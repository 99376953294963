import { memo } from "react";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Typography,
} from "@mui/material";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";

const AddToFleetDialog = ({
  open,
  onClose,
  fleet,
  addVesselToFleet,
  mapRef,
}) => {
  return (
    <Dialog
      container={mapRef.current}
      open={open}
      sx={{
        zIndex: 9999999999999,
      }}
      onClose={onClose}
      PaperProps={{
        style: {
          width: 400,
          maxHeight: 500,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Select fleet</DialogTitle>
      {!fleet.length && (
        <Grid>
          <Typography variant="body1" textAlign="center">
            No fleets found
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            pb={2}
            sx={{
              display: "grid",
              gridTemplateColumns: "max-content min-content",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>Create a fleet via fleet menu</span>
            <DirectionsBoatIcon size="small" />
          </Typography>
        </Grid>
      )}
      {fleet.length > 0 && (
        <List sx={{ pt: 0 }}>
          {fleet.map((currentFleet) => (
            <ListItem disableGutters key={currentFleet.id} sx={{ padding: 0 }}>
              <ListItemButton
                onClick={(e) => {
                  onClose();
                  addVesselToFleet(currentFleet);
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: currentFleet.color }}>
                    <DirectionsBoatIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={currentFleet.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Dialog>
  );
};

export default memo(AddToFleetDialog, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open;
});
