import React, { useContext } from "react";
import ForbiddenErrorPage from "../ForbiddenErrorPage";
import PropTypes from "prop-types";
import { UserContext } from "../AuthGuard";

export default function RoleGuard({ roles = [], children }) {
  const [user] = useContext(UserContext);

  const renderChildren = () => {
    if (user && roles.includes(user.role)) {
      return children;
    }

    return <ForbiddenErrorPage />;
  };

  return renderChildren();
}

RoleGuard.propTypes = {
  roles: PropTypes.array,
};
