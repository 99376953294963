import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";

function ExplainabilityPopup({ area, selectedRow, handleClose }) {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(number);
  };

  const isQuoteOutsideStandardDeviation = (quote, weight, isSubroute) => {
    const price = isSubroute ? subroutePrice(quote, weight) : quote.price;
    if (!selectedRow.standardDeviation) {
      return false;
    }
    const { low, high } = selectedRow.standardDeviation;
    return price < low || price > high;
  };

  const subroutePrice = (subroute, weight) => {
    if (area === "CPP Rhine") {
      return (subroute.price / subroute.weight) * weight;
    }
    return subroute.price - subroute.weight + weight;
  };

  return (
    <Modal open={Boolean(selectedRow)} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
          minWidth: "80vw",
        }}
      >
        {selectedRow && (
          <>
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Explainability data for row {selectedRow.id}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>Number of quotes:</strong> {selectedRow.quotes.length}
            </Typography>
            <Accordion
              expanded={expanded === "quotes"}
              onChange={handleAccordionChange("quotes")}
              sx={{ mb: 2 }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Quotes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedRow.quotes.map((quote) => (
                  <Box
                    key={quote.id}
                    sx={{
                      mb: 2,
                      ...(isQuoteOutsideStandardDeviation(quote, 0, false) && {
                        bgcolor: "error.main",
                        color: "error.contrastText",
                        borderRadius: 1,
                        p: 1,
                      }),
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Id:</strong> {quote.id}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Date:</strong>{" "}
                      {new Date(quote.date._seconds * 1000).toISOString()}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Price:</strong> {formatNumber(quote.price)}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Route:</strong> {quote.route}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Weight:</strong> {quote.weight}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Index:</strong> {quote.index ? "Yes" : "No"}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Load Port:</strong> {quote.loadPort}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Discharge Port:</strong> {quote.dischargePort}
                    </Typography>
                    <Divider />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong>Number of subroutes:</strong>{" "}
              {selectedRow.subroutes.length}
            </Typography>
            <Accordion
              expanded={expanded === "subroutes"}
              onChange={handleAccordionChange("subroutes")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Subroute quotes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedRow.subroutes.map((quote) => (
                  <Box
                    key={quote.id}
                    sx={{
                      mb: 2,
                      ...(isQuoteOutsideStandardDeviation(
                        quote,
                        selectedRow.weight,
                        true,
                      ) && {
                        bgcolor: "error.main",
                        color: "error.contrastText",
                        borderRadius: 1,
                        p: 1,
                      }),
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Id:</strong> {quote.id}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Date:</strong>{" "}
                      {new Date(quote.date._seconds * 1000).toISOString()}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Price:</strong> {formatNumber(quote.price)}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Route:</strong> {quote.route}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Weight:</strong> {quote.weight}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Index:</strong> {quote.index ? "Yes" : "No"}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Load Port:</strong> {quote.loadPort}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Discharge Port:</strong> {quote.dischargePort}
                    </Typography>
                    <Divider />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
            <Typography variant="body1" display="block" sx={{ mt: 2 }}>
              Calculation:
            </Typography>
            <Box
              component="pre"
              sx={{
                mt: 1,
                p: 1,
                bgcolor: "grey.100",
                whiteSpace: "pre-wrap",
                overflowX: "auto",
                borderRadius: 1,
              }}
            >
              {selectedRow.calculation}
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Price: {formatNumber(selectedRow.price)}
            </Typography>
            {selectedRow.historicPrice && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Historic price: {formatNumber(selectedRow.historicPrice)}
              </Typography>
            )}
            <Typography variant="body1">
              Weight: {selectedRow.weight}
            </Typography>
            {selectedRow.standardDeviation && (
              <>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <strong>Standard deviation:</strong>
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <strong>Average:</strong>{" "}
                  {selectedRow.standardDeviation.average.toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  <strong>Deviation:</strong>{" "}
                  {selectedRow.standardDeviation.deviation.toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  <strong>Low:</strong>{" "}
                  {selectedRow.standardDeviation.low.toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  <strong>High:</strong>{" "}
                  {selectedRow.standardDeviation.high.toFixed(2)}
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}

ExplainabilityPopup.propTypes = {
  area: PropTypes.string.isRequired,
  selectedRow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quotes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.shape({
          _seconds: PropTypes.number.isRequired,
        }).isRequired,
        price: PropTypes.number.isRequired,
        route: PropTypes.string.isRequired,
        weight: PropTypes.number.isRequired,
        index: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    subroutes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.shape({
          _seconds: PropTypes.number.isRequired,
        }).isRequired,
        price: PropTypes.number.isRequired,
        route: PropTypes.string.isRequired,
        weight: PropTypes.number.isRequired,
        index: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    calculation: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    historicPrice: PropTypes.number,
    weight: PropTypes.number.isRequired,
    standardDeviation: PropTypes.shape({
      average: PropTypes.number.isRequired,
      deviation: PropTypes.number.isRequired,
      low: PropTypes.number.isRequired,
      high: PropTypes.number.isRequired,
    }),
  }),
  handleClose: PropTypes.func.isRequired,
};

export default ExplainabilityPopup;
