import { Grid, Paper, Divider, Typography, Grow } from "@mui/material";
import { Insights } from "@mui/icons-material";
import graph from "../../assets/graph.png";
import { Status } from "../common/SpotbargeTable";
import { formatNumber } from "../../utils";

const Widget = ({ isSpeciality, number, name, handleClick, icon, onlyTitle }) => {
  return (
    <Grow in={true}>
      <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
        <Paper
          sx={{
            p: 1.5,
            height: "110px",
            cursor: handleClick ? "pointer" : "auto",
          }}
          elevation={4}
          onClick={handleClick ? handleClick : null}
        >
          <Grid
            sx={{
              display: "grid",
              gridTemplateRows: "1fr min-content min-content",
              height: "100%",
            }}
          >
            <Grid
              sx={{
                display: "grid",
                gridTemplateColumns: "min-content 1fr",
                mb: 1
              }}
            >
              <Paper
                elevation={5}
                sx={{
                  p: 1,
                  mt: -2,
                  ml: -2,
                  height: "40px",
                  color: isSpeciality ? "white" : "rgb(0,21,86)",
                  backgroundColor: isSpeciality ? "rgb(0,21,86)" : "#52f4d2",
                }}
              >
                {icon ? icon : <Insights />}
              </Paper>
              <Grid
                sx={{
                  display: "grid",
                  justifyContent: "end",
                  position: "relative",
                  "&::before": handleClick
                    ? {
                      content: '""',
                      backgroundImage: `url(${graph})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      opacity: 0.3,
                      position: "absolute",
                      filter: "blur(1px)",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 999,
                    }
                    : {},
                }}
              >
                <Grid textAlign="end">
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "16px !important", color: "rgb(0,21,86)" }}
                  >
                    {name || "NA"}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "grid",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {isSpeciality ? (
                    <Status status={number}>
                      {(number > 0 ? "+" : "") + formatNumber(number || 0)}
                    </Status>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "15px !important", color: "rgb(0,21,86)" }}
                    >
                      {onlyTitle ? "" : number || 0}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item textAlign="start" justifyContent="center">
              <Typography variant="caption" sx={{ opacity: 0.4 }}>
                {isSpeciality ? (
                  number > 0 ? "Premium" : number < 0 ? "Discount" : ""
                ) : "Market liquidity"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grow>
  );
};

export default Widget;
