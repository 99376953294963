import { memo } from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import isEqual from "lodash/isEqual";
import Bargin from "./Bargin";

const Bargins = ({
  vessels,
  selectedVessel,
  selectVessel,
  handleAddToFleetModal,
  fleetColor,
}) => {
  return (
    <LayersControl.Overlay checked name="Show barges">
      <LayerGroup>
        {vessels.map((vessel, index) => (
          <Bargin
            key={vessel.mmsi + vessel.imo + vessel.name + index}
            vessel={vessel}
            selected={vessel.mmsi === selectedVessel?.mmsi}
            selectVessel={selectVessel}
            handleAddToFleetModal={handleAddToFleetModal}
            fleetColor={fleetColor}
            color={fleetColor[vessel.mmsi]}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.vessels, nextProps.vessels) &&
    prevProps.selectedVessel?.mmsi === nextProps.selectedVessel?.mmsi &&
    isEqual(prevProps.fleetColor, nextProps.fleetColor)
  );
};

export default memo(Bargins, areEqual);
