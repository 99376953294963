import * as React from "react";
import {
  Button,
  ButtonGroup,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
  Checkbox,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import {
  Delete,
  CheckCircle,
  Cancel,
  HourglassEmpty,
  RemoveCircle,
} from "@mui/icons-material";
import SpotbargeTable from "../common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import { useGet, usePost } from "../../request";
import ExcelExportButton from "../atoms/ExcelExportButton";

function formattedDate(d = new Date(), includeTime = false) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  let hours = String(d.getHours());
  let minutes = String(d.getMinutes());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;

  return `${day}/${month}/${year}${includeTime ? ` ${hours}:${minutes}` : ""}`;
}

function AdminQuotes() {
  const { t } = useTranslation();

  const [filter, setFilter] = useState("All");

  const columns = [
    {
      id: "timestamp",
      label: t("adminquotes_quote_date"),
    },
    {
      id: "loadDate",
      label: t("adminquotes_load_date"),
    },
    {
      id: "loadPort",
      label: t("adminquotes_load_port"),
      filter: "checkboxes",
    },
    {
      id: "dischargePort",
      label: t("adminquotes_discharge_port"),
      filter: "checkboxes",
    },
    {
      id: "userName",
      label: t("adminquotes_created_by"),
    },
    {
      id: "freeEntryCompanyName",
      label: t("adminquotes_company_name"),
    },
    {
      id: "contactPerson",
      label: t("adminquotes_contact_person"),
    },
    {
      id: "via",
      label: t("adminquotes_via"),
    },
    {
      id: "userCompany",
      label: t("adminquotes_on_behalf_of"),
    },
    {
      id: "counterParty",
      label: t("adminquotes_counterparty"),
      filter: "checkboxes",
    },
    {
      id: "bargeName",
      label: t("adminquotes_barge_name"),
    },
    {
      id: "product",
      label: t("adminquotes_product"),
    },
    {
      id: "tons",
      label: t("mytrips_tons"),
      numeric: true,
    },
    {
      id: "confirmedPrice",
      label: t("adminquotes_price_per_ton"),
      numeric: true,
    },
    {
      id: "area",
      label: t("adminquotes_area"),
      filter: "checkboxes",
    },
    {
      id: "status",
      label: t("adminquotes_status"),
      filter: "checkboxes",
    },
    {
      id: "actions",
      label: t("adminquotes_actions"),
      width: 150,
    },
  ];
  const filterRows = (rows) => {
    if (filter === "All") return rows;
    return rows.filter((row) => row.status === filter);
  };
  const handleFilterChange = (event) => {
    setFilter(event.currentTarget.dataset.filter);
  };

  const [loading, setLoading] = useState(true);
  const [inIndex, setInIndex] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const filteredData = filterRows(data);

  const get = useGet();
  const post = usePost();

  const handleFetchAll = () => {
    return fetchQuotes(true);
  };

  const fetchQuotes = async (getAll = false) => {
    try {
      let result = await get("admin-get", {
        getAll,
      });
      if (!result) {
        setError(t("adminquotes_something_went_wrong"));
        return;
      }
      if (result.error) {
        setError(result.error);
        return;
      }
      result = result.map((o) => {
        return {
          ...o,
          loadDate: formattedDate(new Date(o["loadDate"]._seconds * 1000)),
          timestamp: formattedDate(
            new Date(o["timestamp"]._seconds * 1000),
            true,
          ),
        };
      });
      setData(result);
    } finally {
      setLoading(false);
    }
  };

  const handleInIndexChange = (event) => setInIndex(event.target.checked);
  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async () => await fetchQuotes());

    return unsubscribe;
  }, [t]);

  const handleUpdateIndex = async () => {
    let result = await post("update-index");
    if (!result) {
      setError(t("adminquotes_something_went_wrong"));
      return;
    }
    if (result.error) {
      setError(result.error);
      return;
    }
    alert("Index updated");
  };

  const handleDelete = async (row) => {
    let result = await post("admin-delete", { id: row.id });
    if (!result) {
      setError(t("adminquotes_something_went_wrong"));
      return;
    }
    if (result.error) {
      setError(result.error);
      return;
    }
    if (result.success) {
      setData(data.filter((r) => r.id !== row.id));
    }
  };

  const handleChangeStatus = async (row, status) => {
    let result = await post("admin-update", {
      id: row.id,
      status,
      inIndex,
    });
    if (result.success) {
      setData(
        data.map((r) => {
          if (r.id === row.id) {
            return {
              ...r,
              status,
            };
          }
          return r;
        }),
      );
    }
  };

  const renderActions = (row) => (
    <>
      {
        // This button is hidden for now, given the risks when quotes are deleted
      }
      <Box sx={{ display: "none" }}>
        <Tooltip title={t("adminquotes_delete_quote")}>
          <IconButton color="error" onClick={() => handleDelete(row)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
      <Tooltip title={t("adminquotes_change_status_confirmed")}>
        <IconButton
          color="success"
          onClick={() => handleChangeStatus(row, "Confirmed")}
        >
          <CheckCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("adminquotes_change_status_rejected")}>
        <IconButton
          color="warning"
          onClick={() => handleChangeStatus(row, "Rejected")}
        >
          <Cancel />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("adminquotes_change_status_pending")}>
        <IconButton
          color="info"
          onClick={() => handleChangeStatus(row, "Pending")}
        >
          <HourglassEmpty />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("adminquotes_change_status_outlier")}>
        <IconButton
          color="primary"
          onClick={() => handleChangeStatus(row, "Outlier")}
        >
          <RemoveCircle />
        </IconButton>
      </Tooltip>
    </>
  );
  const columnRenderMap = {
    actions: renderActions,
  };

  return (
    <SpotbargeLayout title={t("adminquotes_header")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                direction: "row",
                mt: 2,
              }}
            >
              <ButtonGroup variant="outlined" sx={{ mb: 2 }}>
                <Button
                  onClick={handleFilterChange}
                  variant={filter === "All" ? "contained" : "outlined"}
                  data-filter="All"
                >
                  {t("adminquotes_all")}
                </Button>
                <Button
                  onClick={handleFilterChange}
                  variant={filter === "Pending" ? "contained" : "outlined"}
                  data-filter="Pending"
                >
                  {t("adminquotes_pending")}
                </Button>
                <Button
                  onClick={handleFilterChange}
                  variant={filter === "Confirmed" ? "contained" : "outlined"}
                  data-filter="Confirmed"
                >
                  {t("adminquotes_confirmed")}
                </Button>
              </ButtonGroup>
              <Tooltip title={t("adminquotes_approve_for_current_index")}>
                <Checkbox onClick={handleInIndexChange} />
              </Tooltip>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                sx={{ mb: 1 }}
              >
                <ExcelExportButton isAdmin={true} />
              </Box>
            </Box>
            <SpotbargeTable
              data={filteredData}
              columns={columns}
              columnRenderMap={columnRenderMap}
              paginate={filteredData.length > 25}
              filterKey="adminQuotes"
              paginateCallback={handleFetchAll}
            />
            <Grid
              sx={{
                display: "grid",
                justifyItems: "end",
                pr: 1,
                mt: 4,
              }}
            >
              <Button
                onClick={handleUpdateIndex}
                variant="contained"
                color="error"
              >
                {"Update index"}
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(AdminQuotes, {
  fallback: <GeneralErrorPage />,
});
