import React from "react";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  Legend,
  LineChart as RechartLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Brush,
  ReferenceLine,
} from "recharts";
import CustomTooltip from "./CustomTooltip";

const renderLinesForArrayDataKey = (dataKey, colors, isFuture = false) => {
  if (!isFuture)
    return dataKey.map((key, index) => (
      <Line
        key={index}
        type="monotone"
        dot={{ r: 2.4 }}
        dataKey={key}
        stroke={colors[index % colors.length]}
        strokeWidth={2.4}
        animationDuration={500}
      />
    ));
  return dataKey.map((key, index) => (
    <Line
      type="monotone"
      dot={{ r: index === 0 ? 0.8 : 0 }}
      strokeWidth={1.5}
      animationDuration={500}
      filter={
        index === 0
          ? ""
          : `drop-shadow(2px 0px 2px ${colors[index % colors.length]})`
      }
      stroke={colors[index % colors.length]}
      strokeOpacity={index === 0 ? 1 : 0.4}
      dataKey={key}
    />
  ));
};

const renderLineForSingleDataKey = (dataKey) => {
  return (
    <Line
      type="monotone"
      dot={{ r: 0.8 }}
      strokeWidth={1.5}
      dataKey={dataKey}
      animationDuration={500}
    />
  );
};

function LineChart({
  data,
  labelFormatter,
  formatter,
  tickFormatter,
  domain,
  getXAxisArgs,
  dataKey,
  height = 300,
  width = 500,
  noStyling = false,
  custom = false,
  isGreen = false,
  showBrush = false,
  customStartIndex = 31,
  reference = null,
  handleForecast = false,
}) {
  const allDates = data.map((d) => d.x);

  const colors = [
    "#E67E22",
    "#16A085",
    "#2980B9",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
  ];

  const futureColors = ["#3183bd", "#8d97c6", "#b3cde3", "#b3cde3", "#8d97c6"];

  return (
    <ResponsiveContainer width="100%" height={height}>
      <RechartLineChart
        width={width}
        height={height}
        data={data}
        fontSize={12}
        margin={
          noStyling
            ? { top: 5, right: 30, left: 0, bottom: 5 }
            : {
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }
        }
      >
        <CartesianGrid stroke="#f5f5f5" strokeOpacity={0.9} />
        <XAxis
          axisLine={noStyling ? true : false}
          dataKey="x"
          fontSize={12}
          tickLine={noStyling ? true : false}
          label={
            isGreen && {
              value: "source: www.elwis.de",
              position: "insideBottomRight",
              dy: -27,
              fillOpacity: 0.4,
              fontSize: 12,
            }
          }
          {...(getXAxisArgs ? getXAxisArgs(allDates) : {})}
        />
        <YAxis
          fontSize={12}
          padding={{ top: 0, bottom: 0 }}
          scale={"linear"}
          domain={domain}
          tickFormatter={tickFormatter}
        />
        {custom ? (
          <Tooltip
            formatter={formatter}
            labelFormatter={labelFormatter}
            content={<CustomTooltip />}
          />
        ) : (
          <Tooltip formatter={formatter} labelFormatter={labelFormatter} />
        )}
        {!noStyling && <Legend />}
        {Array.isArray(dataKey)
          ? renderLinesForArrayDataKey(
              dataKey,
              handleForecast ? futureColors : colors,
              handleForecast,
            )
          : renderLineForSingleDataKey(dataKey)}
        {reference &&
          Object.entries(reference).map(([key, value], index) => {
            return (
              <ReferenceLine
                y={value}
                strokeDasharray="5 3"
                label={{
                  value: key,
                  fill: "black",
                  fontSize: index <= 1 ? 8 : 7,
                  lineHeight: 2.5,
                }}
                stroke={index <= 1 ? "orange" : "red"}
                strokeOpacity={index <= 1 ? 0.7 : 0.3}
              />
            );
          })}
        {showBrush && (
          <Brush
            height={14}
            startIndex={
              data.length >= customStartIndex
                ? data.length - customStartIndex
                : 0
            }
            stroke="#6f6f6f"
            alwaysShowText
          />
        )}
      </RechartLineChart>
    </ResponsiveContainer>
  );
}

export default React.memo(LineChart);

LineChart.propTypes = {
  data: PropTypes.any,
  dataKey: PropTypes.string,
  domain: PropTypes.array,
  formatter: PropTypes.func,
  labelFormatter: PropTypes.func,
  getXAxisArgs: PropTypes.func,
};
