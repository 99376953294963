import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Grid, Typography, Icon } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import bottomSectionBg from "../../assets/homepage/bottom_section_bg.png";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  color: "#003366",
  padding: theme.spacing(8),
}));

const Title = styled(Typography)({
  fontWeight: "bold",
});

const Column = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const BottomSection = () => {
  const { t } = useTranslation();
  return (
    <Root
      style={{
        background: `url(${bottomSectionBg}) no-repeat center center`,
        backgroundSize: "cover",
        color: "#fff",
      }}
    >
      <Container maxWidth="lg" style={{ padding: "40px" }}>
        <Title variant="h4">{t("bottom_sections_header")}</Title>
        <Typography variant="h6">{t("bottom_sections_subtext")}</Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={4}>
            <Column>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                <Icon sx={{ color: "#3F3FF9", marginRight: "8px", mb: "-4px" }}>
                  <Circle />
                </Icon>
                {t("bottom_sections_col1_header")}
              </Typography>
              <Typography variant="body1">
                {t("bottom_sections_col1_subtext")}
              </Typography>
            </Column>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Column>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                <Icon sx={{ color: "#3F3FF9", marginRight: "8px", mb: "-4px" }}>
                  <Circle />
                </Icon>
                {t("bottom_sections_col2_header")}
              </Typography>
              <Typography variant="body1">
                {t("bottom_sections_col2_subtext")}
              </Typography>
            </Column>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Column>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                <Icon
                  sx={{
                    color: "#3F3FF9",
                    marginRight: "8px",
                    mb: "-4px",
                  }}
                >
                  <Circle />
                </Icon>
                {t("bottom_sections_col3_header")}
              </Typography>
              <Typography variant="body1">
                {t("bottom_sections_col3_subtext")}
              </Typography>
            </Column>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default BottomSection;
