import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import LineChart from "../../common/charts/LineChart";
import { formattedDate } from "./RouteHistoryChart";
import { AreaLabelToCode } from "../../QuoteForm";
import IndexChart from "./IndexChart";

const TotalQuotesChart = ({ area, onClose }) => {
  const areaCode = AreaLabelToCode[area];

  const [graphData, setGraphData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
        setGraphData(
          filteredData
            .sort((a, b) => {
              return new Date(a.id) - new Date(b.id);
            })
            .map((change) => ({
              x: formattedDate(new Date(change.id), false),
              "Total quotes": change.quotes,
            })),
        );
    }
  }, [filteredData]);

  return (
    <IndexChart row={area} onClose={onClose} filteredData={filteredData} setFilteredData={setFilteredData} endpoint={"history-totals"} requestArgs={{ area: areaCode }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Historic Total Quotes for Area {area}
            </Typography>
            <LineChart
              data={graphData}
              dataKey={"Total quotes"}
              domain={[]}
              formatter={(value) => value}
              labelFormatter={(value) => value}
              getXAxisArgs={() => {}}
            />
          </IndexChart>
  );
};

TotalQuotesChart.propTypes = {
  area: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default TotalQuotesChart;
