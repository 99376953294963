import { DirectionsBoat, Sailing, LegendToggle } from "@mui/icons-material";
import { Paper, Stack, Grid } from "@mui/material";

const CustomTooltip = ({ active, payload, label, labelFormatter }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    console.log(data);

    return (
      <Paper elevation={3} sx={{ p: 1, fontSize: "12px" }}>
        <div
          style={{
            minWidth: "200px",
          }}
        >
          <i>{`${labelFormatter(data.x)}`}</i>
        </div>
        {data["Water level"] && (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              fontWeight: "bold",
              minWidth: "200px",
            }}
          >
            <Grid container alignItems="center">
              <LegendToggle fontSize="small" />
              <span>Water level:</span>
            </Grid>
            <div>{data["Water level"]}cm</div>
          </Stack>
        )}
        {data["30%"] && data["70%"] && !data["Water level"] && (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              fontWeight: "bold",
              minWidth: "200px",
              color: "#b3cde3",
            }}
          >
            <Grid container alignItems="center">
              <LegendToggle fontSize="small" />
              <span>Water level 30% chance:</span>
            </Grid>
            <div>
              {data["30%"]}-{data["70%"]}cm
            </div>
          </Stack>
        )}
        {data["50%"] && !data["Water level"] && (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              fontWeight: "bold",
              minWidth: "200px",
              color: "#8d97c6",
            }}
          >
            <Grid container alignItems="center">
              <LegendToggle fontSize="small" />
              <span>Water level 50% chance:</span>
            </Grid>
            <div>{data["50%"]}cm</div>
          </Stack>
        )}
        <div
          style={{
            minWidth: "200px",
          }}
        >
          <i>Loadables per ship</i>
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            fontWeight: "bold",
            minWidth: "200px",
          }}
        >
          <Grid container alignItems="center">
            <Sailing fontSize="small" />
            <span> 110m 2500dwt/3040cmb</span>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minWidth: "250px",
          }}
        >
          <span>Fame dens 0.88</span>
          {data.small && <div>{data.small.fame}t</div>}
          {data["small50%"] && !data.small && (
            <div>
              <span style={{ color: "#8d97c6", fontWeight: "bold" }}>
                {data["small50%"].fame}t
              </span>
            </div>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minWidth: "250px",
            color:
              data.reference &&
              data.reference["Max load diesel"] &&
              data["Water level"] < data.reference["Max load diesel"]
                ? "red"
                : "inherit",
          }}
        >
          <span>Diesel dens 0,83</span>
          {data.small && <div>{data.small.diesel}t</div>}
          {data["small50%"] && !data.small && (
            <div>
              <span style={{ color: "#8d97c6", fontWeight: "bold" }}>
                {data["small50%"].diesel}t
              </span>
            </div>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minWidth: "250px",
            color:
              data.reference &&
              data.reference["Max load gasoline"] &&
              data["Water level"] < data.reference["Max load gasoline"]
                ? "red !important"
                : "inherit",
          }}
        >
          <span>Gasoline dens 0,74</span>
          {data.small && <div>{data.small.gasoline}t</div>}
          {data["small50%"] && !data.small && (
            <div>
              <span style={{ color: "#8d97c6", fontWeight: "bold" }}>
                {data["small50%"].gasoline}t
              </span>
            </div>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            fontWeight: "bold",
            minWidth: "250px",
          }}
        >
          <Grid container alignItems="center">
            <DirectionsBoat fontSize="small" />
            <span>135m 3500dwt/4161cmb</span>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minWidth: "250px",
          }}
        >
          <span>Fame dens 0.88</span>
          {data.big && <div>{data.big.fame}t</div>}
          {data["big50%"] && !data.big && (
            <div>
              <span style={{ color: "#8d97c6", fontWeight: "bold" }}>
                {data["big50%"].fame}t
              </span>
            </div>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minWidth: "250px",
          }}
        >
          <span>Diesel dens 0,83</span>
          {data.big && <div>{data.big.diesel}t</div>}
          {data["big50%"] && !data.big && (
            <div>
              <span style={{ color: "#8d97c6", fontWeight: "bold" }}>
                {data["big50%"].diesel}t
              </span>
            </div>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minWidth: "250px",
          }}
        >
          <span>Gasoline dens 0,74</span>
          {data.big && <div>{data.big.gasoline}t</div>}
          {data["big50%"] && !data.big && (
            <div>
              <span style={{ color: "#8d97c6", fontWeight: "bold" }}>
                {data["big50%"].gasoline}t
              </span>
            </div>
          )}
        </Stack>
      </Paper>
    );
  }

  return null;
};

export default CustomTooltip;
