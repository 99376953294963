import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Tabs, Tab, Box } from "@mui/material";
import SpotbargeLayout from "../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import GraphDealSizes from "./GraphDealSizes";
import GraphPriceHistory from "./GraphPriceHistory";
import GraphTrips from "./GraphTrips";
import GraphProductVolume from "./GraphProductVolume";

const areas = ["CPP ARA > 1500 ton", "CPP Rhine"];

const Dashboard = () => {
  const [selectedArea, setSelectedArea] = useState(areas[0]);
  const { t } = useTranslation();

  const handleChange = (_, newValue) => {
    setSelectedArea(newValue);
  };

  const graphs = [
    <GraphPriceHistory area={selectedArea} />,
    <GraphProductVolume area={selectedArea} />,
    <GraphTrips area={selectedArea} />,
    <GraphDealSizes area={selectedArea} />,
  ];

  return (
    <SpotbargeLayout title={t("dashboard_header")}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mb: 2 }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={selectedArea} onChange={handleChange}>
            {areas.map((area, index) => (
              <Tab key={index} label={area} value={area} />
            ))}
          </Tabs>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        {graphs.map((graph) => (
          <Grid item sm={12} md={6} width="100%">
            <Paper
              sx={{
                p: 2,
                minHeight: "400px",
                maxHeight: "400px",
                display: "grid",
                alignItems: "end",
                alignContent: "space-between",
              }}
              elevation={1}
            >
              {graph}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(React.memo(Dashboard), {
  fallback: <GeneralErrorPage />,
});
