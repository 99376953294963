import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { formattedDate } from "./RouteHistoryChart";
import { AreaLabelToCode } from "../../QuoteForm";
import BarChart from "../../common/charts/BarChart";
import IndexChart from "./IndexChart";

const TotalTonsChart = ({ area, onClose }) => {
  const areaCode = AreaLabelToCode[area];

  const [graphData, setGraphData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setGraphData(
        filteredData.sort((a, b) => {
              return new Date(a.id) - new Date(b.id);
            })
            .map((change) => ({
              x: formattedDate(new Date(change.id), false),
              "Total tons": change.tons,
            }))
            .sort((a, b) => a.x - b.x),
        );
    }
  }, [filteredData]);

  return (
    <IndexChart row={area} onClose={onClose} filteredData={filteredData} setFilteredData={setFilteredData} endpoint={"history-totals"} requestArgs={{ area: areaCode }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Historic Total Tons for Area {area}
            </Typography>
            <BarChart data={graphData} dataKey={"Total tons"} />
          </IndexChart>
  );
};

TotalTonsChart.propTypes = {
  area: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default TotalTonsChart;
