import { useContext } from "react";
import {
  Drawer,
  Divider,
  Grid,
  Button,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { getAuth } from "@firebase/auth";
import { useTranslation } from "react-i18next";
import {
  Newspaper,
  Logout,
  ManageAccounts,
  Troubleshoot,
  BarChart,
  AttachMoney,
  Home,
  EditCalendar,
  Group,
  Anchor,
  Sailing,
  Business,
  Markunread,
  ChevronLeft,
  ChevronRight,
  CloudQueue,
  Water,
  AcUnit,
  DirectionsBoatFilledRounded,
} from "@mui/icons-material";
import logo from "../../../assets/logo-sidebar.svg";
import onlyLogo from "../../../assets/logo-no-text.svg";
import { UserContext } from "../../AuthGuard";

const errorPageLinks = {
  dev: "cee09c95-fee8-4d6b-ab9c-367249948299",
  staging: "3c011165-f116-4dd7-97aa-e48b47dd1bf9",
  prod: "2f3ff45f-da4b-4036-a14a-698ea9ff9163",
};

const MenuContainer = styled(Grid)({
  display: "grid",
  gridAutoRows: "min-content",
  gap: 2,
});
const LogoContainer = styled(Grid)({
  display: "grid",
  alignContent: "center",
  justifyContent: "center",
});
const StyledLogo = styled("img")((matches) => ({
  width: matches ? "200px" : "10px",
  height: "50px",
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#ff4b4b",
    color: "#ff4b4b",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const getMenuItems = (t, signout) => {
  return [
    {
      name: t("menu_dashboard"),
      url: "dashboard",
      role: ["read", "write", "admin"],
      icon: <Home />,
    },
    {
      name: t("menu_index"),
      url: "index",
      role: ["read", "write", "admin"],
      icon: <AttachMoney />,
    },
    {
      name: t("menu_voyages"),
      url: "voyages",
      role: ["write", "admin"],
      icon: <Anchor />,
    },
    {
      name: t("menu_quote"),
      url: "enter-quote",
      role: ["write", "admin"],
      icon: <EditCalendar />,
    },
    {
      name: t("menu_waterlevels"),
      url: "water-levels",
      role: ["read", "write", "admin"],
      icon: <Water />,
    },
    {
      name: t("menu_ship_tracking"),
      url: "ship-tracking",
      role: ["read", "write", "admin"],
      icon: <DirectionsBoatFilledRounded />,
      live: true,
    },
    {
      name: t("menu_snow_leveles"),
      url: "snow-levels",
      role: ["read", "write", "admin"],
      icon: <AcUnit />,
    },
    {
      name: t("menu_weather"),
      url: "weather",
      role: ["read", "write", "admin"],
      icon: <CloudQueue />,
    },
    {
      name: t("menu_market_news"),
      url: "market-news",
      role: ["read", "write", "admin"],
      icon: <Newspaper />,
    },
    {
      name: t("menu_admin_quotes"),
      url: "admin-quotes",
      role: ["admin"],
      hasDividerTop: true,
      icon: <Sailing />,
    },
    {
      name: t("menu_admin_users"),
      url: "admin-users",
      role: ["admin"],
      icon: <Group />,
    },
    {
      name: t("menu_admin_companies"),
      url: "admin-companies",
      role: ["admin"],
      icon: <Business />,
    },

    {
      name: t("menu_admin_email"),
      url: "admin-email",
      role: ["admin"],
      icon: <Markunread />,
    },
    {
      name: "Troubleshoot",
      icon: <Troubleshoot fontSize="small" />,
      hasDividerTop: true,
      role: ["admin"],
      link: `https://console.cloud.google.com/monitoring/dashboards/builder/${
        errorPageLinks[process.env.REACT_APP_ENV]
      };duration=PT1H?project=${process.env.REACT_APP_PROJECT_ID}`,
      color: "#3368d6",
    },
    {
      name: "Analytics",
      icon: <BarChart fontSize="small" />,
      role: ["admin"],
      link: `https://console.firebase.google.com/u/0/project/${process.env.REACT_APP_PROJECT_ID}/analytics/overview`,
      color: "#ffab00",
    },
    {
      name: t("menu_settings"),
      url: "user",
      icon: <ManageAccounts fontSize="small" />,
      hasDividerTop: true,
    },
    {
      name: t("menu_logout"),
      action: signout,
      icon: <Logout fontSize="small" />,
    },
  ];
};

const Sidebar = ({
  large,
  handleOpen,
  mobileOpen,
  matches,
  handleMobileOpen,
}) => {
  const [user, setUser] = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleMenuClick(item) {
    if (item.action) {
      item.action();
    } else if (item.url) {
      navigate(`/${item.url}`);
    } else if (item.link) {
      window.open(item.link, "_blank").focus();
    }
    handleMobileOpen();
  }

  const renderMenuItems = (item, open) => {
    return (
      <span key={item.name}>
        {item.hasDividerTop && (
          <Divider
            sx={{
              mt: 1,
              mb: 1,
            }}
          />
        )}
        <Button
          variant="text"
          size="large"
          fullWidth
          onClick={() => handleMenuClick(item)}
          sx={{
            textAlign: open ? "left" : "center",
            display: "grid",
            color: item.color ? item.color : "#001256",
            gridTemplateColumns: open ? "min-content 1fr" : "min-content",
            overflow: "hidden",
            gap: "10px",
            whiteSpace: "nowrap",
            backgroundColor:
              item.url === window.location.pathname.substring(1)
                ? "#51f3d2"
                : "inherit",
          }}
        >
          <Tooltip title={item.name} placement="right-end">
            {item.live ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                variant="dot"
              >
                {item.icon}
              </StyledBadge>
            ) : (
              item.icon
            )}
          </Tooltip>
          {open && <Typography fontWeight={500}>{item.name}</Typography>}
        </Button>
      </span>
    );
  };

  const menuItems = getMenuItems(t, signout);
  const selectedLogo = large ? logo : onlyLogo;

  async function signout() {
    try {
      await getAuth().signOut();
      setUser({});
      window.localStorage.removeItem("emailForSignIn");
      window.localStorage.removeItem("_grecaptcha");
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Drawer
      open={matches ? true : mobileOpen}
      variant={matches ? "permanent" : "temporary"}
      onClose={handleMobileOpen}
      anchor={matches ? "left" : "right"}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          width: large ? "250px" : "95px",
          transition: "100ms all linear",
          overflowX: "hidden",
          display: "grid",
          height: `${window.innerHeight}px`,
          gridTemplateRows: "60px min-content 1fr min-content min-content",
          padding: "15px",
          gap: "10px",
        },
      }}
    >
      <LogoContainer>
        <StyledLogo src={selectedLogo} matches={matches} />
      </LogoContainer>
      <Divider />
      <MenuContainer>
        {menuItems
          .filter((item) => !item.role || item.role.includes(user?.role))
          .map((item) => {
            return renderMenuItems(item, large);
          })}
      </MenuContainer>
      <Divider />
      {matches && (
        <Grid
          sx={{
            display: "grid",
          }}
        >
          <Button onClick={handleOpen}>
            {large ? <ChevronLeft /> : <ChevronRight />}
          </Button>
        </Grid>
      )}
    </Drawer>
  );
};

export default Sidebar;
