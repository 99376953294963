import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { AreaLabelToCode } from "../../QuoteForm";
import BarChart from "../../common/charts/BarChart";
import IndexChart from "./IndexChart";

const TotalProductsChart = ({ area, onClose }) => {
  const areaCode = AreaLabelToCode[area];

  const [graphData, setGraphData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
        const aggregatedProductResults = filteredData.reduce(
          (accumulator, currentValue) => {
            if (currentValue.productTotals) {
              for (const currentValueKey in currentValue.productTotals) {
                if (accumulator[currentValueKey]) {
                  accumulator[currentValueKey] +=
                    currentValue.productTotals[currentValueKey];
                } else {
                  accumulator[currentValueKey] =
                    currentValue.productTotals[currentValueKey];
                }
              }
            }

            return accumulator;
          },
          {},
        );

        setGraphData(
          Object.entries(aggregatedProductResults)
            .map(([index, value]) => ({
              x: index,
              value,
            }))
            .sort((itemA, itemB) => itemA.x.localeCompare(itemB.x)),
        );
    }
  }, [filteredData]);

  return (
    <IndexChart row={area} onClose={onClose} filteredData={filteredData} setFilteredData={setFilteredData} endpoint={"history-totals"} requestArgs={{ area: areaCode }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Historic Product Totals for Area {area}
            </Typography>
            <BarChart
              data={graphData}
              dataKey={"value"}
              single={false}
              enableYAxisLabels={false}
              enableLegend={false}
              enableTooltip={false}
            />
          </IndexChart>
  );
};

TotalProductsChart.propTypes = {
  area: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default TotalProductsChart;
