import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import LogoSmall from "../../assets/logo-small.svg";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const Logo = styled("img")({
  width: "50px",
  height: "50px",
});

const Link = styled("a")({
  textDecoration: "none",
  color: "#fff",
  "&:hover": {
    textDecoration: "underline",
    color: "#66ccff",
  },
});
const Text = styled(Typography)({
  paddingTop: "4px",
  paddingBottom: "4px",
});

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      display="grid"
      gridTemplateRows="1fr min-content"
      p={1}
      pl={2}
      pr={2}
      gap={1.5}
      sx={{
        backgroundColor: "#001556",
        color: "white",
        textAlign: ["center", "left"],
      }}
    >
      <Grid
        container
        display="grid"
        sx={{
          gridTemplateRows: {
            sm: "1fr",
          },
          gridTemplateColumns: {
            md: "1fr max-content",
          },
        }}
      >
        <Grid
          container
          gap={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            flexDirection: ["column", "row"],
          }}
        >
          <Logo src={LogoSmall} alt="Logo" />
          <Text variant="body1" style={{ fontWeight: "bold" }}>
            {t("bottom_section_header")}
          </Text>
        </Grid>
      </Grid>
      <Grid
        p={0}
        sx={{
          textAlign: {
            sm: "left",
            md: "center",
          },
        }}
      >
        <Typography variant="subtitle2">
          {t("bottom_section_contact")} info@spotbarge.com
        </Typography>
        <Typography variant="subtitle2">
          {t("bottom_section_copyright")}
        </Typography>
      </Grid>
      <Grid
        container
        display="grid"
        direction="column"
        justifyContent="center"
        gap={2}
        sx={{
          color: "white",
          fontSize: "5px",
          gridTemplateColumns: {
            md: "repeat(6, max-content)",
            xs: "max-content max-content",
          },
        }}
        p={0}
      >
        <Link href="/privacy">
          <Typography fontSize={12}>{t("bottom_section_privacy")}</Typography>
        </Link>
        <Link href="/spotbarge_terms_and_conditions_2023.pdf">
          <Typography fontSize={12}>{t("bottom_section_terms")}</Typography>
        </Link>
        <Link href="/spotbarge_code_of_conduct_2024.pdf">
          <Typography fontSize={12}>{t("bottom_section_code")}</Typography>
        </Link>
        <Link href="/spotbarge_methodology.pdf">
          <Typography fontSize={12}>
            {t("bottom_section_methodology")}
          </Typography>
        </Link>
        <Link href="/spotbarge_guideline.pdf">
          <Typography fontSize={12}>{t("bottom_section_guideline")}</Typography>
        </Link>
        <Link href="/complaints_dispute_policy.pdf">
          <Typography fontSize={12}>
            {t("bottom_section_complaints")}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Footer;
