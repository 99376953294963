import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Alert,
  ButtonGroup,
  Autocomplete,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import firebase from "firebase/compat/app";
import { formatNumber, productOptions } from "../utils";
import SpotbargeLayout from "./common/SpotbargeLayout";
import { useTranslation } from "react-i18next";
import { UserContext } from "./AuthGuard";
import OutlinedSection from "./atoms/OutlinedSection";
import GeneralErrorPage from "./GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useGet, usePost } from "../request";

export const alphanumericRegex = /^[a-zA-Z0-9À-ÿ\s.'-]*$/;

export const Areas = {
  Canals: "Canals BE/FR",
  CPP_RHINE: "CPP Rhine",
  DPP_ARA: "DPP ARA",
  CPP_ARA_DWT_LARGE: "CPP ARA > 1500 ton",
  CPP_ARA_DWT_SMALL: "CPP ARA < 1500 ton",
};

export const AreaLabelToCode = {
  [Areas.Canals]: "Canals",
  [Areas.DPP_ARA]: "DPP",
  [Areas.CPP_RHINE]: "Rhine",
  [Areas.CPP_ARA_DWT_LARGE]: "DWTHeavy",
  [Areas.CPP_ARA_DWT_SMALL]: "DWTLight",
};

function areasForDisplay(data) {
  let result = {};
  let areaMap = {};
  data
    .filter((area) => area.cities.length > 1)
    .forEach((area) => {
      areaMap[area.area + area.name] = area.cities.join(", ");
    });
  data
    .filter((area) => area.destinations.length > 0)
    .forEach((area) => {
      if (!result[area.area]) {
        result[area.area] = {};
      }
      result[area.area][
        `${area.name}` +
          (area.cities.length > 1 ? ` (${area.cities.join(", ")})` : "")
      ] = area.destinations.map(
        (destination) =>
          `${destination}` +
          (areaMap[area.area + destination]
            ? ` (${areaMap[area.area + destination]})`
            : ""),
      );
    });
  return result;
}

function isWeekendOrHoliday(date) {
  const day = date.getUTCDay();
  return day === 0 || day === 6 || isNationalHoliday(date);
}

function isNationalHoliday(date) {
  const ukBankHolidays = new Set([
    "2024-01-01",
    "2024-04-01",
    "2024-05-06",
    "2024-05-27",
    "2024-08-26",
    "2024-12-25",
    "2024-12-26",
    "2025-01-01",
    "2025-04-18",
    "2025-04-21",
    "2025-05-05",
    "2025-05-26",
    "2025-08-25",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-04-03",
    "2026-04-06",
    "2026-05-04",
    "2026-05-25",
    "2026-08-31",
    "2026-12-25",
    "2027-01-01",
    "2027-03-26",
    "2027-03-29",
    "2027-05-03",
    "2027-05-31",
    "2027-08-30",
    "2027-12-27",
    "2027-12-28",
    "2028-01-03",
    "2028-04-14",
    "2028-04-17",
    "2028-05-01",
    "2028-05-29",
    "2028-08-28",
    "2028-12-25",
    "2028-12-26",
    "2029-01-01",
    "2029-03-29",
    "2029-04-01",
    "2029-05-07",
    "2029-05-28",
    "2029-08-27",
    "2029-12-25",
    "2029-12-26",
    "2030-01-01",
    "2030-04-19",
    "2030-04-22",
    "2030-05-06",
    "2030-05-27",
    "2030-08-26",
    "2030-12-25",
    "2030-12-26",
  ]);
  const formattedDate = date.toISOString().split("T")[0];
  return ukBankHolidays.has(formattedDate);
}

function QuoteForm() {
  const { t } = useTranslation();
  const [loadDate, setLoadDate] = useState("");
  const [loadPort, setLoadPort] = useState("");
  const [dischargePort, setDischargePort] = useState("");
  const [price, setPrice] = useState("");
  const [tons, setTons] = useState("");
  const [bargeName, setBargeName] = useState("");
  const [counterparty, setCounterparty] = useState("");
  const [onBehalfOf, setOnBehalfOf] = useState("");
  const [product, setProduct] = useState("");
  const [area, setArea] = useState("");
  const [priceType, setPriceType] = useState("");
  const [loading, setLoading] = useState(true);
  const [portOptions, setPortOptions] = useState({});
  const [error, setError] = useState("");
  const [message, setMessage] = useState({ text: "", severity: "" });
  const [counterpartyOptions, setCounterpartyOptions] = useState([]);
  const [oilCompanies, setOilCompanies] = useState([]);
  const [user] = useContext(UserContext);
  const [payableTonsError, setPayableTonsError] = useState(false);
  const [isIndicative, setIsIndicative] = useState(false);
  const [freeEntryCompanyName, setFreeEntryCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [via, setVia] = useState("");

  const [bargeNameValid, setBargeNameValid] = useState(true);

  // In case of multiple prices/tons for ARA and FARAG
  const [priceValues, setPriceValues] = useState({});

  const get = useGet();
  const post = usePost();

  const isQuoteFormDisabled = useCallback(() => {
    const date = new Date();
    let hour = date.getUTCHours() + 1; // Convert to CET

    // Check if date is between last Sunday in March and last Sunday in October
    const startDST = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        2,
        31 -
          ((5 + new Date(Date.UTC(date.getUTCFullYear(), 2, 1)).getUTCDay()) %
            7),
        1,
      ),
    );
    const endDST = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        9,
        31 -
          ((5 + new Date(Date.UTC(date.getUTCFullYear(), 9, 1)).getUTCDay()) %
            7),
        1,
      ),
    );

    if (date >= startDST && date < endDST) {
      hour += 1; // Add an extra hour for daylight saving time
    }

    if (user.role === "admin") return false;

    return hour < 9 || hour >= 18 || isWeekendOrHoliday(date);
  }, []);

  const handlePriceValuesChange = (i, value) => {
    setPriceValues((prevValues) => ({
      ...prevValues,
      [i]: limitPriceInput(value),
    }));
  };

  const hasPayableTonsError = (value, area) => {
    return (
      (area === Areas.DPP_ARA && (value < 2000 || value > 10000)) ||
      (area === Areas.CPP_ARA_DWT_SMALL && (value < 500 || value > 1500)) ||
      (area === Areas.CPP_ARA_DWT_LARGE && (value < 1500 || value > 8000)) ||
      (area === Areas.CPP_RHINE && (value < 500 || value > 2500)) ||
      (area === Areas.Canals && (value < 500 || value > 3000))
    );
  };

  const getPayableTonsErrorText = () => {
    return `Please enter a value between ${
      area === Areas.DPP_ARA
        ? "2000 and 10000"
        : area === Areas.CPP_ARA_DWT_SMALL
        ? "500 and 1500"
        : area === Areas.CPP_ARA_DWT_LARGE
        ? "1500 and 8000"
        : area === Areas.Canals
        ? "500 and 3000"
        : area === Areas.CPP_RHINE
        ? "500 and 2500"
        : ""
    }`;
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async () => {
      let result = await get("form-data");

      const shouldShowOnBehalfOfData =
        user.role === "admin" || user.companyType === "broker";

      try {
        if (
          !result ||
          !result.areas ||
          !result.areas.length ||
          !result.companies ||
          !result.companies.length ||
          (shouldShowOnBehalfOfData &&
            (!result.oilCompanies || !result.oilCompanies.length))
        ) {
          setError("Something went wrong!");
          return;
        } else if (result.error) {
          setError(result.error);
          return;
        } else {
          setPortOptions(areasForDisplay(result.areas));
          setCounterpartyOptions(result.companies);
          setOilCompanies(result.oilCompanies);
        }
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleAreaChange = (event) => {
    setArea(event.target.value);
    setLoadPort("");
    setDischargePort("");
    setTons("");
    setPayableTonsError(false);
    setPrice("");
  };

  const handleLoadDateChange = (event) => {
    setLoadDate(event.target.value);
  };

  const handleLoadPortChange = (value) => {
    setLoadPort(value);
    setDischargePort("");
  };

  const handleDischargePortChange = (value) => {
    setDischargePort(value);
  };

  const limitPriceInput = (price) => {
    const priceString = String(price);
    if (priceString.indexOf(".") !== -1) {
      const behindComma = priceString.split(".")[1].length;
      if (behindComma > 2) {
        return priceString.substring(0, priceString.length - (behindComma - 2));
      }
    }
    return price;
  };

  const handlePriceChange = (event) => {
    setPrice(limitPriceInput(event.target.value));
  };

  const handleTonsChange = (event) => {
    const value = event.target.value;
    setTons(value);
    setPayableTonsError(hasPayableTonsError(value, area));
  };

  const handleBargeNameChange = (event) => {
    const inputValue = event.target.value;
    setBargeName(event.target.value);
    setBargeNameValid(!inputValue.length || alphanumericRegex.test(inputValue));
  };

  const handleCompanyNameChange = (event) => {
    setFreeEntryCompanyName(event.target.value);
  };

  const handleContactPersonChange = (event) => {
    setContactPerson(event.target.value);
  };

  const handleViaChange = (event) => {
    setVia(event.target.value);
  };

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  const handleClear = () => {
    setLoadDate("");
    setLoadPort("");
    setDischargePort("");
    setPrice("");
    setPriceType("");
    setTons("");
    setBargeName("");
    setCounterparty("");
    setProduct("");
    setArea("");
    setPriceValues({});
    setOnBehalfOf("");
    setIsIndicative(false);
    setFreeEntryCompanyName("");
    setContactPerson("");
    setVia("");
  };

  const getPortLocations = useCallback((port) => {
    if (port.indexOf("(") === -1) return [port];
    return port.substring(port.indexOf("(") + 1, port.indexOf(")")).split(", ");
  }, []);

  const getPortCombinations = useCallback(
    (load, discharge) => {
      const loadLocations = getPortLocations(load);
      const dischargeLocations = getPortLocations(discharge);
      let allCombinations = loadLocations.reduce(
        (acc, loadLocation) =>
          acc.concat(
            dischargeLocations.map((dischargeLocation) => [
              loadLocation,
              dischargeLocation,
            ]),
          ),
        [],
      );
      if (
        allCombinations.filter(([load, discharge]) => load === discharge)
          .length > 1
      ) {
        allCombinations = allCombinations.filter(
          ([load, discharge]) =>
            load !== discharge || load === "Flushing" || load === "Ghent",
        );
        allCombinations.push(["Cross harbor", "Cross harbor"]);
      }
      return allCombinations.filter(([load, discharge], i) => {
        const otherWayIndex = allCombinations.findIndex(
          ([load2, discharge2]) => load === discharge2 && discharge === load2,
        );
        return otherWayIndex <= i;
      });
    },
    [getPortLocations],
  );

  const getLocationPostData = (inIndex) => {
    const rhineOrCanals = area === Areas.CPP_RHINE || area === Areas.Canals;
    const quote = {
      area,
      loadDate: new Date(loadDate),
      loadPort: loadPort.split(" ")[0],
      dischargePort: dischargePort.split(" ")[0],
      tons: parseInt(parseInt(tons)),
      bargeName,
      freeEntryCompanyName,
      contactPerson,
      via,
      counterparty,
      product: product.replaceAll(/ \(.*/g, ""),
      priceType,
      inIndex,
      isIndicative,
      onBehalfOf,
    };
    if (priceType !== "BL date") {
      quote["price"] = parseFloat(price);
    }
    const hasMultiplePrices =
      !rhineOrCanals &&
      (loadPort.indexOf("ARA") === 0 ||
        loadPort.indexOf("FARAG") === 0 ||
        dischargePort.indexOf("ARA") === 0 ||
        dischargePort.indexOf("FARAG") === 0);
    if (priceType !== "BL date" && hasMultiplePrices) {
      return getPortCombinations(loadPort, dischargePort).map(
        ([load, discharge], i) => {
          return {
            ...quote,
            loadPort: load,
            dischargePort: discharge,
            price: parseFloat(parseFloat(priceValues[i])),
            tons: parseInt(parseInt(tons)),
            inIndex,
          };
        },
      );
    }
    return [quote];
  };

  const getPriceValidationErrors = () => {
    if (
      area === Areas.CPP_RHINE ||
      area === Areas.Canals ||
      (!(loadPort.indexOf("ARA") === 0 || loadPort.indexOf("FARAG") === 0) &&
        !(
          dischargePort.indexOf("ARA") === 0 ||
          dischargePort.indexOf("FARAG") === 0
        ))
    ) {
      if (!price) return ["Price"];
    }
    if (
      area !== Areas.CPP_RHINE &&
      area !== Areas.Canals &&
      (loadPort.indexOf("ARA") === 0 ||
        loadPort.indexOf("FARAG") === 0 ||
        dischargePort.indexOf("ARA") === 0 ||
        dischargePort.indexOf("FARAG") === 0)
    ) {
      return getPortCombinations(loadPort, dischargePort)
        .filter((_arr, i) => !priceValues[i])
        .map(([load, discharge]) => `Price for ${load} - ${discharge}`);
    }
    return [];
  };

  const handleEnter = async (inIndex = false) => {
    const missingFields = [];
    if (!loadDate) missingFields.push("Load date");
    if (!loadPort) missingFields.push("Load port");
    if (!dischargePort) missingFields.push("Discharge port");

    if (priceType !== "BL date") {
      missingFields.push(...getPriceValidationErrors());
    }
    if (!tons) missingFields.push("Tons");
    if (!bargeName) missingFields.push("Barge name");
    if (!counterparty) missingFields.push("Counterparty");
    if (!product) missingFields.push("Product");
    if (!area) missingFields.push("Area");
    if (user.companyType === "broker" && !onBehalfOf)
      missingFields.push("On behalf of");

    if (missingFields.length > 0) {
      setMessage({
        text: `Please fill in the following fields: ${missingFields.join(
          ", ",
        )}.`,
        severity: "warning",
      });
    } else {
      setLoading(true);
      try {
        const locationData = getLocationPostData(inIndex);
        try {
          const res = await post("add-quote", { quotes: locationData });
          if (!res || "error" in res) {
            setMessage({
              text:
                "Quote failed to add. Please try again or contact us." +
                (res.error ? " " + res.error : ""),
              severity: "error",
            });
            return;
          }
        } catch (err) {
          console.error(err);
          setMessage({
            text: "Quote failed to add. Please try again or contact us.",
            severity: "error",
          });
          return;
        }
        setMessage({ text: "Quote added successfully.", severity: "success" });
        handleClear();
      } finally {
        setLoading(false);
      }
    }
  };

  const generateRandomInput = () => {
    const today = new Date();
    const monthEarlier = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 13,
    );
    const randomDate = new Date(
      today.getTime() +
        Math.random() * (monthEarlier.getTime() - today.getTime()),
    );
    const randomDateFormatted = randomDate.toISOString().slice(0, 10);
    setLoadDate(randomDateFormatted);

    const areaOptions = Object.keys(portOptions);
    const randomAreaIndex = Math.floor(Math.random() * areaOptions.length);
    const randomArea = areaOptions[randomAreaIndex];
    setArea(randomArea);

    const loadPortOptions = Object.keys(portOptions[randomArea]);
    const randomLoadPortIndex = Math.floor(
      Math.random() * loadPortOptions.length,
    );
    const randomLoadPort = loadPortOptions[randomLoadPortIndex];
    setLoadPort(randomLoadPort);

    const dischargePortOptions = portOptions[randomArea][randomLoadPort];
    const randomDischargePortIndex = Math.floor(
      Math.random() * dischargePortOptions.length,
    );
    const randomDischargePort = dischargePortOptions[randomDischargePortIndex];
    setDischargePort(randomDischargePort);

    const randomPriceType =
      Math.random() < 0.3
        ? "Price per ton"
        : Math.random() < 0.5
        ? "Lumpsum"
        : "BL date";
    setPriceType(randomPriceType);

    const randomPrice = (
      Math.random() * (randomPriceType === "Lumpsum" ? 9000 : 9) +
      1
    ).toFixed(2);
    setPrice(randomPrice);

    const randomTons = Math.floor(Math.random() * 4000 + 1000);
    setTons(randomTons);

    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomLetter = letters[Math.floor(Math.random() * letters.length)];
    const randomNumber = Math.floor(Math.random() * 10 + 1);
    const randomBargeName = `${randomLetter}${randomNumber}`;
    setBargeName(randomBargeName);

    const randomCounterpartyIndex = Math.floor(
      Math.random() * counterpartyOptions.length,
    );
    const randomCounterparty = counterpartyOptions[randomCounterpartyIndex];
    setCounterparty(randomCounterparty);

    const randomOnBehalfOfIndex = Math.floor(
      Math.random() * oilCompanies.length,
    );
    const randomOnBehalfOf = oilCompanies[randomOnBehalfOfIndex];
    setOnBehalfOf(randomOnBehalfOf);

    const randomProductIndex = Math.floor(
      Math.random() * productOptions.length,
    );
    const randomProduct = productOptions[randomProductIndex];
    setProduct(randomProduct);
  };

  const calculateConfirmedPrice = (i) => {
    let numPrice = Number(price);
    let numTons = Number(tons);
    if (i !== null) numPrice = Number(priceValues[i] ? priceValues[i] : 0);
    return priceType === "Price per ton" ? numPrice : numPrice / (numTons || 1);
  };

  let today = new Date();
  let twoWeeksLater = new Date();
  twoWeeksLater.setDate(today.getDate() + 14);
  today = today.toISOString().split("T")[0];
  twoWeeksLater = twoWeeksLater.toISOString().split("T")[0];

  const renderSinglePriceOptionRow = () => {
    if (
      area === Areas.CPP_RHINE ||
      area === Areas.Canals ||
      (!(loadPort.indexOf("ARA") === 0 || loadPort.indexOf("FARAG") === 0) &&
        !(
          dischargePort.indexOf("ARA") === 0 ||
          dischargePort.indexOf("FARAG") === 0
        ))
    ) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ p: 1 }}>
              <TextField
                id="price"
                label={`Price ${
                  priceType === "Price per ton" ? "p/t" : ""
                } - ${priceType}`}
                type="number"
                value={price}
                onChange={handlePriceChange}
                placeholder="e.g. 5,00"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            {renderTonsInput()}
          </Grid>
        </Grid>
      );
    }

    return <></>;
  };

  const renderTonsInput = () => {
    return (
      <Box sx={{ p: 1 }}>
        <TextField
          id="tons"
          label={t("enter_quote_payable_tons")}
          type="number"
          value={tons}
          onChange={handleTonsChange}
          error={payableTonsError}
          helperText={payableTonsError && getPayableTonsErrorText()}
          placeholder="e.g. 2000"
          fullWidth
        />
      </Box>
    );
  };

  const renderConfirmedPriceInput = () => {
    if (
      price &&
      tons &&
      tons !== 0 &&
      (area === Areas.CPP_RHINE ||
        area === Areas.Canals ||
        !(loadPort.indexOf("ARA") === 0 || loadPort.indexOf("FARAG") === 0))
    )
      return (
        <Box sx={{ p: 1 }}>
          <TextField
            id="confirmedPrice"
            label="Confirmed price"
            value={formatNumber(calculateConfirmedPrice(null))}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            disabled
          />
        </Box>
      );

    return <></>;
  };

  const renderMultiplePriceOptionRows = () => {
    return getPortCombinations(loadPort, dischargePort).map(
      ([load, discharge], i) => (
        <React.Fragment key={i}>
          <Typography
            variant="subtitle1"
            sx={{
              ml: "12px",
              mb: 0,
              mt: 1,
              fontWeight: "600",
            }}
            gutterBottom
          >
            {load}
            {" - "}
            {discharge}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ p: 1 }}>
                <TextField
                  id={`price${i}`}
                  label={`Price ${
                    priceType === "Price per ton" ? "p/t" : ""
                  } - ${priceType}`}
                  value={priceValues[i]}
                  onChange={(event) =>
                    handlePriceValuesChange(i, event.target.value)
                  }
                  type="number"
                  placeholder="e.g. 5,00"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ p: 1 }}>
                <TextField
                  id={`tons${i}`}
                  label={i > 0 ? "" : t("enter_quote_payable_tons")}
                  sx={{
                    opacity: i > 0 ? 0.5 : 1,
                  }}
                  type="number"
                  value={tons}
                  error={i === 0 ? payableTonsError : null}
                  onChange={i === 0 ? handleTonsChange : null}
                  disabled={i > 0}
                  placeholder="e.g. 2000"
                  helperText={
                    i === 0 && payableTonsError && getPayableTonsErrorText()
                  }
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ p: 1 }}>
                <TextField
                  id={`confirmedPrice${i}`}
                  label="Confirmed price"
                  value={formatNumber(calculateConfirmedPrice(i))}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  disabled
                />
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      ),
    );
  };

  const renderPriceOptions = () => {
    if (loadPort && dischargePort && priceType && priceType !== "BL date") {
      return (
        <>
          {renderSinglePriceOptionRow()}
          {area !== Areas.CPP_RHINE &&
            area !== Areas.Canals &&
            (loadPort.indexOf("ARA") === 0 ||
              loadPort.indexOf("FARAG") === 0 ||
              dischargePort.indexOf("ARA") === 0 ||
              dischargePort.indexOf("FARAG") === 0) &&
            renderMultiplePriceOptionRows()}
          {renderConfirmedPriceInput()}
        </>
      );
    } else if (
      loadPort &&
      dischargePort &&
      priceType &&
      priceType === "BL date"
    ) {
      return renderTonsInput();
    }

    return <></>;
  };

  return (
    <SpotbargeLayout title={t("enter_quote_header")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <>
            {message.text && (
              <Alert severity={message.severity} sx={{ mb: 2 }}>
                {message.text}
              </Alert>
            )}
            {isQuoteFormDisabled() && (
              <Alert severity="warning">{t("disabled_quote")}</Alert>
            )}
            <Masonry
              sequential={+true}
              spacing={0}
              options={{
                columnWidth: 1,
              }}
              columns={{ sm: 1, md: 2 }}
              lg={{ display: "grid", gridTemplateColumns: "1fr" }}
              sx={{
                display: {
                  sm: "grid",
                  xs: "grid",
                  md: "flex",
                },
                gridTemplateColumns: "1fr",
                gridTemplateRows: "auto",
                gridTemplateAreas: `
                "item1"
                "item2"
                "item3"
                "item4"
              `,
              }}
            >
              <Grid item sm={12} sx={{ gridArea: "item1" }}>
                <OutlinedSection title={t("enter_quote_trips")}>
                  <Box sx={{ p: 1 }}>
                    <TextField
                      id="load-date"
                      label={t("enter_quote_load_date")}
                      type="date"
                      value={loadDate}
                      onChange={handleLoadDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      inputProps={{
                        min: today,
                        max: twoWeeksLater,
                      }}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <TextField
                      id="product"
                      label={t("enter_quote_load_product")}
                      value={product}
                      onChange={handleProductChange}
                      select
                      fullWidth
                      className="notranslate"
                    >
                      <MenuItem value="" disabled>
                        {t("enter_quote_select_product")}
                      </MenuItem>
                      {productOptions.map((product) => (
                        <MenuItem
                          key={product}
                          value={product}
                          className="notranslate"
                        >
                          {product}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box sx={{ p: 1 }}>
                        <TextField
                          id="barge-name"
                          label={t("enter_quote_barge_name")}
                          type="text"
                          value={bargeName}
                          onChange={handleBargeNameChange}
                          placeholder="e.g. Charis"
                          fullWidth
                          error={!bargeNameValid}
                          helperText={
                            !bargeNameValid &&
                            "Barge name can only contain letters and numbers."
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ p: 1 }}>
                        <Autocomplete
                          id="counterparty"
                          options={counterpartyOptions}
                          value={counterparty}
                          PaperComponent={(props) => (
                            <Paper {...props} className="notranslate" />
                          )}
                          onChange={(_event, newValue) => {
                            setCounterparty(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("enter_quote_counterparty")}
                              fullWidth
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    {oilCompanies.length > 0 && (
                      <Grid item xs={6}>
                        <Box sx={{ p: 1 }}>
                          <Autocomplete
                            id="onBehalfOf"
                            options={oilCompanies}
                            value={onBehalfOf}
                            PaperComponent={(props) => (
                              <Paper {...props} className="notranslate" />
                            )}
                            onChange={(_event, newValue) => {
                              setOnBehalfOf(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("enter_quote_on_behalf_of")}
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </OutlinedSection>
              </Grid>
              <Grid item sx={{ gridArea: "item2" }}>
                <OutlinedSection title={t("enter_quote_area")}>
                  <Box sx={{ p: 1 }}>
                    <TextField
                      id="area"
                      label={t("enter_quote_area_select")}
                      value={area}
                      onChange={handleAreaChange}
                      select
                      fullWidth
                      className="notranslate"
                    >
                      <MenuItem value="" disabled>
                        {t("enter_quote_area_select_option")}
                      </MenuItem>
                      {Object.keys(portOptions).map((area) => (
                        <MenuItem
                          key={area}
                          value={area}
                          className="notranslate"
                        >
                          {area}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <Autocomplete
                      id="load-port"
                      options={
                        portOptions[area] ? Object.keys(portOptions[area]) : []
                      }
                      value={loadPort}
                      onChange={(_event, newValue) => {
                        handleLoadPortChange(newValue);
                      }}
                      disableClearable
                      className="notranslate"
                      PaperComponent={(props) => (
                        <Paper {...props} className="notranslate" />
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("enter_quote_load_port")}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <Autocomplete
                      id="discharge-port"
                      options={loadPort ? portOptions[area][loadPort] : []}
                      value={dischargePort}
                      onChange={(_event, newValue) => {
                        handleDischargePortChange(newValue);
                      }}
                      PaperComponent={(props) => (
                        <Paper {...props} className="notranslate" />
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("enter_quote_discharge_port")}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </OutlinedSection>
              </Grid>
              <Grid item sx={{ gridArea: "item3" }}>
                <OutlinedSection title={t("enter_quote_price")}>
                  <Box sx={{ p: 1 }}>
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        onClick={() => setPriceType("Price per ton")}
                        variant={
                          priceType === "Price per ton"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        {t("enter_quote_ppt")}
                      </Button>
                      <Button
                        onClick={() => setPriceType("Lumpsum")}
                        variant={
                          priceType === "Lumpsum" ? "contained" : "outlined"
                        }
                      >
                        {t("enter_quote_lumpsum")}
                      </Button>
                      <Button
                        onClick={() => setPriceType("BL date")}
                        variant={
                          priceType === "BL date" ? "contained" : "outlined"
                        }
                      >
                        {t("enter_quote_bl_date")}
                      </Button>
                    </ButtonGroup>
                  </Box>
                  {renderPriceOptions()}
                </OutlinedSection>
              </Grid>
              <Grid item sx={{ gridArea: "item4" }}>
                {user && user.role === "admin" && (
                  <OutlinedSection title={"Admin manual entry"}>
                    <Box sx={{ p: 1, spacing: 2 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isIndicative}
                            onChange={(e) => setIsIndicative(e.target.checked)}
                          />
                        }
                        label="Indicative price"
                      />
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <TextField
                        label="Company name"
                        type="text"
                        value={freeEntryCompanyName}
                        onChange={handleCompanyNameChange}
                        fullWidth
                      />
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <TextField
                        label="Contact person"
                        type="text"
                        value={contactPerson}
                        onChange={handleContactPersonChange}
                        fullWidth
                      />
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <TextField
                        label="Received via"
                        type="text"
                        value={via}
                        onChange={handleViaChange}
                        fullWidth
                      />
                    </Box>
                  </OutlinedSection>
                )}
                <Box sx={{ margin: "0 8px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClear}
                        fullWidth
                      >
                        {t("enter_quote_clear_fields")}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEnter(false)}
                        fullWidth
                        disabled={
                          payableTonsError ||
                          !bargeNameValid ||
                          isQuoteFormDisabled()
                        }
                      >
                        {t("enter_quote_enter_quote")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Masonry>
            {user && user.role === "admin" && (
              <Box sx={{ p: 1 }}>
                <Typography>Testing options:</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={generateRandomInput}
                  fullWidth
                >
                  {t("enter_quote_randomize")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEnter(true)}
                  fullWidth
                >
                  {t("enter_quote_admin_only")}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(QuoteForm, {
  fallback: <GeneralErrorPage />,
});
