import { Grid, Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)({
  textAlign: "center",
  display: "grid",
  borderRadius: 0,
  overflow: "hidden",
  gap: "10px",
  height: "70px",
  whiteSpace: "nowrap",
});

const StyledBadge = styled(Grid)({
  minWidth: 12,
  minHeight: 12,
  backgroundColor: "red",
  position: "absolute",
  top: 4,
  right: 4,
  borderRadius: "50%",
});

const SidebarButton = ({ title, icon, onClick, hasChanges, disabled }) => (
  <Tooltip
    title={title}
    placement="right"
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: "common.white",
          color: "common.black",
          "& .MuiTooltip-arrow": {
            color: "common.white",
          },
        },
      },
    }}
  >
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      variant="text"
      size="large"
      color="primary"
      fullWidth
    >
      {hasChanges && <StyledBadge></StyledBadge>}
      {icon}
    </StyledButton>
  </Tooltip>
);

export default SidebarButton;
