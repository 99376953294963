import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "../firebase";
import { RecaptchaVerifier } from "@firebase/auth";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Alert,
  Button,
  CircularProgress,
  TextField,
  Paper,
  Grid,
  Divider,
  Fade,
  Collapse,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Google,
  Email,
  Microsoft,
  Phone,
  PhoneIphone,
  PhonelinkLock,
  EmailOutlined,
  ArrowRight,
  ArrowDropDown,
} from "@mui/icons-material";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import { Popup } from "./homepage/LaptopWithPopups";
import popup1 from "../assets/popup1.png";
import popup2 from "../assets/popup2.png";
import popup3 from "../assets/popup3.png";
import ship from "../assets/ship.gif";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(1, 3),
  height: "60px",
}));

function SignInScreen() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState({
    googleLoading: false,
    buttonLoading: false,
    microsoftLoading: false,
    phoneLoading: false,
    phoneVerificationLoading: false,
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    message: "",
    open: false,
  });
  const [phoneError, setPhoneError] = useState({
    message: "",
    open: false,
  });
  const [email, setEmail] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      /*Every sign in method will change currentUser and redirect to /login-success
       * no need to use getRedirectResult to handle successful logins
       * we only check error for each login method
       */
      window.location.href = "/login-success";
    } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        setEmail("");
      } else {
        setEmail(email);
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(() => {
            window.localStorage.removeItem("emailForSignIn");
            window.location.href = "/login-success";
          })
          .catch((error) => {
            console.log(error);
            setError({
              message:
                "An error occurred when verifying your login link. Please try again.",
              open: true,
            });
          });
      }
    } else {
      setIsLoading(false);
    }
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  const handlePhoneLogin = async (e) => {
    e.preventDefault();
    setPhoneError({ message: "", open: false });
    setLoader((prevState) => ({ ...prevState, buttonLoading: true }));
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "captcha-container",
        {
          size: "invisible",
          callback: (response) => { },
        },
        firebase.auth(),
      );
    }

    try {
      const result = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier);

      setConfirmationResult(result);
      setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      setShowPhone(false);
      setPhoneNumber("");
    } catch (error) {
      setPhoneNumber("");
      setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      setPhoneError({ message: error.message, open: true });
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setPhoneError({ message: "", open: false });
    setLoader((prevState) => ({ ...prevState, buttonLoading: true }));
    try {
      await confirmationResult.confirm(confirmationCode);
    } catch (error) {
      setConfirmationCode("");
      setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      setPhoneError({ message: error.message, open: true });
    }
  };

  const handleGoogleLogin = () => {
    setLoader({ googleLoading: true, buttonLoading: false });
    const auth = firebase.auth();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(googleProvider).catch((error) => {
      setLoader({ googleLoading: false, buttonLoading: false });
      setError({ message: error.message, open: true });
      setIsLoading(false);
    });
  };

  const handleMicrosoftLogin = () => {
    setLoader((prevState) => ({ ...prevState, microsoftLoading: true }));
    const auth = firebase.auth();
    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    auth.signInWithPopup(provider).catch((error) => {
      setLoader({ microsoftLoading: false, buttonLoading: false });
      setError({ message: error.message, open: true });
      setIsLoading(false);
    });
  };

  const handleEmailLinkLogin = (e) => {
    e.preventDefault();
    setLoader((prevState) => ({ ...prevState, buttonLoading: true }));

    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
        setSuccess(true);
        setShowEmail(false);
        setEmail("");
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleConfirmationCodeChange = (event) => {
    setConfirmationCode(event.target.value);
  };

  const renderPhoneLoginForm = () => {
    return (
      <Collapse in={showPhone} collapsedSize={0}>
        <form
          onSubmit={handlePhoneLogin}
          style={{ width: "100%", padding: "10px" }}
        >
          <Divider>
            <PhoneIphone />
          </Divider>
          {phoneError.open && (
            <Alert severity="error">{phoneError.message}</Alert>
          )}
          <Box mb={2}>
            <TextField
              label="Phone Number"
              variant="outlined"
              margin="normal"
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box mb={2} sx={{ textAlign: "center" }}>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={loader.buttonLoading || !phoneNumber}
              fullWidth
              sx={{ margin: "0px" }}
            >
              {loader.buttonLoading ? "Loading..." : "Send Confirmation code"}
            </StyledButton>
          </Box>
        </form>
      </Collapse>
    );
  };
  const renderConfirmationForm = () => {
    return (
      <Collapse in={confirmationResult} collapsedSize={0}>
        <form
          onSubmit={handleCodeSubmit}
          style={{ width: "100%", padding: "10px" }}
        >
          <Divider>
            <PhonelinkLock />
          </Divider>
          {phoneError.open && (
            <Alert severity="error">{phoneError.message}</Alert>
          )}
          {!phoneError.open && (
            <Alert severity="success">
              {t("login_confirmation_code_sent")}
            </Alert>
          )}
          <Box mb={2}>
            <TextField
              label="Confirmation code"
              variant="outlined"
              margin="normal"
              type="number"
              value={confirmationCode}
              onChange={handleConfirmationCodeChange}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box mb={2} sx={{ textAlign: "center" }}>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={loader.buttonLoading || !confirmationCode}
              fullWidth
              sx={{ margin: "0px" }}
            >
              {loader.buttonLoading ? "Loading..." : "Submit confirmation code"}
            </StyledButton>
          </Box>
        </form>
      </Collapse>
    );
  };

  const renderEmailLoginForm = () => {
    return (
      <Collapse in={showEmail} collapsedSize={0}>
        <form
          onSubmit={handleEmailLinkLogin}
          style={{ width: "100%", padding: "10px" }}
        >
          <Divider>
            <EmailOutlined />
          </Divider>
          <Box mb={2}>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type={"email"}
              value={email}
              onChange={handleEmailChange}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box mb={2} sx={{ textAlign: "center" }}>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={loader.buttonLoading || !email}
              fullWidth
              sx={{ margin: "0px" }}
            >
              {loader.buttonLoading ? "Loading..." : "Send login link"}
            </StyledButton>
          </Box>
        </form>
      </Collapse>
    );
  };
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Fade in={true}>
      <Grid
        container
        display="grid"
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          gridTemplateColumns: {
            lg: "2fr 10fr 2fr",
            md: "12fr",
          },
          minHeight: "80vh",
        }}
      >
        <Grid
          item
          sx={{
            position: "relative",
            opacity: 0.4,
            filter: "blur(2px) grayscale(100%)",
            display: {
              lg: "flex",
              xl: "flex",
              md: "none",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Popup
            src={popup1}
            alt="popup1"
            top="40%"
            right="10%"
            width={"250%"}
          />
        </Grid>
        <Paper
          elevation={7}
          sx={{
            maxWidth: "529px",
            margin: "3% auto",
            height: "fit-content",
            borderTopLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
        >
          <Grid display="grid" direction="row" p={5}>
            <div id="captcha-container" style={{ display: "none" }}></div>
            <Title variant="h2">{t("login_header")}</Title>
            <Divider>
              <img src={ship} width="200px" alt="ship" />
            </Divider>
            {error.open && <Alert severity="error">{error.message}</Alert>}
            {success && (
              <Alert severity="success" sx={{ mt: "16px" }}>
                {t("login_link_sent", { email })}
                <br />
                {t("login_link_sent2")}
              </Alert>
            )}
            <>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<Google />}
                onClick={handleGoogleLogin}
                disabled={
                  loader.googleLoading ||
                  loader.buttonLoading ||
                  confirmationResult
                }
                sx={{ minWidth: "250px" }}
              >
                {loader.googleLoading ? "Loading..." : "Sign in with Google"}
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<Microsoft />}
                onClick={handleMicrosoftLogin}
                disabled={
                  loader.microsoftLoading ||
                  loader.buttonLoading ||
                  confirmationResult
                }
                sx={{ minWidth: "250px" }}
              >
                {loader.microsoftLoading
                  ? "Loading..."
                  : "Sign in with Microsoft"}
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<Email />}
                endIcon={showEmail ? <ArrowDropDown /> : <ArrowRight />}
                onClick={() => {
                  setShowEmail((showEmail) => !showEmail);
                  setShowPhone(false);
                }}
                disabled={loader.buttonLoading || confirmationResult}
                sx={{ minWidth: "250px" }}
              >
                {loader.buttonLoading ? "Loading..." : "Sign in with Email"}
              </StyledButton>
              {renderEmailLoginForm()}
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<Phone />}
                endIcon={showPhone ? <ArrowDropDown /> : <ArrowRight />}
                onClick={() => {
                  setShowPhone((showPhone) => !showPhone);
                  setShowEmail(false);
                }}
                disabled={loader.buttonLoading || confirmationResult}
                sx={{ minWidth: "250px" }}
              >
                {loader.buttonLoading ? "Loading..." : "Sign in with Phone"}
              </StyledButton>
              {renderPhoneLoginForm()}
              {renderConfirmationForm()}
            </>
          </Grid>
        </Paper>
        <Grid
          item
          sx={{
            position: "relative",
            opacity: 0.4,
            filter: "blur(2px) grayscale(100%)",
            display: {
              lg: "flex",
              xl: "flex",
              md: "none",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Popup
            src={popup2}
            alt="popup2"
            left="-10%"
            top="10%"
            width={"170%"}
          />
          <Popup
            src={popup3}
            alt="popup3"
            bottom="15%"
            left="60%"
            width={"200%"}
          />
        </Grid>
      </Grid>
    </Fade>
  );
}

export default withErrorBoundary(SignInScreen, {
  fallback: <GeneralErrorPage />,
});
