import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Alert } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import GeneralErrorPage from "./GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { usePost } from "../request";
import { AppCheckSdkContext } from "reactfire";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  padding: theme.spacing(4),
  height: "100%",
}));

function ConfirmQuote() {
  const { token, accept } = useParams();
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const sdk = useContext(AppCheckSdkContext);

  const post = usePost();

  useEffect(() => {
    async function verifyQuote() {
      try {
        const response = await post(
          "verify",
          {
            token,
            accept: accept === "true",
          },
        );
        if (response.success) {
          setMessage({
            severity: "success",
            text: `Thank you for ${accept === "true" ? "confirming" : "rejecting"} the quote.`,
          });
        } else if (response.error) {
          setMessage({ severity: "error", text: response.error });
        } else {
          setMessage({
            severity: "error",
            text: "An error occurred. Please try again or contact us.",
          });
        }
      } catch (error) {
        setMessage({
          severity: "error",
          text: "An error occurred. Please try again or contact info@spotbarge.com.",
        });
      } finally {
        setLoading(false);
      }
    }

    if(process.env.REACT_APP_ENV === "dev" || sdk) {
      verifyQuote();
    }
  }, [token, accept, sdk]);

  return (
    <Container>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Box sx={{ marginTop: "16px" }}>
            Please wait, {accept === "true" ? "confirming" : "rejecting"}{" "}
            quote in progress...
          </Box>
        </Box>
      ) : (
        <Alert severity={message.severity} sx={{ mb: 2 }}>
          {message.text}
        </Alert>
      )}
    </Container>
  );
}

export default withErrorBoundary(ConfirmQuote, {
  fallback: <GeneralErrorPage />,
});
