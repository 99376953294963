import { memo } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@mui/material";

const DeleteFleetDialog = ({
  open,
  onClose,
  fleetToDelete,
  deleteFleet,
  mapRef,
}) => {
  return (
    <Dialog
      container={mapRef.current}
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 9999999999999,
      }}
    >
      <DialogTitle>Delete Fleet</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the fleet "{fleetToDelete?.name}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          onClick={async () => {
            await deleteFleet();
            onClose();
          }}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeleteFleetDialog, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open;
});
