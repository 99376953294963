import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Bar,
  BarChart as RechartBarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Rectangle,
  Cell,
  Brush,
  LabelList,
} from "recharts";

const colors = [
  "#16A085",
  "#2980B9",
  "#8E44AD",
  "#2C3E50",
  "#F39C12",
  "#D35400",
  "#C0392B",
  "#7F8C8D",
  "#F1C40F",
];

function BarChart({
  data,
  dataKey,
  single = true,
  enableYAxisLabels = true,
  enableLegend = true,
  enableTooltip = true,
  showBrush = false,
  customStartIndex = 31,
  handleFuture = false,
}) {
  const renderBars = () => {
    if (single) {
      return Array.isArray(dataKey) ? (
        <>
          {handleFuture &&
            dataKey.map((key, index) => {
              return (
                <Bar
                  dataKey={key}
                  stackId="a"
                  fill="#2980B9"
                  fillOpacity={key.toLowerCase().includes("future") ? 0.5 : 1}
                />
              );
            })}
          {!handleFuture &&
            dataKey.map((key, index) => (
              <Bar dataKey={key} stackId="a" fill={colors[index % 20]} />
            ))}
        </>
      ) : (
        <Bar dataKey={dataKey} stackId="a" fill="#2980B9" />
      );
    }

    return (
      <Bar
        dataKey={dataKey}
        fill="#8884d8"
        activeBar={<Rectangle fill="pink" stroke="blue" />}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        ))}
      </Bar>
    );
  };

  const renderLegend = () => {
    if (enableLegend) return <Legend />;

    return <></>;
  };

  const renderTooltip = () => {
    if (enableTooltip)
      return <Tooltip itemStyle={{ fontSize: "11px", fontWeight: "bold" }} />;

    return <></>;
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <RechartBarChart
        width={500}
        height={250}
        data={data}
        fontSize={12}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" />
        <YAxis hide={!enableYAxisLabels} />
        {renderTooltip()}
        {renderLegend()}
        {renderBars()}
        {showBrush && (
          <Brush
            height={14}
            startIndex={
              data.length >= customStartIndex
                ? data.length - customStartIndex
                : 0
            }
            stroke="#6f6f6f"
            alwaysShowText
          />
        )}
      </RechartBarChart>
    </ResponsiveContainer>
  );
}

BarChart.propTypes = {
  dataKey: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.any,
  single: PropTypes.bool,
  enableYAxisLabels: PropTypes.bool,
  enableLegend: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  showBrush: PropTypes.bool,
};

export default BarChart;
