import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Stack,
  CircularProgress,
  Alert,
  Fade,
} from "@mui/material";
import {
  VisibilityOff,
  WaterDrop,
  Air,
  Water,
  FilterDrama,
  WbSunny,
  NightsStay,
  Thermostat,
} from "@mui/icons-material";
import icons from "./icons.json";
import dayImg from "../../assets/day.png";
import nightImg from "../../assets/night.png";
import * as Icons from "./ImageImporter";
import { useGet } from "../../request";

const determineIcon = (icon) => {
  return icons.find((i) => i.icon === icon.icon && i.id === icon.id);
};

function WeatherOverview({ city }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [weatherData, setWeatherData] = useState(null);

  const get = useGet();

  function getMatchingIconName(city) {
    const iconSrc = determineIcon(city.weather[0])["src"];
    const matchingIconName = iconSrc
      .split(".")[0]
      .replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
    return Icons[matchingIconName];
  }

  const fetchData = async () => {
    setLoading(true);
    const weatherResponse = await get("location-weather-overview", {
      lat: city.coord.lat,
      lon: city.coord.lon,
    });

    if (weatherResponse && weatherResponse.error) {
      setError(weatherResponse.error);
      return;
    }

    setWeatherData(weatherResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (city) fetchData();
  }, [city]);

  function formatWeatherDate(unixTimestamp, timezone) {
    const date = new Date(unixTimestamp * 1000);
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: timezone,
    };
    return date.toLocaleString("en-US", options);
  }

  return (
    <Grid container p={4} flexDirection="column" sx={{ minHeight: "672px" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && weatherData && city && (
        <>
          <Fade in={true}>
            <Grid container>
              <Typography
                variant="h4"
                component="caption"
                sx={{ fontWeight: 400 }}
              >
                Forecast in{" "}
                <b>
                  {city.name}, {city.sys.country}
                </b>
              </Typography>
            </Grid>
          </Fade>
          <Fade in={true}>
            <Grid container mb={5}>
              <Typography variant="subtitle1" component="caption">
                {formatWeatherDate(
                  weatherData.current.dt,
                  weatherData.timezone,
                )}
              </Typography>
            </Grid>
          </Fade>
          <Fade in={true}>
            <Grid
              container
              mb={6}
              rowGap={7}
              justifyContent="space-between"
              gap={2}
            >
              <Grid
                container
                lg={5}
                sx={{ backgroundColor: "#3292d721", p: 3, borderRadius: 1.5 }}
                alignContent="space-between"
              >
                <Grid
                  container
                  flexWrap="nowrap"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h2" component="h3">
                      {Math.round(weatherData.current.temp)}°C
                    </Typography>
                  </Grid>
                  <Grid item flexDirection="column" textAlign="right">
                    <Typography variant="h6" component="h2">
                      High {Math.round(weatherData.daily[0].temp.max)}°C
                    </Typography>
                    <Typography component="h3" sx={{ fontSize: "17px" }}>
                      Low {Math.round(weatherData.daily[0].temp.min)}°C
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  flexWrap="nowrap"
                  alignItems="center"
                >
                  <Grid container flexDirection="column">
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ opacity: 0.5 }}
                    >
                      {weatherData.current.weather[0].description}
                    </Typography>
                    <Typography variant="h6" component="h3">
                      Feels like {weatherData.current.feels_like}°C
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ opacity: weatherData.daily[0].rain ? 1 : 0.2 }}
                    >
                      Rain {weatherData.daily[0].rain ?? 0} mm
                    </Typography>
                  </Grid>
                  <img
                    src={getMatchingIconName(weatherData.current)}
                    style={{
                      maxWidth: "130px",
                      filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.2))",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                lg={3}
                md={5}
                sx={{ backgroundColor: "#52f4d278", p: 3, borderRadius: 1.5 }}
              >
                <Stack sx={{ minWidth: "100%" }} spacing={1}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1" component="caption">
                      Visibility
                    </Typography>
                    <Typography variant="subtitle1" component="caption">
                      <Grid container gap={1}>
                        {weatherData.current.visibility / 1000}km
                        <VisibilityOff />
                      </Grid>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1" component="caption">
                      Dew Point
                    </Typography>
                    <Typography variant="subtitle1" component="caption">
                      <Grid container gap={1}>
                        {Math.round(weatherData.current.dew_point)}°C
                        <Thermostat />
                      </Grid>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1" component="caption">
                      Wind
                    </Typography>
                    <Typography variant="subtitle1" component="caption">
                      <Grid container gap={1}>
                        {weatherData.current.wind_speed}mph
                        <Air />
                      </Grid>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1" component="caption">
                      Humidity
                    </Typography>
                    <Typography variant="subtitle1" component="caption">
                      <Grid container gap={1}>
                        {weatherData.current.humidity}%
                        <Water />
                      </Grid>
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container justifyContent="space-between">
                    <Typography variant="subtitle1" component="caption">
                      Cloudiness
                    </Typography>
                    <Typography variant="subtitle1" component="caption">
                      <Grid container gap={1}>
                        {weatherData.current.clouds}%
                        <FilterDrama />
                      </Grid>
                    </Typography>
                  </Grid>
                </Stack>
              </Grid>
              <Grid
                container
                lg={3}
                md={5}
                sx={{
                  background: `url('${
                    weatherData.current.dt >= weatherData.current.sunrise &&
                    weatherData.current.dt <= weatherData.current.sunset
                      ? dayImg
                      : nightImg
                  }')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: 1.5,
                }}
              >
                <Grid
                  container
                  sx={{ p: 3, backgroundColor: "#dedede73" }}
                  gap={2}
                >
                  <Grid container flexDirection="column" alignItems="center">
                    <WbSunny />
                    <Typography variant="h5" component="caption">
                      Sunrise
                    </Typography>
                    <Typography variant="h4" component="caption">
                      {new Date(
                        weatherData.current.sunrise * 1000,
                      ).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </Typography>
                  </Grid>
                  <Grid container flexDirection="column" alignItems="center">
                    <NightsStay />
                    <Typography variant="h5" component="caption">
                      Sunset
                    </Typography>
                    <Typography variant="h4" component="caption">
                      {new Date(
                        weatherData.current.sunset * 1000,
                      ).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
          <Fade in={true}>
            <Grid
              container
              flexDirection="row"
              gap={2}
              justifyContent="space-between"
            >
              {weatherData.daily.map((day, index) =>
                index !== 0 ? (
                  <Grid
                    container
                    lg={1.5}
                    md={3}
                    sm={5}
                    xs={12}
                    sx={{
                      backgroundColor: "#3292d721",
                      p: 2,
                      justifyContent: "center",
                      borderRadius: 1.5,
                    }}
                  >
                    <Typography variant="subtitle1" component="caption">
                      {
                        formatWeatherDate(day.dt, weatherData.timezone).split(
                          ",",
                        )[0]
                      }
                    </Typography>
                    <img
                      src={getMatchingIconName(day)}
                      style={{
                        maxWidth: "120px",
                        filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.2))",
                      }}
                    />
                    <Grid container direction="column" alignItems="center">
                      <Grid
                        container
                        width="max-content"
                        alignItems="center"
                        sx={{ opacity: day.rain ? 1 : 0.2 }}
                      >
                        <Typography variant="subtitle2">
                          {day.rain ?? 0}mm
                        </Typography>
                        <WaterDrop variant="small" sx={{ fontSize: "15px" }} />
                      </Grid>
                      <Typography variant="subtitle2">
                        {Math.round(day.temp.max)}°C
                      </Typography>
                      <Typography variant="caption">
                        {Math.round(day.temp.min)}°C
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null,
              )}
            </Grid>
          </Fade>
        </>
      )}
    </Grid>
  );
}

export default React.memo(WeatherOverview);
