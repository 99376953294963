import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useGet } from "../../request";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import BarChart from "../common/charts/BarChart";

const GraphTrips = ({ area }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(30);
  const [error, setError] = useState(null);
  const [trips, setTrips] = useState({
    "CPP Rhine": [],
    "CPP ARA > 1500 ton": [],
  });
  const get = useGet();

  const filterCategoriesArray = useMemo(
    () => [
      { All: 10000 },
      { "6 Months": 180 },
      { "1 Month": 30 },
      { "1 Week": 7 },
    ],
    [],
  );

  const fetchGraphDealSizesData = async () => {
    setLoading(true);
    try {
      let result = await get("trips");
      if (!result) {
        setError("Something went wrong!");
        return;
      }
      if (result.error) {
        setError(result.error);
        return;
      }
      setTrips(result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraphDealSizesData();
  }, []);

  const filteredDashboardData = useMemo(() => {
    if (!trips[area]) return [];
    if (filter === 10000) return trips[area];

    const now = new Date();

    const filteredData = trips[area].filter((trip) => {
      const filterDate = new Date(trip.filterDate);
      const tripDate = new Date(trip.date);
      return (
        filterDate >= new Date(now.getTime() - filter * 24 * 60 * 60 * 1000) ||
        tripDate >= now
      );
    });

    return filteredData;
  }, [trips, filter, area]);

  const tickFormatter = (date) => {
    date = new Date(date);
    return (
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0")
    );
  };

  const transformedGraphData = filteredDashboardData
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((change) => {
      const maped = {
        x: tickFormatter(change.date),
        colorDate: change.date,
      };
      const today = new Date().setHours(0, 0, 0, 0);
      if (new Date(change.date).setHours(0, 0, 0, 0) > today) {
        maped["Future Trips"] = change.tripsPerDay;
      } else {
        maped["Trips"] = change.tripsPerDay;
      }
      return maped;
    });

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {t("trips")}
      </Typography>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!error && !loading && trips && (
        <>
          <BarChart
            data={transformedGraphData}
            dataKey={["Trips", "Future Trips"]}
            enableLegend={false}
            showBrush
            handleFuture
          />
          <Grid container gap={0} justifyContent="center">
            {filterCategoriesArray.map((item, index) => (
              <Button
                key={index}
                sx={{
                  height: "30px",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === filterCategoriesArray.length - 1
                      ? "0 5px 5px 0"
                      : 0,
                }}
                variant={
                  filter === item[Object.keys(item)[0]]
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setFilter(item[Object.keys(item)[0]])}
              >
                {Object.keys(item)[0]}
              </Button>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default withErrorBoundary(React.memo(GraphTrips), {
  fallback: <GeneralErrorPage />,
});
