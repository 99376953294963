import React from "react";
import { AppCheckProvider, useFirebaseApp } from "reactfire";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

export default function FirebaseAppCheckProvider({ children, recaptchaId }) {
  const app = useFirebaseApp();

  if(process.env.NODE_ENV === "development") {
    return <>{children}</>;
  }
  const provider = new ReCaptchaEnterpriseProvider(recaptchaId);

  const sdk = initializeAppCheck(app, {
    provider,
    isTokenAutoRefreshEnabled: true,
  });

  return <AppCheckProvider sdk={sdk}>{children}</AppCheckProvider>;
}
