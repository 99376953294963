import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Delete, Edit, DirectionsBoat, OilBarrel } from "@mui/icons-material";
import SpotbargeTable from "../common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../common/SpotbargeLayout";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import { useGet, usePost } from "../../request";
import { validProducts } from "../../utils";

function AdminCompanies() {
  const { t } = useTranslation();

  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [editingCompany, setEditingCompany] = useState({});
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newCompanyEmail, setNewCompanyEmail] = useState("");
  const [newCompanyIsShipping, setNewCompanyIsShipping] = useState(false);
  const [productEmails, setProductEmails] = useState([]);

  const get = useGet();
  const post = usePost();

  const handleCloseDialog = () => {
    setNewCompanyName("");
    setNewCompanyEmail("");
    setNewCompanyIsShipping(false);
    setEditingCompany({});
    setProductEmails([]);
  };

  const handleOpenDialog = () => {
    setEditingCompany({ isEditing: true, isNew: true });
  };

  const handleStartEdit = (row) => {
    setNewCompanyName(row.name);
    setNewCompanyEmail(row.email);
    setNewCompanyIsShipping(row.isShipping);
    setProductEmails(row.productEmails || []);
    setEditingCompany({ isEditing: true, isNew: false, company: row });
  };

  const addRow = () => {
    setProductEmails([...productEmails, { product: '', email: '' }]);
  };

  const handleChange = (index, field) => (event) => {
    const newProductEmails = [...productEmails];
    newProductEmails[index][field] = event.target.value;
    setProductEmails(newProductEmails);
  };

  const deleteRow = (index) => {
    setProductEmails(old => old.filter((_, i) => i !== index));
  };

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const result = await get("admin-companies");
      if (result && !result.error) {
        setData(result);
      } else {
        setError(result?.error || t("admincompanies_something_went_wrong"));
      }
    } catch (error) {
      setError(t("admincompanies_something_went_wrong"));
    } finally {
      setLoading(false);
    }
  };

  const handleAddCompany = async () => {
    setLoading(true);
    try {
      const response = await post("admin-add-company", {
        name: newCompanyName,
        email: newCompanyEmail,
        isShipping: newCompanyIsShipping,
        productEmails,
        edit: editingCompany.company && editingCompany.company.name ? editingCompany.company.name : null,
      });
      if (response.success) {
        handleCloseDialog();
      } else {
        setError(
          response.error || "An error occurred while adding the company.",
        );
      }
    } catch (error) {
      setError(
        "An error occurred. Please try again or contact info@spotbarge.com.",
      );
    } finally {
      setLoading(false);
      fetchCompanies();
    }
  };

  const columns = [
    { id: "name", label: "Company Name" },
    { id: "email", label: "Email" },
    { id: "productEmails", label: "Product Emails" },
    { id: "isShipping", label: "Company Type" },
    { id: "actions", label: t("adminuser_actions") },
  ];

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(fetchCompanies);
    return () => unsubscribe();
  }, []);

  const filteredData = data.filter(
    (row) => filter === "All" || row.isShipping === (filter === "Shipping"),
  );

  const handleFilterChange = (event) => {
    setFilter(event.currentTarget.dataset.filter);
  };

  const handleDelete = async (row) => {
    try {
      let result = await post("admin-delete-company", {
        id: row.name,
      });
      if (!result || result.error) {
        setError(result?.error || t("admincompanies_something_went_wrong"));
        return;
      }
      if (result.success) {
        setData(data.filter((r) => r.name !== row.name));
      }
    } catch (error) {
      setError(t("admincompanies_something_went_wrong"));
    }
  };

  const renderActions = (row) => (
    <>
      <IconButton color="error" onClick={() => handleDelete(row)}>
        <Tooltip title="Delete Company">
          <Delete />
        </Tooltip>
      </IconButton>
      <IconButton
        color="primary"
        onClick={() => handleStartEdit(row)}
      >
        <Tooltip title={t("adminuser_change_role_write")}>
          <Edit onClick={() => handleStartEdit(row)} />
        </Tooltip>
      </IconButton>
    </>
  );
  const columnRenderMap = {
    productEmails: (row) => (
      <>
        {row.productEmails && row.productEmails.map((productEmail, index) => (
          <div key={index}>
            <b>{productEmail.product}:</b> {productEmail.email}
          </div>
        ))}
      </>
    ),
    isShipping: (row) => (
      <>
        {row.isShipping ? (
          <DirectionsBoat color="primary" />
        ) : (
          <OilBarrel color="secondary" />
        )}
      </>
    ),
    actions: renderActions,
  };

  return (
    <SpotbargeLayout title="Admin Companies Management">
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 0 }}
            >
              <ButtonGroup variant="outlined" sx={{ mb: 2 }}>
                <Button
                  onClick={handleFilterChange}
                  variant={filter === "All" ? "contained" : "outlined"}
                  data-filter="All"
                >
                  All
                </Button>
                <Button
                  onClick={handleFilterChange}
                  variant={filter === "Shipping" ? "contained" : "outlined"}
                  data-filter="Shipping"
                >
                  Shipping
                </Button>
                <Button
                  onClick={handleFilterChange}
                  variant={filter === "Oil" ? "contained" : "outlined"}
                  data-filter="Oil"
                >
                  Oil
                </Button>
              </ButtonGroup>
              <Button variant="contained" onClick={handleOpenDialog}>
                Add Company
              </Button>
            </Box>
            <SpotbargeTable
              data={filteredData}
              columns={columns}
              columnRenderMap={columnRenderMap}
              paginate={filteredData.length > 25}
            />
          </>
        )}
      </Box>
      <Dialog open={editingCompany.isEditing} onClose={handleCloseDialog}>
        <DialogTitle>{editingCompany.isNew ? "Add New": "Edit"} Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Company Name"
            type="text"
            fullWidth
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Company Email"
            type="email"
            fullWidth
            value={newCompanyEmail}
            onChange={(e) => setNewCompanyEmail(e.target.value)}
          />
          <Box>
            {productEmails.map((productEmail, index) => (
              <Box key={index} display="flex" gap={2} sx={{mb: 1}}>
                <Select value={productEmail.product} displayEmpty onChange={handleChange(index, 'product')}>
                  <MenuItem value="" disabled>Select Product</MenuItem>
                  {validProducts.map((option, i) => (
                    <MenuItem key={i} value={option}>{option}</MenuItem>
                  ))}
                </Select>
                <TextField value={productEmail.email} onChange={handleChange(index, 'email')} placeholder="Email" />
                <IconButton onClick={() => deleteRow(index)}>
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button onClick={addRow}>Add Email for Specific Product</Button>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={newCompanyIsShipping}
                onChange={(e) => setNewCompanyIsShipping(e.target.checked)}
                name="isShipping"
              />
            }
            label="Is this a shipping company?"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCompany} color="primary">
            {editingCompany.isNew ? "Add": "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(AdminCompanies, {
  fallback: <GeneralErrorPage />,
});
