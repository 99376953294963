import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { CheckCircle } from "@mui/icons-material";
import Zoom from "@mui/material/Zoom";
import { useNavigate } from "react-router-dom";

const ToReadMore = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      display="grid"
      sx={{
        pt: "82px !important",
        p: [5, 5, 10, 10, 10],
        maxWidth: 850,
        margin: "0px auto",
      }}
    >
      <Box sx={{ pt: 0.5 }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={150}
          animation="wave"
        />
        <Skeleton width={50} />
        <Skeleton width="100%" animation="wave" />
        <Skeleton
          width="100%"
          sx={{
            background:
              "linear-gradient(100deg, rgb(0, 18, 85) 50%, rgb(115 221 201) 100%)",
          }}
        />
      </Box>
      <CardContent width="100%" pt={2}>
        <Typography variant="h4" component="div" gutterBottom>
          Unlock Exclusive Content
        </Typography>
        <Typography variant="h6" paragraph>
          To read this article and many more, please register for free and
          enjoy:
        </Typography>
        <Zoom in>
          <Grid container alignItems="center" gap={0.5} pl={1}>
            <CheckCircle fontSize="small" sx={{ color: "#4d9ea6" }} />
            <Typography variant="body1">
              <b>In-Depth Market Insights:</b> Analyses and expert commentary on
              market trends.
            </Typography>
          </Grid>
        </Zoom>
        <Zoom in style={{ transitionDelay: "200ms" }}>
          <Grid container alignItems="center" gap={0.5} pl={1}>
            <CheckCircle fontSize="small" sx={{ color: "#4d9ea6" }} />
            <Typography variant="body1">
              <b>Index Prices:</b> Real-time and historical data on major market
              indices.
            </Typography>
          </Grid>
        </Zoom>
        <Zoom in style={{ transitionDelay: "400ms" }}>
          <Grid container alignItems="center" gap={0.5} pl={1}>
            <CheckCircle fontSize="small" sx={{ color: "#4d9ea6" }} />
            <Typography variant="body1">
              <b>Water Levels:</b> Critical updates on water conditions for
              rivers, lakes, and reservoirs.
            </Typography>
          </Grid>
        </Zoom>
        <Zoom in style={{ transitionDelay: "600ms" }}>
          <Grid container alignItems="center" gap={0.5} pl={1}>
            <CheckCircle fontSize="small" sx={{ color: "#4d9ea6" }} />
            <Typography variant="body1">
              <b>Snow Levels:</b> Accurate information on snow accumulation and
              conditions.
            </Typography>
          </Grid>
        </Zoom>
        <Zoom in style={{ transitionDelay: "800ms" }}>
          <Grid container alignItems="center" gap={0.5} pl={1}>
            <CheckCircle fontSize="small" sx={{ color: "#4d9ea6" }} />
            <Typography variant="body1">
              <b>Reach Charts:</b> Detailed metrics on prices and trips
            </Typography>
          </Grid>
        </Zoom>
        <Zoom in style={{ transitionDelay: "1000ms" }}>
          <Grid container alignItems="center" gap={0.5} pl={1}>
            <CheckCircle fontSize="small" sx={{ color: "#4d9ea6" }} />
            <Typography variant="body1">
              <b>Weather Forecasts:</b> Reliable predictions on temperature,
              precipitation, and more.
            </Typography>
          </Grid>
        </Zoom>
      </CardContent>
      <Divider />
      <Button variant="text" color="primary" onClick={() => navigate("/login")}>
        Log in / Register
      </Button>
    </Grid>
  );
};

export default ToReadMore;
