import { Grid, Paper, Box } from "@mui/material";
import { AdsClick } from "@mui/icons-material";
import { keyframes } from "@emotion/react";

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
`;

function Legend({ map }) {
  return (
    <Paper
      elevation={5}
      sx={{
        p: 2,
        opacity: 0.8,
        zIndex: 1000,
        position: "absolute",
        left: 10,
        bottom: 10,
      }}
    >
      <Grid container display="grid">
        <Grid container alignItems="center">
          <Box
            sx={{ width: 30, height: 10, backgroundColor: "#001256", mr: 2 }}
          ></Box>
          <div>Water level</div>
          <AdsClick
            fontSize="small"
            color="#001256"
            sx={{ animation: `${pulse} 2s infinite`, ml: 0.5 }}
          />
        </Grid>
        <Grid container gap={2} alignItems="center">
          <Box sx={{ width: 30, height: 10, backgroundColor: "#d64141" }}></Box>
          <div>Port name</div>
        </Grid>
        <Grid container gap={2} alignItems="center">
          <Box sx={{ width: 30, height: 10, backgroundColor: "#52f4d2" }}></Box>
          <div>River name</div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Legend;
