import { LayersControl, LayerGroup, Marker } from "react-leaflet";
import L from "leaflet";

const terminals = [
  {
    name: "Aare",
    coordinates: [46.684545398825975, 8.241965504988315],
  },
  {
    name: "Aare",
    coordinates: [46.800700906949174, 7.5705892643767925],
  },
  {
    name: "Aare",
    coordinates: [47.49018158925841, 8.233599079117567],
  },
  {
    name: "Front Rhine",
    coordinates: [46.7497644269186, 9.03819450871407],
  },
  {
    name: "Back Rhine",
    coordinates: [46.575042933481086, 9.417415195324537],
  },
  {
    name: "Rhine",
    coordinates: [47.05591746848259, 9.447792484846616],
  },
  {
    name: "ILL",
    coordinates: [47.11642380815189, 9.840468974799478],
  },
  {
    name: "Bregenzer Ache",
    coordinates: [47.360986373771595, 9.905506058703565],
  },
  {
    name: "Rhine",
    coordinates: [48.7588068980657, 7.965750869517677],
  },
  {
    name: "Main",
    coordinates: [50.00683914747617, 9.046501793915894],
  },
  {
    name: "Mosel",
    coordinates: [50.03456747340908, 7.109302373946321],
  },
  {
    name: "Rhine",
    coordinates: [51.88373796911006, 5.6944560442740055],
  },
];

const Markers = () => {
  return (
    <LayersControl.Overlay checked name="Show river names">
      <LayerGroup>
        {terminals.map((ter) => (
          <Marker
            position={ter.coordinates}
            icon={L.divIcon({
              className: "custom-marker",
              html: `<div class="custom-div-icon">${ter.name}</div>`,
            })}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default Markers;
