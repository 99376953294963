import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  CircularProgress,
  Box,
  Modal,
  Alert,
  IconButton,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useGet } from "../../../request";

export function formattedDate(d = new Date(), includeTime = false) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  let hours = String(d.getHours());
  let minutes = String(d.getMinutes());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;

  return `${day}/${month}/${year}${includeTime ? ` ${hours}:${minutes}` : ""}`;
}

export function convertDate(dateString) {
    const dateParts = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }

const IndexChart = ({ row, onClose, filteredData, setFilteredData, endpoint, requestArgs, children }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");

  const filterCategoriesArray = useMemo(() => [
    {"All": 999999999999},
    {"1 Year": 365},
    {"6 Months": 6 * 30},
    {"3 Months": 3 * 30},
    {"1 Month": 30},
    {"1 Week": 7},
    {"1 Day": 1},
  ], []);
  const filterCategories = useMemo(() => filterCategoriesArray.reduce((accumulator, currentValue) => {
    return {...accumulator, ...currentValue};
    }, {}), [filterCategoriesArray]);

    const applyFilter = useCallback((filter) => {
        if(data && data.length > 0) {
            const filteredData = data.filter((change) => {
              const date = change.date ? new Date(change.date) : new Date(change.id);
              const currentDate = new Date();
              const diffTime = Math.abs(currentDate - date);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              return diffDays <= filterCategories[filter];
            });
            return filteredData;
          }
          return [];
    }, [data, filterCategories]);

  useEffect(() => {
    setFilteredData(applyFilter(selectedFilter));
    }, [applyFilter, setFilteredData, selectedFilter]);

  const displayedFilters = useMemo(() => {
    return filterCategoriesArray
        .map((filter) => Object.keys(filter)[0])
        .map((filter) => [filter, applyFilter(filter)])
        .filter((filter) => filter[1].length >= 2)
        .filter((filter, i, arr) => i === 0 || arr[i - 1][1].length !== filter[1].length)
        .map((filter) => filter[0]);
  }, [applyFilter, filterCategoriesArray]);

  const get = useGet();

  const fetchData = async () => {
    if (row) {
      setLoading(true);
      try {
        let result = await get(endpoint, requestArgs);
        if (!result || result.error || result.length === undefined) {
          setError("Something went wrong!");
          return;
        }
        setData(result);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [row]);

  return (
    <Modal
      open={!!row}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxWidth: "90%",
          maxHeight: "90vh",
          overflow: "auto",
          position: "relative",
          borderRadius: 2,
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0, margin: 1 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!error && !loading && !row && filteredData.length < 2 && (
          <Alert severity="info">No data available</Alert>
        )}
        {!error && !loading && row && filteredData.length >= 2 && (
           <>
           {children}
           {displayedFilters.length > 1 && (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: 2}}>
                     {displayedFilters.map((filter) => (
                          <Box key={filter} sx={{mx: 1}}>
                            <Button variant={filter === selectedFilter ? "contained" : "outlined"} onClick={() => setSelectedFilter(filter)}>{filter}</Button>
                          </Box>
                     ))}
                </Box>
              )}

           </>
        )}
      </Box>
    </Modal>
  );
};

IndexChart.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    load: PropTypes.string.isRequired,
    discharge: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default IndexChart;
