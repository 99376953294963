import { useState, memo } from "react";
import { Paper, Grid, Tabs, Tab, Box, Fade } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "./common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import SnowChart from "./index/charts/SnowChart";

const areas = ["Snow height 1500m +", "Snow height 1000-1500m"];

const graphs = {
  "Snow height 1500m +": [
    {
      id: "Kronberg -> 1645",
      dbId: "Kronberg",
      name: "1645m",
    },
    {
      id: "Santis -> 2501m",
      dbId: "Santis",
      name: "2501m",
    },
    {
      id: "Weissfluhjoch -> 2691m",
      dbId: "Weissfluhjoch",
      name: "2691m",
    },
    {
      id: "Dischma -> 1710m",
      dbId: "Dischma",
      name: "1710m",
    },
  ],
  "Snow height 1000-1500m": [
    {
      id: "Urnerboden -> 1395m",
      dbId: "Urnerboden",
      name: "1395m",
    },
    {
      id: "Hoch-Ybrig -> 1452m",
      dbId: "Hoch-Ybrig",
      name: "1452m",
    },
    {
      id: "Vella -> 1245m",
      dbId: "Vella",
      name: "1245m",
    },
    {
      id: "Splügen -> 1460m",
      dbId: "Splügen",
      name: "1460m",
    },
    {
      id: "Ulrichen -> 1346m",
      dbId: "Ulrichen",
      name: "1346m",
    },
    {
      id: "Sedrun -> 1431m",
      dbId: "Sedrun",
      name: "1431m",
    },
    {
      id: "Stiegelschwand -> 1456m",
      dbId: "Stiegelschwand",
      name: "1456m",
    },
    {
      id: "Unterschächen -> 1470m",
      dbId: "Unterschächen",
      name: "1470m",
    },
  ],
};

function SnowLevels() {
  const [selectedArea, setSelectedArea] = useState(areas[0]);
  const { t } = useTranslation();

  const handleChange = (_, newValue) => {
    setSelectedArea(newValue);
  };

  return (
    <SpotbargeLayout title={t("menu_snow_leveles")}>
      <TabContext value={selectedArea}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={selectedArea} onChange={handleChange}>
              {areas.map((area, index) => (
                <Tab key={index} label={area} value={area} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid container spacing={2}>
          {areas.map((area) =>
            graphs[area].map((graph, index) => (
              <Fade in={selectedArea === area}>
                <Grid
                  item
                  sm={12}
                  md={6}
                  width="100%"
                  hidden={selectedArea !== area}
                >
                  <Paper
                    sx={{
                      p: 2,
                      minHeight: "400px",
                      maxHeight: "400px",
                      display: "grid",
                      alignItems: "end",
                      alignContent: "space-between",
                    }}
                    elevation={1}
                  >
                    {
                      <SnowChart
                        row={graph}
                        customDomain={graph.customDomain}
                        altitude={graph.altitude}
                      />
                    }
                  </Paper>
                </Grid>
              </Fade>
            )),
          )}
        </Grid>
      </TabContext>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(SnowLevels, {
  fallback: <GeneralErrorPage />,
});
