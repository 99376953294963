import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useGet } from "../../request";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import { VerticalBarChart } from "../common/charts/VerticalBarChart";

const GraphDealSizes = ({ area }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(30);
  const [error, setError] = useState(null);
  const [graphDealSizesData, setGraphDealSizesData] = useState([]);
  const get = useGet();

  const filterCategoriesArray = useMemo(
    () => [
      { All: 10000 },
      { "6 Months": 180 },
      { "1 Month": 30 },
      { "1 Week": 7 },
      { Today: 1 },
    ],
    [],
  );

  const categories = {
    "CPP Rhine": [
      [0, 1000],
      [1000, 2000],
      [2000, 20000],
    ],
    "CPP ARA > 1500 ton": [
      [1500, 3000],
      [3000, 5000],
      [5000, 7000],
      [7000, 20000],
    ],
  };

  const categoryMap = (min, max) => {
    if (min === 0 && max === 1000) {
      return "0-1000t";
    } else if (min === 2000 && max === 20000) {
      return "2000t + ";
    } else if (min === 7000 && max === 20000) {
      return "7000t + ";
    } else {
      return `${min}-${max}t`;
    }
  };

  const fetchGraphDealSizesData = async () => {
    setLoading(true);
    try {
      let result = await get("graph-deal-sizes");
      if (!result || !result.length) {
        setError("Something went wrong!");
        return;
      }
      if (result.error) {
        setError(result.error);
        return;
      }
      setGraphDealSizesData(result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraphDealSizesData();
  }, []);

  const data = categories[area].map((category) => {
    const itemsInCategory = graphDealSizesData.filter(
      (item) =>
        item.tonsMin === category[0] &&
        item.tonsMax === category[1] &&
        item.area === area &&
        item.days === filter,
    );
    const totalNrQuotes = itemsInCategory.reduce(
      (sum, item) => sum + item.nrQuotes,
      0,
    );
    return {
      name: categoryMap(category[0], category[1]),
      Deals: totalNrQuotes,
    };
  });

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {t("widget_deal_sizes")}
      </Typography>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!error && !loading && data && (
        <>
          <VerticalBarChart data={data} dataKey={"Deals"} />
          <Grid container gap={0} justifyContent="center">
            {filterCategoriesArray.map((item, index) => (
              <Button
                key={index}
                sx={{
                  height: "30px",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === filterCategoriesArray.length - 1
                      ? "0 5px 5px 0"
                      : 0,
                }}
                variant={
                  filter === item[Object.keys(item)[0]]
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setFilter(item[Object.keys(item)[0]])}
              >
                {Object.keys(item)[0]}
              </Button>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default withErrorBoundary(React.memo(GraphDealSizes), {
  fallback: <GeneralErrorPage />,
});
