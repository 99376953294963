import { useState } from "react";
import { Paper, Grid, Tabs, Tab, Box, Fade } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Map } from "@mui/icons-material";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "./common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import WaterLevelChart from "./index/charts/WaterLevelChart";
import WaterLevelSimpleChart from "./index/charts/WaterLevelSimpleChart";
import WaterLevelMap from "./waterlevelMap/WaterLevelMap";

const areas = ["Germany", "Switzerland", "Austria"];

const graphs = {
  Germany: [
    {
      id: "RhineARADuisburg",
      load: "ARA",
      discharge: "Duisburg",
      extra: " / Essen / Gelsenkirchen",
      riverOverride: "duisburg-ruhrort",
      marker: {
        name: "Duisburg",
        coordinates: [51.45155267646163, 6.7182355102900715],
      },
    },
    {
      id: "RhineARACologne",
      load: "ARA",
      discharge: "Cologne",
      riverOverride: "Cologne",
      marker: {
        name: "Cologne",
        coordinates: [50.92168994482034, 6.973376619064453],
      },
    },
    {
      id: "RhineARAFrankfurt",
      load: "ARA",
      discharge: "Frankfurt",
      extra: " / Floersheim / Kelsterbach",
      marker: {
        name: "Kaub",
        coordinates: [50.007204095542676, 7.857984124033663],
      },
    },
    {
      id: "RhineARABasel",
      load: "ARA",
      discharge: "Mannheim",
      extra: " / Karlsruhe / Strasbourg / Basel",
    },
    {
      id: "RhineFrankfurtBasel",
      load: "ARA",
      discharge: "Karlsruhe",
      extra: " / Strasbourg / Basel",
      fullSize: true,
      marker: {
        name: "Maxau",
        coordinates: [49.18853828070925, 8.383765346743672],
      },
    },
  ],
  Switzerland: [
    {
      name: "Lake Bodensee",
      isSimple: true,
      id: "aa9179c1-17ef-4c61-a48a-74193fa7bfdf",
      customDomain: [340, 550],
      marker: {
        name: "Bodensee",
        coordinates: [47.60048797397391, 9.341532770680772],
      },
    },
    {
      name: "Aare Switzerland, Murgenthal",
      isSimple: true,
      id: "2063",
      altitude: 399,
      customDomain: [39700, 40200],
      marker: {
        name: "Murgenthal",
        coordinates: [47.34053765551624, 7.908539268334238],
      },
    },
    {
      name: "Rhine Domat/Ems",
      isSimple: true,
      id: "2602",
      customDomain: [56000, 57000],
      altitude: 575,
      marker: {
        name: "Domat/Ems",
        coordinates: [46.838196200356606, 9.450775206214132],
      },
    },
    {
      name: "Neuhausen, Rhine",
      isSimple: true,
      id: "2288",
      customDomain: [38100, 38600],
      altitude: 380,
    },
  ],
  Austria: [
    {
      name: "Rhine Austria, Bangs",
      isSimple: true,
      id: "V334714",
      customDomain: [600, 900],
      marker: {
        name: "Bangs",
        coordinates: [47.27008796342281, 9.530228305456433],
      },
    },
    {
      name: "Rhine Austria, Lustenau (Eisenbahnbrücke)",
      isSimple: true,
      id: "V334711",
      customDomain: [500, 1100],
      marker: {
        name: "Lustenau",
        coordinates: [47.46679509822525, 9.668915246065751],
      },
    },
    {
      name: "Gisingen, III",
      isSimple: true,
      id: "V334564",
      customDomain: [100, 600],
      marker: {
        name: "Gisingen",
        coordinates: [47.2453534683537, 9.587283192370023],
      },
    },
    {
      name: "Bozenau, Bregenzerach",
      isSimple: true,
      id: "V334435",
      customDomain: [0, 300],
      marker: {
        name: "Bozenau",
        coordinates: [47.482042881269166, 9.844325428945893],
      },
    },
  ],
};

function Waterlevels() {
  const [selectedArea, setSelectedArea] = useState("map");
  const { t } = useTranslation();

  const handleChange = (_, newValue) => {
    setSelectedArea(newValue);
  };

  return (
    <SpotbargeLayout title={t("waterlevels_header")}>
      <TabContext value={selectedArea}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={selectedArea} onChange={handleChange}>
              <Tab key={3} label={<Map />} value="map" />

              {areas.map((area, index) => (
                <Tab key={index} label={area} value={area} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid container spacing={2} pb={3}>
          {areas.map((area, index) =>
            graphs[area].map((graph, index) => (
              <Fade in={selectedArea === area}>
                <Grid
                  item
                  sm={12}
                  md={graph.fullSize ? 12 : 6}
                  width="100%"
                  hidden={selectedArea !== area}
                >
                  <Paper
                    sx={{
                      p: 2,
                      minHeight: "400px",
                      maxHeight: "400px",
                      display: "grid",
                      alignItems: "end",
                      alignContent: "space-between",
                    }}
                    elevation={1}
                  >
                    {!graph.isSimple ? (
                      <WaterLevelChart row={graph} />
                    ) : (
                      <WaterLevelSimpleChart row={graph} />
                    )}
                  </Paper>
                </Grid>
              </Fade>
            )),
          )}
          <Grid hidden={selectedArea !== "map"} justifyContent="center">
            <WaterLevelMap
              graphs={[
                ...graphs.Austria,
                ...graphs.Germany,
                ...graphs.Switzerland,
              ]}
            />
          </Grid>
        </Grid>
      </TabContext>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(Waterlevels, {
  fallback: <GeneralErrorPage />,
});
