import { useState, useContext } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  FormControlLabel,
  Grid,
  Zoom,
  Fab,
} from "@mui/material";
import {
  ViewStream,
  ViewDay,
  PictureAsPdf,
  Description,
} from "@mui/icons-material";
import FileDownload from "@mui/icons-material/FileDownload";
import { useExport } from "../../request";
import { useGet } from "../../request";
import { UserContext } from "../AuthGuard";

const StyledFab = styled(Fab)({
  position: "fixed",
  right: 30,
  zIndex: 9999999,
  width: 65,
  height: 65,
  bottom: 20,
  fontSize: 25,
});

const SecondaryButton = styled(Button)({
  position: "fixed",
  zIndex: 9999999,
  borderRadius: 50,
  right: 30,
});

const DownloadIndex = ({ index, totals, tableGroups }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("v2");
  const [news, setNews] = useState("");
  const [showSecondaryButtons, setShowSecondaryButtons] = useState(false);
  const [user] = useContext(UserContext);
  const pdfGet = useExport();
  const get = useGet();

  const handleMainFabClick = () => {
    setShowSecondaryButtons(!showSecondaryButtons);
  };

  const handleDownloadClick = async () => {
    setShowSecondaryButtons(false);
    setLoading(true);
    setIsModalOpen(false);
    setNews("");
    let result = await pdfGet(
      "generateIndexPdf",
      {
        index,
        areas: index.areas,
        totals,
        news,
        layout: selectedValue,
        tableGroups,
      },
      "blob",
    );
    const blob = new Blob([result], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toISOString().split("T")[0];
    link.download = `Spotbarge index ${currentDate}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const handloeExcelDownload = async () => {
    setShowSecondaryButtons(false);
    setLoading(true);
    setIsModalOpen(false);
    setNews("");
    try {
      let result = await get(
        "index-xls",
        {
          index,
          areas: index.areas,
          totals,
          news,
          layout: selectedValue,
          tableGroups,
        },
        "blob",
      );
      if (result) {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "index.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error("Failed to generate Excel file");
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setNews("");
  };
  const handleOpen = () => setIsModalOpen(true);
  const handleChange = (e) => setNews(e.target.value);
  const handleLayoutChange = (event) => setSelectedValue(event.target.value);

  return (
    <div>
      <StyledFab
        color="primary"
        style={{
          bottom: 20,
        }}
        onClick={handleMainFabClick}
      >
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <FileDownload fontSize="inherit" />
        )}
      </StyledFab>
      <Zoom in={showSecondaryButtons} timeout={50}>
        <StyledFab
          variant="contained"
          color="secondary"
          style={{ bottom: 90 }}
          onClick={handloeExcelDownload}
        >
          <Description />
        </StyledFab>
      </Zoom>
      {user && user.role === "admin" && (
        <Zoom in={showSecondaryButtons}>
          <StyledFab
            variant="contained"
            color="secondary"
            style={{ bottom: 160 }}
            onClick={handleOpen}
          >
            <PictureAsPdf />
          </StyledFab>
        </Zoom>
      )}
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Add Market Commentary</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            fullWidth
            minRows={3}
            value={news}
            onChange={handleChange}
          />
          <Grid
            sx={{
              display: "grid",
              justifyContent: "end",
              alignItems: "center",
              gridTemplateColumns: "max-content max-content",
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "v1"}
                  onChange={handleLayoutChange}
                  value="v1"
                  name="radio-buttons"
                />
              }
              label={
                <Grid
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    gridTemplateColumns: "max-content max-content",
                  }}
                >
                  <ViewStream />
                  <div>Flow</div>
                </Grid>
              }
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "v2"}
                  onChange={handleLayoutChange}
                  value="v2"
                  name="radio-buttons"
                />
              }
              label={
                <Grid
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    gridTemplateColumns: "max-content max-content",
                  }}
                >
                  <ViewDay />
                  <div>Grouped</div>
                </Grid>
              }
              labelPlacement="start"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            color="success"
            onClick={handleDownloadClick}
            disabled={!news}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DownloadIndex;
