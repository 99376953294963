import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Checkbox,
  ClickAwayListener,
  Menu,
  MenuItem,
  Tooltip,
  Fade,
  Grid,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatNumber } from "../../utils";
import PropTypes from "prop-types";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";

export const Status = styled("div")(({ theme, status }) => ({
  display: "inline-block",
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: "0.83rem",
  paddingTop: "0.20rem",
  paddingBottom: "0.20rem",
  fontWeight: "bold",
  color:
    status === "Confirmed" || (typeof status === "number" && status > 0)
      ? theme.palette.success.main
      : status === "Pending" || status === "Outlier"
        ? theme.palette.warning.main
        : status === 0
          ? theme.palette.info.main
          : theme.palette.error.main,
  border: `1px solid ${status === "Confirmed" || (typeof status === "number" && status > 0)
    ? theme.palette.success.main
    : status === "Pending" || status === "Outlier"
      ? theme.palette.warning.main
      : status === 0
        ? theme.palette.info.main
        : theme.palette.error.main
    }`,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "rgb(0,21,86)",
  "& .MuiTableCell-root": {
    color: "white",
    backgroundColor: "rgb(0,21,86)",
  },
  "& .MuiTableCell-root:first-of-type": {
    borderTopLeftRadius: theme.spacing(1),
  },
  "& .MuiTableCell-root:last-child": {
    borderTopRightRadius: theme.spacing(1),
  },
  "& .MuiTableSortLabel-root.MuiTableSortLabel-hover": {
    color: "white",
  },
  "& .MuiTableSortLabel-root.Mui-active,& .MuiTableSortLabel-root.Mui-focus": {
    color: "white",
    fontWeight: "bold",
    "& .MuiTableSortLabel-icon": {
      color: "white",
    },
  },
  "& .MuiTableSortLabel-root:hover": {
    color: "white",
    "& .MuiTableSortLabel-icon": {
      color: "white",
    },
  },
}));

function SpotbargeTable({
  data,
  columns,
  tooltipColumns = [],
  columnRenderMap,
  paginate = false,
  filterKey = "default",
  noDataMessage = "No Data Found",
  propagateFilterAndData: propagateColumsAndData = null,
  initialSorting = null,
  minH = null,
  paginateCallback = null,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(initialSorting);
  const [page, setPage] = useState(0);
  const [hasAll, setHasAll] = useState(data.length !== 250);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const filterRef = useRef(null);
  const currentFilterKey = useRef("");
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    if (propagateColumsAndData) propagateColumsAndData(paginatedData, columns);
    if (currentFilterKey.current !== filterKey) {
      const savedFilterValues = localStorage.getItem(`filter_${filterKey}`);
      if (savedFilterValues) {
        setFilterValues(JSON.parse(savedFilterValues));
      } else {
        setFilterValues({});
      }
      currentFilterKey.current = filterKey;
    } else {
      localStorage.setItem(`filter_${filterKey}`, JSON.stringify(filterValues));
    }
  }, [filterKey, filterValues]);

  const convertTimestamp = (dateString) => {
    if (!dateString) return 0;
    const [day, month, year, hours, minutes] = dateString.split(/[/ :]/);
    return new Date(year, month - 1, day, hours, minutes);
  };

  const convertDate = (dateString) => {
    if (!dateString) return 0;
    const [day, month, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };

  const compare = useCallback((a, b, orderBy, order) => {
    if (orderBy === "timestamp") {
      a = convertTimestamp(a[orderBy]);
      b = convertTimestamp(b[orderBy]);
    } else if (orderBy === "loadDate") {
      a = convertDate(a[orderBy]);
      b = convertDate(b[orderBy]);
    } else {
      a = a[orderBy];
      b = b[orderBy];
    }

    if (b < a) {
      return order === "asc" ? -1 : 1;
    }
    if (b > a) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  }, []);

  const sortedData = useMemo(
    () =>
      orderBy ? [...data].sort((a, b) => compare(a, b, orderBy, order)) : data,
    [data, order, orderBy, compare],
  );
  const filteredData = useMemo(
    () =>
      sortedData.filter((row) =>
        Object.entries(filterValues).every(
          ([columnId, filterValueMap]) =>
            filterValueMap[row[columnId]] ||
            Object.keys(filterValueMap).length === 0,
        ),
      ),
    [sortedData, filterValues],
  );
  const paginatedData = useMemo(
    () =>
      filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredData, page, rowsPerPage],
  );

  useEffect(() => setPage(0), [rowsPerPage, filterValues]);
  const handleChangePage = (_event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnId);
      setOrder("asc");
    }
  };

  const handleFilterClick = (event, column) => {
    setFilterColumn(column);
    setFilterAnchorEl(event.currentTarget);
    setFilterOptions(
      Array.from(
        new Set(
          sortedData
            .filter(
              (row) =>
                (column.id in filterValues &&
                  Object.keys(filterValues[column.id]).some(
                    (k) => k === row[column.id],
                  )) ||
                Object.entries(filterValues)
                  .filter(([columnId]) => columnId !== column.id)
                  .every(
                    ([columnId, filterValueMap]) =>
                      filterValueMap[row[columnId]] ||
                      Object.keys(filterValueMap).length === 0,
                  ),
            )
            .map((row) => row[column.id]),
        ),
      ),
    );
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    const { value, checked } = event.target;
    if (!checked) {
      if (
        Object.values(filterValues[filterColumn.id]).every((k) => k === value)
      ) {
        handleFilterReset();
      }
      setFilterValues((prevValues) => {
        const newValues = { ...prevValues };
        delete newValues[filterColumn.id][value];
        if (Object.values(newValues[filterColumn.id]).length === 0) {
          delete newValues[filterColumn.id];
        }
        return newValues;
      });
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [filterColumn.id]: {
          ...prevValues[filterColumn.id],
          [value]: checked,
        },
      }));
    }
  };

  const handleFilterApply = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterReset = () => {
    setFilterAnchorEl(null);
  };

  const fullFilterReset = () => {
    setFilterValues({});
    setFilterAnchorEl(null);
  };

  useEffect(() => {
    setPage(0);
  }, [data]);

  const getAllData = async () => {
    setLoading(true);
    await paginateCallback();
    setHasAll(true);
    setLoading(false);
  };

  const renderFetchAll = () => {
    if (paginateCallback && !hasAll) {
      return (
        <Grid
          display="grid"
          sx={{
            gridTemplateColumns: "max-content max-content",
            justifyContent: "end",
            alignItems: "center",
            pr: 1,
          }}
        >
          <div>Result is capped to 250: </div>
          <Button color="secondary" onClick={getAllData}>
            Display all data
          </Button>
        </Grid>
      );
    }
  };

  const renderPagination = () => {
    if (paginate)
      return (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );

    return <></>;
  };

  const renderTableBody = () => {
    if (data?.length === 0)
      return (
        <TableBody>
          <TableRow>
            <TableCell
              sx={{ padding: 2, width: "100%", textAlign: "center" }}
              colSpan={100}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    width: "100%",
                    top: 0,
                    bottom: 0,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                noDataMessage
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      );

    const TooltipColumns = ({ tooltipColumns, row }) => {
      return (
        <div
          style={{
            padding: "10px",
          }}
        >
          {tooltipColumns.map((column) => (
            <div key={column.id}>
              <strong>{column.label}: </strong>
              {columnRenderMap[column.id](row)}
            </div>
          ))}
        </div>
      );
    };

    return (
      <TableBody>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              width: "100%",
              top: 0,
              bottom: 0,
            }}
          >
            <CircularProgress />
          </div>
        )}
        {paginatedData.map((row, i) => (
          <Tooltip
            title={
              !tooltipColumns.length ? (
                ""
              ) : (
                <TooltipColumns tooltipColumns={tooltipColumns} row={row} />
              )
            }
            sx={{ width: "100%" }}
            key={i}
          >
            <TableRow
              sx={{ backgroundColor: i % 2 === 0 ? "white" : "#F1F4F9" }}
            >
              {columns.map((column) => {
                if (column.id in columnRenderMap) {
                  return (
                    <TableCell key={column.id}>
                      {columnRenderMap[column.id](row)}
                    </TableCell>
                  );
                } else if (column.id === "status") {
                  return (
                    <TableCell key={column.id}>
                      <Status status={row[column.id]}>{row[column.id]}</Status>
                    </TableCell>
                  );
                } else if (column.id === "change") {
                  return (
                    <TableCell key={column.id}>
                      <Status status={row[column.id]}>
                        {(row[column.id] > 0 ? "+" : "") +
                          formatNumber(row[column.id])}
                      </Status>
                    </TableCell>
                  );
                } else if (column.label.includes("€")) {
                  return (
                    <TableCell key={column.id}>
                      {formatNumber(row[column.id])}
                    </TableCell>
                  );
                }

                return <TableCell key={column.id}>{row[column.id]}</TableCell>;
              })}
            </TableRow>
          </Tooltip>
        ))}
        {Object.values(filterValues).some((filterValueMap) =>
          Object.values(filterValueMap).some((value) => value === true),
        ) && (
            <TableRow>
              <TableCell sx={{ padding: 2, width: "100%" }} colSpan={100}>
                This view has been filtered.{" "}
                <Button color="secondary" onClick={fullFilterReset}>
                  Clear filters
                </Button>
              </TableCell>
            </TableRow>
          )}
      </TableBody>
    );
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          overflowX: "auto",
          maxHeight: "80vh",
          minHeight: minH ? minH : 0,
        }}
        elevation={2}
      >
        <Fade in={true}>
          <Table stickyHeader size="small">
            <StyledTableHead>
              <TableRow>
                {columns.map((column, columnId) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    style={{
                      minWidth: column.width || "100%",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                    {column.filter === "checkboxes" && (
                      <IconButton
                        size="small"
                        onClick={(event) => handleFilterClick(event, column)}
                        color="inherit"
                      >
                        {filterValues[column.id] &&
                          Object.keys(filterValues[column.id]).length > 0 ? (
                          <FilterAlt />
                        ) : (
                          <FilterAltOutlined />
                        )}
                      </IconButton>
                    )}
                    <Menu
                      anchorEl={filterAnchorEl}
                      open={Boolean(filterAnchorEl) && filterColumn === column}
                      onClose={handleFilterClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      getContentAnchorEl={null}
                    >
                      <ClickAwayListener onClickAway={handleFilterClose}>
                        <div ref={filterRef}>
                          <MenuItem onClick={handleFilterReset}>
                            <Checkbox
                              checked={
                                !filterValues[filterColumn.id] ||
                                Object.keys(filterValues[filterColumn.id])
                                  .length === 0
                              }
                            />
                            All
                          </MenuItem>
                          {filterOptions.map((value, itemId) => (
                            <MenuItem
                              key={value}
                              onClick={(e) => {
                                const checkbox = document.getElementById(
                                  `checkbox-${columnId}-${itemId}`,
                                );
                                if (e.target !== checkbox) {
                                  checkbox.click();
                                }
                              }}
                            >
                              <Checkbox
                                checked={
                                  filterValues[filterColumn.id] &&
                                  filterValues[filterColumn.id][value]
                                }
                                onChange={handleFilterChange}
                                value={value}
                                id={`checkbox-${columnId}-${itemId}`}
                              />
                              {value}
                            </MenuItem>
                          ))}
                          <MenuItem onClick={handleFilterApply}>
                            <IconButton size="small">Apply</IconButton>
                          </MenuItem>
                        </div>
                      </ClickAwayListener>
                    </Menu>
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            {renderTableBody()}
          </Table>
        </Fade>
      </TableContainer>
      {renderPagination()}
      {renderFetchAll()}
    </>
  );
}

SpotbargeTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnRenderMap: PropTypes.object.isRequired,
  paginate: PropTypes.bool,
  filterKey: PropTypes.string,
  noDataMessage: PropTypes.string,
};

export default SpotbargeTable;
