import React, { useEffect, createContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import { CircularProgress, Stack } from "@mui/material";
import SignInScreen from "./SignInScreen";
import { useGet } from "../request";

const UserContext = createContext();

function gtag() {
  window.dataLayer.push(arguments);
}

const usePageTracking = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const GA_TRACKING_ID = process.env.REACT_APP_MEASUREMENT_ID;

      // Load the Google Analytics script
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      script.async = true;
      document.body.appendChild(script);

      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      gtag("js", new Date());
      gtag("config", GA_TRACKING_ID, {
        page_path: window.location.pathname,
        // Deny ad_storage and analytics_storage by default
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    }
  }, []);
};

const AuthGuard = ({ children }) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(undefined);

  const [userLoading, setUserLoading] = useState(true);
  const get = useGet();

  usePageTracking();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const resetUserState = () => {
    setUser({});
    setUserLoading(false);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUserLoading(true);
        try {
          const response = await get("user");

          if (!response.error) {
            setUser({
              ...response,
              email: authUser ? authUser.email : response.email,
              name: authUser ? authUser.displayName : response.name,
              phone: authUser && authUser.phoneNumber ? authUser.phoneNumber : response.phone,
            });
            if (
              response.language &&
              localStorage.getItem("language") !== response.language
            ) {
              localStorage.setItem("language", response.language);
              i18n.changeLanguage(response.language);
            }
          }
        } catch (error) {
          resetUserState();
        } finally {
          setUserLoading(false);
        }
      } else {
        resetUserState();
      }
    });

    return unsubscribe;
  }, [i18n]);

  const renderProviderChildren = () => {
    if (userLoading) {
      return (
        <Stack
          height={"100vh"}
          width={"100vw"}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      );
    }

    if (user) {
      return (
        <UserContext.Provider value={[user, setUser]}>
          {children}
        </UserContext.Provider>
      );
    }

    return (
      <UserContext.Provider value={[user, setUser]}>
        <SignInScreen />{" "}
      </UserContext.Provider>
    );
  };

  return renderProviderChildren();
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;

export { UserContext };
