import { LayersControl, LayerGroup, Marker } from "react-leaflet";
import L from "leaflet";
import mountainIcon from "../../assets/mountain.png";

const terminals = [
  {
    name: "Aare",
    coordinates: [46.4994, 9.297],
  },
  {
    name: "Aare",
    coordinates: [46.6354, 8.9087],
  },
  {
    name: "Aare",
    coordinates: [46.8567, 8.949],
  },
  {
    name: "Aare",
    coordinates: [46.9853, 9.2533],
  },
  {
    name: "Aare",
    coordinates: [46.7914, 9.6198],
  },
  {
    name: "Aare",
    coordinates: [46.7345, 9.805],
  },
  {
    name: "Aare",
    coordinates: [47.1481, 9.3718],
  },
  {
    name: "Aare",
    coordinates: [47.2608, 9.372],
  },
];

const Mountain = () => {
  return (
    <LayersControl.Overlay checked name="Show mountains">
      <LayerGroup>
        {terminals.map((ter) => (
          <Marker
            position={ter.coordinates}
            icon={L.divIcon({
              html: `<img class="custom-mountain-icon" src="${mountainIcon}"/ >`,
            })}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default Mountain;
