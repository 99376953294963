import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGet, usePost } from "../../request";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  ZoomControl,
  useMap,
  ScaleControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";
import Terminals from "./layers/Terminals";
import Bargins from "./layers/bargins/Bargins";
import "./style.css";
import MapSidebar from "./sidebar/MapSidebar";
import VesselAutocomplete from "./layers/VesselAutocomplete";
import Alerts from "./Alerts";
import AddToFleetDialog from "./dialogs/AddToFleetDialog";
import DeleteFleetDialog from "./dialogs/DeleteFleetDialog";
import FleetDialog from "./dialogs/FleetDialog";
import Counter from "./sidebar/Counter";

function SetCenter({ center, zoom }) {
  const map = useMap();
  useEffect(() => {
    if (center && zoom !== undefined) {
      map.setView(center, zoom);
    }
  }, [center, zoom, map]);
  return null;
}

const defaultFilters = {
  dwt: { type: "range", value: [0, 12000] },
  length: { type: "range", value: [0, 300] },
  breadth: { type: "range", value: [0, 300] },
  heating: { type: "bool", value: "all" },
  coated: { type: "bool", value: "all" },
  product: {
    type: "exact",
    value: {
      ALL: true,
      CPP: true,
      DPP: true,
      Vegoil: true,
      GAS: true,
    },
  },
};

const defaultFleet = {
  id: null,
  name: "",
  color: "#ff0000",
  vessels: [],
};

function Map() {
  const [vessels, setVessels] = useState([]);
  const [filteredVessels, setFilteredVessels] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState([51.01986821800572, 7.941116146878102]);
  const [zoom, setZoom] = useState(6.5);
  const [key, setKey] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [addToFleetModalOpen, setAddToFleetModalOpen] = useState(false);
  const [selectedVesselToAdd, setSelectedVesselToAdd] = useState(null);
  const [isFleetUpdate, setIsFleetUpdate] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fleetToDelete, setFleetToDelete] = useState(null);
  const [fleetToUpsert, setFleetToUpsert] = useState(defaultFleet);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [onlyFleet, setOnlyFleet] = useState(false);
  const [mapStyle, setMapStyle] = useState(
    localStorage.getItem("mapStyle") || "normal",
  );
  const mapRef = useRef(null);
  const { t } = useTranslation();
  const get = useGet();
  const post = usePost();

  const addFleet = async () => {
    try {
      const response = await post("add-fleet", fleetToUpsert);
      setFleet((prev) => [...prev, { ...fleetToUpsert, id: response.id }]);
      handleFleetClear();
      handleSnackbarOpen("Fleet added", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error adding fleet", "error");
    }
  };

  const updateFleet = async () => {
    try {
      await post("update-fleet", fleetToUpsert);
      setFleet((prev) =>
        prev.map((fleet) =>
          fleet.id === fleetToUpsert.id ? fleetToUpsert : fleet,
        ),
      );
      handleFleetClear();
      handleSnackbarOpen("Fleet updated", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error updating fleet", "error");
    }
  };

  const deleteFleet = async () => {
    try {
      await post("delete-fleet", { id: fleetToDelete.id });
      setFleet((prev) => prev.filter((fleet) => fleet.id !== fleetToDelete.id));
      handleFleetClear();
      handleSnackbarOpen("Fleet deleted", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error deleting fleet", "error");
    }
  };

  const addVesselToFleet = async (selectedFleet) => {
    try {
      await post("add-vessel-fleet", {
        id: selectedFleet.id,
        vessel: selectedVesselToAdd,
      });
      setFleet((prev) =>
        prev.map((fleet) =>
          fleet.id === selectedFleet.id
            ? { ...fleet, vessels: [...fleet.vessels, selectedVesselToAdd] }
            : fleet,
        ),
      );
      handleFleetClear();
      handleSnackbarOpen("Vessel added to fleet", "success");
    } catch (error) {
      handleFleetClear();
      handleSnackbarOpen("Error adding vessel to fleet", "error");
    }
  };

  const removeVesselFromFleet = async (selectedFleet, selectedVessel) => {
    try {
      await post("delete-vessel-fleet", {
        id: selectedFleet.id,
        vessel: selectedVessel,
      });
      setFleet((prev) =>
        prev.map((fleet) =>
          fleet.id === selectedFleet.id
            ? {
                ...fleet,
                vessels: fleet.vessels.filter(
                  (vessel) => vessel.mmsi !== selectedVessel.mmsi,
                ),
              }
            : fleet,
        ),
      );
      handleFleetClear();
      handleSnackbarOpen("Vessel removed from fleet", "success");
    } catch (error) {
      console.error("Error removing vessel from fleet:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleFleetClear = () => {
    setIsFleetUpdate(false);
    setFleetToUpsert(defaultFleet);
    setUpsertModalOpen(false);
    setDeleteDialogOpen(false);
    setAddToFleetModalOpen(false);
    handleSnackbarClose();
  };

  const handleDeleteFleet = (fleet) => {
    setFleetToDelete(fleet);
    setDeleteDialogOpen(true);
  };

  const handleUpsertModal = (selectedFleet) => {
    if (selectedFleet) {
      setFleetToUpsert(selectedFleet);
      setIsFleetUpdate(true);
    } else {
      setFleetToUpsert(defaultFleet);
      setIsFleetUpdate(false);
    }
    setUpsertModalOpen(true);
  };

  const handleAddToFleetModal = (vessel) => {
    setAddToFleetModalOpen(true);
    setSelectedVesselToAdd(vessel);
  };

  const fetchFleet = async () => {
    try {
      const response = await get("fleet");
      setFleet(response);
    } catch (error) {
      console.error("Error fetching fleet data:", error);
    }
  };

  const fetchVessels = async () => {
    try {
      setLoading(true);
      const response = await get("vessels");
      setVessels(response);
    } catch (error) {
      console.error("Error fetching vessel data:", error);
    }
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    fetchVessels();
    fetchFleet();
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchVessels();
    }, 500000);

    return () => clearInterval(intervalId);
  }, []);

  const fleetColor = useMemo(() => {
    return fleet.reduce((acc, cf) => {
      cf.vessels.forEach((cv) => {
        acc[cv.mmsi] = cf.color;
      });
      return acc;
    }, {});
  }, [fleet]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = vessels.filter((vessel) => {
      if (onlyFleet && !fleetColor[vessel["mmsi"]]) {
        return false;
      }
      return Object.keys(filters).every((key) => {
        const filter = filters[key];
        if (filter.type === "range") {
          if (!vessel[key]) return true;
          return (
            vessel[key] >= filter.value[0] && vessel[key] <= filter.value[1]
          );
        } else if (filter.type === "exact") {
          if (filter.value["ALL"]) return true;
          return filter.value[vessel[key]];
        } else if (
          filter.type === "bool" &&
          filter.value !== null &&
          filter.value !== "all"
        ) {
          return filter.value === vessel[key];
        }
        return true;
      });
    });
    setFilteredVessels(filtered);
  }, [filters, vessels, fleetColor, onlyFleet]);

  const clearFilters = () => {
    setFilters(defaultFilters);
    setOnlyFleet(false);
  };

  const handleDataRefresh = useCallback(() => {
    fetchVessels();
  }, [fetchVessels]);

  const handleClear = () => {
    setSearchValue("");
    setSelectedVessel(null);
    setCenter([51.41986821800572, 7.941116146878102]);
    setZoom(6.5);
  };

  const toggleMapStyle = (st) => {
    setMapStyle((prevStyle) => st);
    localStorage.setItem("mapStyle", st);
    setKey((prevKey) => prevKey + 1); // Update key to force re-render
  };

  const filterOptions = useCallback((options, { inputValue }) => {
    return options.filter((option) => {
      return (
        option.mmsi?.toString().includes(inputValue) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        (option.eni && option.eni.toString().includes(inputValue))
      );
    });
  }, []);

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      if (mapRef.current.requestFullscreen) {
        mapRef.current.requestFullscreen();
      } else if (mapRef.current.mozRequestFullScreen) {
        mapRef.current.mozRequestFullScreen();
      } else if (mapRef.current.webkitRequestFullscreen) {
        mapRef.current.webkitRequestFullscreen();
      } else if (mapRef.current.msRequestFullscreen) {
        mapRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange,
      );
    };
  }, []);

  return (
    <div ref={mapRef}>
      <Grid
        container
        sx={{ width: "100%", heigth: "100vh", position: "relative" }}
      >
        {/* <VesselAutocomplete
          filteredVessels={filteredVessels}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selectedVessel={selectedVessel}
          setSelectedVessel={setSelectedVessel}
          setCenter={setCenter}
          setZoom={setZoom}
          mapRef={mapRef}
          filterOptions={filterOptions}
        />
        <MapSidebar
          handleClear={handleClear}
          handleDataRefresh={handleDataRefresh}
          loading={loading}
          changeMapStyle={toggleMapStyle}
          mapStyle={mapStyle}
          filters={filters}
          setFilters={setFilters}
          clearFilters={clearFilters}
          defaultFilters={defaultFilters}
          handleFullscreenToggle={handleFullscreenToggle}
          mapRef={mapRef}
          fleet={fleet}
          handleUpsertModal={handleUpsertModal}
          handleDeleteFleet={handleDeleteFleet}
          removeVesselFromFleet={removeVesselFromFleet}
          showOnlyFleet={setOnlyFleet}
          onlyFleet={onlyFleet}
        /> */}
        <Counter vessels={filteredVessels} />
        <MapContainer
          key={key}
          center={[51.41986821800572, 7.941116146878102]}
          preferCanvas={true}
          zoom={zoom}
          style={{ width: "100%", height: "100vh" }}
          fadeAnimation={true}
          zoomControl={false}
          maxBounds={[
            [36.527295, -31.640625],
            [71.187754, 39.375],
          ]}
          className={`ship-${mapStyle}`}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="bottomright" />
          <LayersControl position="topleft">
            <Terminals />
            <Bargins
              vessels={filteredVessels}
              selectedVessel={selectedVessel}
              selectVessel={setSelectedVessel}
              handleAddToFleetModal={handleAddToFleetModal}
              fleetColor={fleetColor}
            />
          </LayersControl>
          <ScaleControl position="bottomleft" />
          <SetCenter center={center} zoom={zoom} />
        </MapContainer>
        <FleetDialog
          open={upsertModalOpen}
          onClose={handleFleetClear}
          isFleetUpdate={isFleetUpdate}
          fleetToUpsert={fleetToUpsert}
          setFleetToUpsert={setFleetToUpsert}
          addFleet={addFleet}
          updateFleet={updateFleet}
          mapRef={mapRef}
        />
        <DeleteFleetDialog
          open={deleteDialogOpen}
          onClose={handleFleetClear}
          fleetToDelete={fleetToDelete}
          deleteFleet={deleteFleet}
          mapRef={mapRef}
        />
        <AddToFleetDialog
          open={addToFleetModalOpen}
          onClose={handleFleetClear}
          fleet={fleet}
          addVesselToFleet={addVesselToFleet}
          mapRef={mapRef}
        />
        <Alerts
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
          mapRef={mapRef}
        />
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </div>
  );
}

export default withErrorBoundary(Map, {
  fallback: <GeneralErrorPage />,
});
