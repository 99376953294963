import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const GeneralErrorPage = () => {
  const { t: translation } = useTranslation();

  const navigateHome = () => {
    window.location.href = "/";
  };

  return (
    <Container>
      <Typography variant="h4">
        {translation("something_went_wrong")}
      </Typography>

      <Typography variant="body1">
        {translation("refresh_the_page_or_return_home")}
      </Typography>

      <Button
        sx={{
          mt: 2,
        }}
        variant="contained"
        color="primary"
        onClick={navigateHome}
      >
        {translation("go_home")}
      </Button>
    </Container>
  );
};

export default GeneralErrorPage;
