import * as React from "react";
import { Button, Box, CircularProgress, Alert, TextField, Paper } from "@mui/material";
import { useState } from "react";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import { usePost } from "../../request";

function AdminEmail() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");

  const post = usePost();

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      let result = await post(
        "admin-mail",
        {
          recipient: email,
          subject,
          header: header,
          body: body.replace(/\n/g, "<br />"),
        },
      );
      if (!result) {
        setError(t("adminuser_something_went_wrong"));
        return;
      }
      if (result.error) {
        setError(result.error);
        return;
      }
      if (result.success) {
        setEmail("");
        setSubject("");
        setHeader("");
        setBody("");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SpotbargeLayout title="Admin Send Email">
      <Paper sx={{ width: "100%", height: "100%", p: 10 }}>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <>
            <Box sx={{ p: 1 }}>
              <TextField
                label="Recipient"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                label="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                label="Header"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                label="Body"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                fullWidth
                multiline
                rows={10}
              />
            </Box>
            <Box sx={{ p: 1, textAlign: "center" }}>
              <Button variant="contained" onClick={handleSendEmail}>
                Send
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(AdminEmail, {
  fallback: <GeneralErrorPage />,
});
