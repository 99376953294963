import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart as RechartBarChart,
} from "recharts";

export function VerticalBarChart({ data, dataKey }) {
  const maxBarSize = 70;
  const minBarSize = 20;
  const categoriesCount = data.length;

  const barSize = Math.max(
    minBarSize,
    Math.min(maxBarSize, 120 / categoriesCount),
  );
  return (
    <ResponsiveContainer width="100%" height={250}>
      <RechartBarChart
        layout="vertical"
        width={500}
        height={250}
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 5" />
        <Tooltip cursor={{ fill: "rgb(0, 0, 0, 0.1)" }} />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" width={120} />
        <Tooltip />
        <Bar dataKey={dataKey} barSize={barSize} fill="#2980B9" />
      </RechartBarChart>
    </ResponsiveContainer>
  );
}
