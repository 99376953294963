import React, { useState, useEffect } from "react";
import { Paper, Grid, Alert, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import CityWeatherWidget from "./CityWeatherWidget";
import WeatherOverview from "./WeatherOverview";
import { useGet } from "../../request";

function Weather() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [weatherData, setWeatherData] = useState();
  const [selectedCity, setSelectedCity] = useState(null);

  const get = useGet();

  const cities = [
    "Duisburg",
    "Cologne",
    "Frankfurt",
    "Karlsruhe",
    "Strasbourg",
    "Basel",
  ];

  const handleSelectCity = (city) => {
    setSelectedCity(city);
  };

  const fetchData = async () => {
    setLoading(true);

    const weatherResponse = await get("city-weather", {
      cities,
    });

    if (weatherResponse && weatherResponse.error) {
      setError(weatherResponse.error);
      return;
    }

    setWeatherData(weatherResponse);
    setSelectedCity(weatherResponse[0]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SpotbargeLayout title={t("menu_weather")}>
      <Grid container flexDirection="column" gap={3} flexWrap="nowrap">
        <Paper elevation={2}>
          <Grid container display="flex" alignItems="center" minHeight="194px">
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "194px",
                  width: "100%",
                  alignContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!loading && error && <Alert severity="error">{error}</Alert>}
            {!loading &&
              !error &&
              weatherData &&
              weatherData.map((city, index) => (
                <CityWeatherWidget
                  key={index}
                  city={city}
                  selectedCity={selectedCity}
                  handleSelect={handleSelectCity}
                />
              ))}
          </Grid>
        </Paper>
        <Paper p={3} elevation={8}>
          <WeatherOverview city={selectedCity} />
        </Paper>
      </Grid>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(React.memo(Weather), {
  fallback: <GeneralErrorPage />,
});
