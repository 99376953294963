import React, { useState, useEffect, useMemo } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useGet } from "../../../request";
import LineChart from "../../common/charts/LineChart";
import { formattedDate } from "./RouteHistoryChart";

const SnowChart = ({ row, customDomain = null }) => {
  const [loading, setLoading] = useState(false);
  const [snowData, setSnowData] = useState([]);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState(365);

  const get = useGet();

  const filterCategoriesArray = useMemo(
    () => [
      { "5 Years": 1825 },
      { "2 Years": 730 },
      { "1 Year": 365 },
      { "1 Month": 30 },
    ],
    [],
  );

  const fetchData = async () => {
    if (!row) return;
    setLoading(true);
    try {
      const snowLevelsResponse = await get("snow-level", {
        stationId: row.dbId,
      });
      if (snowLevelsResponse && snowLevelsResponse.error) {
        setError(snowLevelsResponse.error);
        return;
      }

      const data = snowLevelsResponse.map((item) => ({
        x: new Date(item.timestamp).getTime(),
        timestamp: item.timestamp,
        "Snow level": item.value,
      }));
      setSnowData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [row]);

  const tickFormatter = (date) => {
    if (filter > 365) return formattedDate(new Date(date), false);
    return formattedDate(new Date(date), false, filter !== 1, filter === 1);
  };

  const filteredSnowData = useMemo(() => {
    if (!snowData || snowData.length === 0) return [];

    const filteredByDate = snowData.filter(
      (change) =>
        new Date(change.timestamp.split("T")[0]) >=
        new Date(new Date().getTime() - filter * 24 * 60 * 60 * 1000),
    );

    const totalDataPoints = filteredByDate.length;
    const desiredMaxPoints = 160;
    const samplingInterval = Math.ceil(totalDataPoints / desiredMaxPoints);

    const sampledData = filteredByDate.filter(
      (_, index) => index % samplingInterval === 0,
    );

    return sampledData;
  }, [snowData, filter]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {row.dbId} <i style={{ fontSize: "14px" }}>({row.name})</i>
      </Typography>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && snowData && (
        <>
          <LineChart
            data={filteredSnowData}
            dataKey="Snow level"
            domain={[0, "dataMax + 80"]}
            formatter={(value) => `${value} cm`}
            tickFormatter={(value) => `${value} cm`}
            labelFormatter={(value) => formattedDate(new Date(value), true)}
            getXAxisArgs={() => ({
              tickFormatter: tickFormatter,
            })}
            noStyling={true}
            height={250}
            customStartIndex={161}
            showBrush
          />
          <Grid container gap={0} justifyContent="center">
            {filterCategoriesArray.map((item, index) => (
              <Button
                key={index}
                sx={{
                  height: "30px",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === filterCategoriesArray.length - 1
                      ? "0 5px 5px 0"
                      : 0,
                }}
                variant={
                  filter === item[Object.keys(item)[0]]
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setFilter(item[Object.keys(item)[0]])}
              >
                {Object.keys(item)[0]}
              </Button>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(SnowChart);
