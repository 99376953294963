import React, { useMemo, useState, useCallback } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { icon, divIcon } from "leaflet";
import { Grid } from "@mui/material";
import isEqual from "lodash/isEqual";
import { useGet } from "../../../../request";
import VesselDetails from "./details/VesselDetails";
import VesselInfoSlide from "../VesselInfoSlide";
import { calculateProgress } from "../../../../utils";

const Bargin = ({
  vessel,
  selected,
  selectVessel,
  handleAddToFleetModal,
  color,
}) => {
  const [vesselData, setVesselData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const get = useGet();

  const handleOpenChange = () => {
    setOpen(true);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await get("vessel", {
        mmsi: vessel.mmsi,
      });
      setVesselData({ ...vessel, ...response });
    } catch (error) {
      setError("Error fetching some vessel data");
      console.error("Error fetching vessel data:", error);
    }
    setLoading(false);
  }, [get, vessel]);

  const progress = useMemo(() => {
    if (!vesselData.atd_epoch || !vesselData.eta_epoch) {
      return 0;
    }
    return calculateProgress(vesselData.atd_epoch, vesselData.eta_epoch);
  }, [vesselData.atd_epoch, vesselData.eta_epoch]);

  const handleSelectVessel = useCallback(
    (vessel) => {
      setTimeout(() => {
        selectVessel(vessel);
      }, 0);
    },
    [selectVessel],
  );

  return (
    <Marker
      position={[vessel.lat, vessel.lon]}
      rotationAngle={
        (vessel.heading && vessel.heading !== 511
          ? vessel.heading
          : vessel.course) % 360
      }
      eventHandlers={{
        click: () => {
          // handleSelectVessel(vessel);
          // fetchData();
        },
        popupclose: () => {
          handleSelectVessel(null);
          setOpen(false);
        },
      }}
      icon={divIcon({
        className: selected ? "mymarker selected-marker" : "mymarker",
        html: `<div><svg
                width="22"
                height="22"
                viewBox="0 0 4 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 7L2 0L4 7L2 5.5L0 7Z" fill="${
                  color ?? "#fba33f"
                }" stroke="black" stroke-width="0.2"/>
              </svg></div>`,
        iconAnchor: [11, 11],
      })}
    >
      {/* <Popup>
        <VesselDetails
          vessel={vessel}
          vesselData={vesselData}
          progress={progress}
          loading={loading}
          handleOpenChange={handleOpenChange}
          error={error}
          handleAddToFleetModal={handleAddToFleetModal}
        />
      </Popup> */}
      <Tooltip direction="top" opacity={0.9}>
        <Grid>
          <b>
            {vessel.name} [{vessel.country_iso}]
          </b>
        </Grid>
        <Grid>
          Destination: <b>{vessel.destination}</b>
        </Grid>
      </Tooltip>
      {open && <VesselInfoSlide open={open} vesselData={vesselData} />}
    </Marker>
  );
};

export default React.memo(Bargin, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.vessel, nextProps.vessel) &&
    prevProps.color === nextProps.color &&
    prevProps.selected == nextProps.selected
  );
});
