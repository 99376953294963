import * as React from "react";
import {
  Box,
  CircularProgress,
  Alert,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import SpotbargeTable from "./common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "./common/SpotbargeLayout";
import {
  DirectionsBoat,
  CheckCircleOutline,
} from "@mui/icons-material";
import logoSmall from "../assets/logo-blue.svg";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import { useGet } from "../request";
import { formatNumber } from "../utils";
import ExcelExportButton from "./atoms/ExcelExportButton";

function formattedDate(d = new Date(), includeTime = false) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  let hours = String(d.getHours());
  let minutes = String(d.getMinutes());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;

  return `${day}/${month}/${year}${includeTime ? ` ${hours}:${minutes}` : ""}`;
}

function Voyages() {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      id: "timestamp",
      label: t("mytrips_quote_date"),
    },
    {
      id: "loadDate",
      label: t("mytrips_load_date"),
    },
    {
      id: "loadPort",
      label: t("mytrips_load_port"),
      filter: "checkboxes",
    },
    {
      id: "dischargePort",
      label: t("mytrips_discharge_port"),
      filter: "checkboxes",
    },
    {
      id: "bargeName",
      label: t("mytrips_barge_name"),
    },
    {
      id: "product",
      label: t("mytrips_product"),
      filter: "checkboxes",
    },
    {
      id: "tons",
      label: t("mytrips_tons"),
      numeric: true,
    },
    {
      id: "confirmedPrice",
      label: t("mytrips_price_per_ton", { currency: "€" }),
      numeric: true,
    },
    {
      id: "area",
      label: t("mytrips_area"),
      filter: "checkboxes",
    },
    {
      id: "counterParty",
      label: t("mytrips_counterparty"),
    },
    {
      id: "status",
      label: t("mytrips_status"),
      filter: "checkboxes",
    },
    {
      id: "companyName",
      label: t("mytrips_company"),
      filter: "checkboxes",
    },
    {
      id: "userName",
      label: t("mytrips_user"),
      filter: "checkboxes",
    },
    {
      id: "confirmedBy",
      label: <CheckCircleOutline sx={{ position: "relative", left: "12px" }} />,
      width: 20,
    },
  ];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [data, setData] = useState([]);

  const get = useGet();

  const handleFetchAll = () => {
    return fetchQuotes(true);
  };

  const fetchQuotes = async (getAll = false) => {
    try {
      let result = await get("get-quotes", {
        getAll,
      });
      if (!result) {
        setError(t("mytrips_something_went_wrong"));
        return;
      }
      if (result.error) {
        setError(result.error);
        return;
      }
      try {
        result = result.map((o) => {
          return {
            ...o,
            loadDate: formattedDate(new Date(o["loadDate"]._seconds * 1000)),
            timestamp: formattedDate(
              new Date(o["timestamp"]._seconds * 1000),
              true,
            ),
          };
        });
      } catch (e) {
        console.log(e);
        setError(t("mytrips_unexpected_error"));
        return;
      }
      setData([...data, ...result]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async () => await fetchQuotes());

    return unsubscribe;
  }, []);

  const columnRenderMap = {
    confirmedPrice: (row) => row.confirmedPrice ? formatNumber(row.confirmedPrice) : "BL date",
    confirmedBy: (row) =>
      row.confirmedByCounterparty !== undefined &&
      row.confirmedByCounterparty !== null && (
        <Tooltip
          title={
            row.confirmedByCounterparty
              ? "Validated by counterparty"
              : "Validated by Spotbarge"
          }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row.confirmedByCounterparty ? (
              <DirectionsBoat
                fontSize="small"
                sx={{ color: theme.palette.primary.main }}
              />
            ) : (
              <img src={logoSmall} alt="Spotbarge logo" height="16" />
            )}
          </div>
        </Tooltip>
      ),
  };

  return (
    <SpotbargeLayout title={t("my_trips_header")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh"
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <>
            <Box
              display="flex"
              width="100%"
              justifyContent="flex-end"
              sx={{ mb: 1 }}
            >
              <ExcelExportButton />
            </Box>
            <SpotbargeTable
              data={data}
              columns={columns}
              columnRenderMap={columnRenderMap}
              paginate={data.length > 25}
              noDataMessage={t("mytrips_no_data_found")}
              initialSorting={"timestamp"}
              minH="80vh"
              paginateCallback={handleFetchAll}
            />
          </>
        )}
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(Voyages, {
  fallback: <GeneralErrorPage />,
});
