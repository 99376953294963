import React, { useState, useEffect, useMemo } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { formattedDate } from "./RouteHistoryChart";
import { useGet } from "../../../request";
import LineChart from "../../common/charts/LineChart";
import * as idToWaterLevelMap from "./routeToElwis.json";

const WaterLevelChart = ({ row, preRouteText = "" }) => {
  const [loading, setLoading] = useState(false);
  const [waterData, setWaterData] = useState([]);
  const [error, setError] = useState("");
  const [routeRiver, setRouteRiver] = useState("");
  const [reference, setReference] = useState(null);
  const [filter, setFilter] = useState(30);

  const get = useGet();

  const filterCategoriesArray = useMemo(
    () => [
      { All: 10000 },
      { "1 Year": 365 },
      { "6 Months": 180 },
      { "1 Month": 30 },
      { "1 Week": 7 },
      // { Today: 1 },
    ],
    [],
  );

  const fetchData = async () => {
    if (!row) return;
    setLoading(true);
    try {
      const id = idToWaterLevelMap[row.id].id;
      const river = idToWaterLevelMap[row.id].river;
      const routeReference = idToWaterLevelMap[row.id].reference;
      setRouteRiver(river);
      setReference(routeReference);

      const loadablesResponse = await get("loadables", {
        river,
      });
      if (loadablesResponse && loadablesResponse.error) {
        setError(loadablesResponse.error);
        return;
      }

      const data = loadablesResponse.map((item) => ({
        x: new Date(item.timestamp).getTime(),
        timestamp: item.timestamp,
        "Water level": item.waterLevel,
        "30%": item["30%"],
        "50%": item["50%"],
        "70%": item["70%"],
        big: item.big,
        "big30%": item["big30%"],
        "big50%": item["big50%"],
        "big70%": item["big70%"],
        small: item.small,
        "small30%": item["small30%"],
        "small50%": item["small50%"],
        "small70%": item["small70%"],

        reference: routeReference,
      }));
      setWaterData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [row]);

  const filteredWaterData = useMemo(() => {
    if (!waterData || waterData.length === 0) return [];

    const filteredByDate = waterData.filter(
      (change) =>
        new Date(change.timestamp.split("T")[0]) >=
        new Date(new Date().getTime() - filter * 24 * 60 * 60 * 1000),
    );

    const totalDataPoints = filteredByDate.length;
    const desiredMaxPoints = 160;
    const samplingInterval = Math.ceil(totalDataPoints / desiredMaxPoints);

    const today = new Date().toISOString().split("T")[0];
    const sampledData = filteredByDate.filter((item, index) => {
      const itemDate = new Date(item.timestamp).toISOString().split("T")[0];
      return index % samplingInterval === 0 || itemDate === today;
    });

    return sampledData;
  }, [waterData, filter]);

  const tickFormatter = (date) => {
    if (filter > 365) return formattedDate(new Date(date), false);
    return formattedDate(new Date(date), false, filter !== 1, filter === 1);
  };

  return (
    <>
      <Typography
        sx={{
          mb: 2,
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {preRouteText}
        {row.load} to {row.discharge} {row.extra}&nbsp;
        <i style={{ fontSize: "14px" }}>
          (
          {(row.riverOverride || routeRiver).charAt(0).toUpperCase() +
            (row.riverOverride || routeRiver).slice(1).toLowerCase()}
          )
        </i>
      </Typography>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && waterData && (
        <>
          <LineChart
            data={filteredWaterData}
            reference={reference}
            dataKey={["Water level", "50%", "30%", "70%"]}
            domain={[0, 800]}
            formatter={() => {}}
            labelFormatter={(value) => formattedDate(new Date(value), true)}
            getXAxisArgs={() => ({
              tickFormatter: tickFormatter,
            })}
            custom={true}
            isGreen={true}
            noStyling={true}
            height={250}
            customStartIndex={161}
            showBrush
            handleForecast
          />
          <Grid container gap={0} justifyContent="center">
            {filterCategoriesArray.map((item, index) => (
              <Button
                key={index}
                sx={{
                  height: "30px",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === filterCategoriesArray.length - 1
                      ? "0 5px 5px 0"
                      : 0,
                }}
                variant={
                  filter === item[Object.keys(item)[0]]
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setFilter(item[Object.keys(item)[0]])}
              >
                {Object.keys(item)[0]}
              </Button>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(WaterLevelChart);
