import * as React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";
import laptop from "../../assets/laptop.png";
import popup1 from "../../assets/popup1.png";
import popup2 from "../../assets/popup2.png";
import popup3 from "../../assets/popup3.png";
import PropTypes from "prop-types";

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const Laptop = styled("img")({
  width: "100%",
  height: "100%",
});

export const Popup = styled("img")(({ top, right, bottom, left, width }) => ({
  position: "absolute",
  top,
  right,
  bottom,
  left,
  animation: `${float} 6s ease-in-out infinite`,
  width: width || "50%",
}));

const LaptopWithPopups = ({ width }) => {
  return (
    <Box
      sx={{
        position: "relative",
        ml: "auto",
        mr: "auto",
        width,
      }}
    >
      <Laptop src={laptop} alt="laptop" />
      <Popup src={popup1} alt="popup1" top="-10%" right={0} />
      <Popup src={popup2} alt="popup2" left="-10%" top="30%" />
      <Popup src={popup3} alt="popup3" bottom={0} left="60%" />
    </Box>
  );
};

LaptopWithPopups.propTypes = {
  width: PropTypes.string.isRequired,
};

export default LaptopWithPopups;
