import { scaleTime } from "d3-scale";
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import LineChart from "../../common/charts/LineChart";
import IndexChart from "./IndexChart";

export function formattedDate(
  d = new Date(),
  includeTime = false,
  onlyShortDate = false,
  onlyTime = false,
) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  let hours = String(d.getHours());
  let minutes = String(d.getMinutes());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;

  if (onlyShortDate) return `${day}/${month}`;
  if (onlyTime) return `${hours}:${minutes}`;
  return `${day}/${month}/${year}${includeTime ? ` ${hours}:${minutes}` : ""}`;
}

const RouteHistoryChart = ({ row, onClose }) => {
  const [graphData, setGraphData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setGraphData(
        filteredData
        .map((change) => ({
          x: new Date(change.date).getTime(),
          "Index price": parseFloat(change.price.toFixed(2)),
        }))
        .sort((a, b) => a.x - b.x),
      );
    }
  }, [filteredData]);

  const minDate = new Date(Math.min(...filteredData.map((d) => d.x)));
  const maxDate = new Date(Math.max(...filteredData.map((d) => d.x)));
  const isSameDay =
    minDate.getDate() === maxDate.getDate() &&
    minDate.getMonth() === maxDate.getMonth() &&
    minDate.getFullYear() === maxDate.getFullYear();

  const tickFormatter = (date) => {
    date = new Date(date);
    return isSameDay
      ? date.getHours().toString().padStart(2, "0") +
          ":" +
          date.getMinutes().toString().padStart(2, "0")
      : date.getDate().toString().padStart(2, "0") +
          "/" +
          (date.getMonth() + 1).toString().padStart(2, "0");
  };

  const getXAxisArgsForTimeBasedGraph = (numericValues) => {
    const maxValue = Math.max(...numericValues);
    const minValue = Math.min(...numericValues);
    const timeScale = scaleTime().domain([minValue, maxValue]).nice(5);

    return {
      scale: timeScale,
      type: "number",
      domain: timeScale.domain(),
      tickFormatter: tickFormatter,
      ticks: timeScale.ticks(5),
    };
  };

  return (
    <IndexChart row={row} onClose={onClose} filteredData={filteredData} setFilteredData={setFilteredData} endpoint={"route-history"} requestArgs={{ id: row.id }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Historic Index Price for {row.load} to {row.discharge}
      </Typography>
      <LineChart
              data={graphData}
              dataKey={"Index price"}
              domain={[
                Math.max(0, Math.min(...graphData.map((d) => d["Index price"]))) *
                  0.8,
                Math.max(...graphData.map((d) => d["Index price"])),
              ]}
              formatter={(price) => "€" + price.toFixed(2)}
              labelFormatter={(value) => formattedDate(new Date(value), true)}
              getXAxisArgs={getXAxisArgsForTimeBasedGraph}
            />
    </IndexChart>
  );
};

RouteHistoryChart.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    load: PropTypes.string.isRequired,
    discharge: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default RouteHistoryChart;
