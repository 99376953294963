import { memo } from "react";
import { Slide, Grid, Typography, Divider, CardMedia } from "@mui/material";
import Flag from "react-world-flags";

const VesselInfoSlide = ({ open, vesselData }) => {
  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Grid
        sx={{
          zIndex: 9999999999999,
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "white",
          maxWidth: "500px",
          boxShadow: "0 3px 14px rgba(0,0,0,0.4)",
          p: 2,
          borderRadius: 2,
        }}
      >
        <Grid gap={1} container display="grid">
          <Typography variant="h6" component="p" lineHeight={1}>
            Summary
          </Typography>
          <Divider />
          <Typography variant="overline" component="p" lineHeight={1}>
            Where is the ship?
          </Typography>
          <Typography variant="caption">
            {vesselData.type_specific} <b>{vesselData.name}</b> is currently
            located at <b>LAT: {vesselData.lat}</b> <b>LON: {vesselData.lon}</b>
            (reported <b>{vesselData.last_position_UTC}</b>)
          </Typography>
          <Typography variant="overline" component="p" lineHeight={1}>
            What kind of ship is this?
          </Typography>
          <Typography variant="caption" lineHeight={1}>
            <b>{vesselData.name}</b> (mmsi: {vesselData.mmsi}) is an{" "}
            <b>{vesselData.type_specific}</b> and is sailing under the flag of
            &nbsp;
            <Flag
              code={vesselData.country_iso}
              style={{ width: "20px", height: "15px" }}
            />
            {vesselData.country_name}. Her length overall (LOA) is{" "}
            {vesselData.length}m and her width is {vesselData.breadth}m
          </Typography>
        </Grid>
        <Divider sx={{ mt: 2 }} />
        <Grid
          gap={2}
          container
          display="grid"
          sx={{ gridTemplateColumns: "1fr 1fr 1fr", mt: 2 }}
        >
          <Grid container gap={1} display="grid" sx={{ height: "max-content" }}>
            <Typography variant="h6" component="p" lineHeight={1}>
              General
            </Typography>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Name
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.name}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Country
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.country_name}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                IMO
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.imo}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                ENI
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.eni}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Call sign
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.callsign}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Tonage
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.gross_tonnage}T
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                MMSI
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.mmsi}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                General vessel type
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.type}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Detailed vessel type
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.type_specific}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Home port
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.home_port}
              </Typography>
            </Grid>
          </Grid>
          <Grid container gap={1} display="grid" sx={{ height: "max-content" }}>
            <Typography variant="h6" component="p" lineHeight={1}>
              Latest AIS
            </Typography>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Navigational status
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.navigational_status}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Position received
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.last_position_UTC}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Latitude
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.lat}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Longitude
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.lon}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Speed
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.speed}kn
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Course
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.course}°
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                True heading
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.heading}°
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Draught
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.current_draught}m
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Destination
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.destination} ({vesselData.dest_port_unlocode})
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Estimated time of arrival
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.eta_UTC}
              </Typography>
            </Grid>
          </Grid>
          <Grid container gap={1} display="grid" sx={{ height: "max-content" }}>
            <Typography variant="h6" component="p" lineHeight={1}>
              Specifics
            </Typography>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                M3
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.m3}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Product
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.product}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                DWT
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.dwt}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Length
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.length}m
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Draft
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.draft}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Tanks
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.tanks}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Coated
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.coated ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Heating
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.heating ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="caption"
                lineHeight={1}
                color="text.secondary"
              >
                Year built
              </Typography>
              <Typography
                variant="overline"
                component="p"
                lineHeight={1}
                sx={{
                  margin: "0 !important",
                }}
              >
                {vesselData.year_built}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default memo(
  VesselInfoSlide,
  (prevProps, nextProps) =>
    prevProps.open === nextProps.open &&
    prevProps.vesselData.mmsi === nextProps.vesselData.mmsi &&
    prevProps.vesselData.name === nextProps.vesselData.name,
);
