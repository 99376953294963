import { LayersControl, LayerGroup, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import WaterLevelChart from "../index/charts/WaterLevelChart";
import WaterLevelSimpleChart from "../index/charts/WaterLevelSimpleChart";

const StationPoints = ({ graphs }) => {
  return (
    <LayersControl.Overlay checked name="Show station points">
      <LayerGroup>
        {graphs
          .filter((g) => g.hasOwnProperty("marker"))
          .map((ter) => (
            <Marker
              position={ter.marker.coordinates}
              icon={L.divIcon({
                className: "custom-marker",
                html: `<div class="custom-station-icon-container">
                <div class="custom-station-icon">${ter.marker.name}</div>
                <div class="custom-dot">.</div>
              </div>`,
              })}
            >
              <Popup>
                {!ter.isSimple ? (
                  <WaterLevelChart row={ter} />
                ) : (
                  <WaterLevelSimpleChart row={ter} />
                )}
              </Popup>
            </Marker>
          ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default StationPoints;
