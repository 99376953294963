import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CURRENT_INDEX } from "../../utils";

const IndexHistorySelector = ({ items = [], selectedItem, setCurrent }) => {
  const { t } = useTranslation();
  const handleSelectChange = (event) => {
    const index = event.target.value;
    const selected = items[index];
    setCurrent(selected);
  };

  const handleArrowClick = (direction) => {
    const currentIndex = items.indexOf(selectedItem);
    const newIndex = currentIndex + direction;
    const selected = items[newIndex];
    setCurrent(selected);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        id="history-selector"
        sx={{ fontWeight: "bold", color: "#001556", fontSize: "16px" }}
      >
        {t("index_date")}
      </InputLabel>
      <Select
        labelId="history-selector"
        value={items.indexOf(selectedItem)}
        onChange={handleSelectChange}
        sx={{
          minWidth: 210,
          fontSize: "13px",
          height: "40px",
          backgroundColor: "white",
        }}
        label={t("index_date")}
        className="notranslate"
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={index}>
            {item === CURRENT_INDEX ? t("index_currentIndex") : item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

IndexHistorySelector.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.any.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default IndexHistorySelector;
