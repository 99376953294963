import { memo } from "react";
import { Polyline, LayersControl, LayerGroup } from "react-leaflet";

const terminals = [
  {
    name: "test",
    coordinates: [
      [51.958628, 4.121048],
      [51.962401, 4.107325],
      [51.959062, 4.104792],
      [51.955896, 4.115066],
      [51.958628, 4.121048],
    ],
  },
  {
    name: "test2",
    coordinates: [
      [51.9463061, 4.039191],
      [51.946359, 4.039191],
      [51.9442427, 4.0396202],
      [51.9443485, 4.0408218],
      [51.9439782, 4.0426242],
      [51.9441369, 4.0462291],
      [51.9453538, 4.050864],
      [51.9468351, 4.063052],
      [51.9485281, 4.0766132],
      [51.9513319, 4.0765274],
      [51.9463061, 4.039191],
    ],
  },
  {
    name: "test3",
    coordinates: [
      [
        [51.95809386656816, 4.007415707358973],
        [51.956589936277595, 3.995891938046327],
        [51.94712574671027, 3.986042096762958],
        [51.94583396782187, 3.9855601505803975],
        [51.94147061813615, 3.98089512623946],
        [51.94065379039688, 3.9819948034352137],
        [51.939779130082854, 3.9814478719044644],
        [51.93927714583526, 3.9814215635663857],
        [51.93877060301551, 3.9826577659971747],
        [51.94544363205077, 3.990684225314851],
        [51.95042253408394, 3.996884810894926],
        [51.950344475188786, 3.997909625841487],
        [51.95809386656816, 4.007415707358973],
      ],
    ],
  },
];

const Terminals = () => {
  return (
    <LayersControl.Overlay name="Show port terminals">
      <LayerGroup>
        {terminals.map((ter) => (
          <Polyline
            key={ter.name}
            pathOptions={{ color: "blue", weight: 1.5 }}
            positions={ter.coordinates}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

const areEqual = (prevProps, nextProps) => {
  return true;
};

export default memo(Terminals, areEqual);
