import React from "react";
import { Box } from "@mui/material";
import TopSection from "./TopSection";
import MiddleSection from "./MiddleSection";
import Footer from "../common/Footer";
import BottomSection from "./BottomSection";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";

function Homepage() {
  return (
    <Box>
      <Box
        style={{
          paddingTop: "60px",
          background:
            "linear-gradient(100deg, rgb(0, 18, 85) 50%, rgb(115 221 201) 100%)",
        }}
      >
        <TopSection />
      </Box>
      <MiddleSection />
      <BottomSection />
      <Footer />
    </Box>
  );
}

export default withErrorBoundary(Homepage, {
  fallback: <GeneralErrorPage />,
});
