import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
if (process.env.REACT_APP_ENV === "local") {
  firebase.storage().useEmulator("localhost", 9199);
}
export const callFunction = async (
  functionName,
  data = {},
  isWrite = false,
  headers = {},
  responseType = "json",
  isStandalone = false,
) => {
  try {
    const isProd =
      process.env.NODE_ENV && process.env.NODE_ENV === "production";
    const user = firebase.auth().currentUser;
    const token = user && (await user.getIdToken());
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };
    const endpoint = isWrite ? "write" : "get";

    functionName = isStandalone ? functionName : `${endpoint}/${functionName}`;
    const url = isProd
      ? `/${functionName}`
      : isStandalone
      ? `http://127.0.0.1:5001/spotbarge-dev/europe-west1/${functionName}`
      : `http://127.0.0.1:5001/spotbarge-dev/europe-west1/${endpoint}/${functionName}`;
    const response = await fetch(url, options);
    if (responseType === "blob") {
      return await response.blob();
    }
    return await response.json();
  } catch (e) {
    return false;
  }
};
