import React from "react";
import { Grid, Typography, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import icons from "./icons.json";
import * as Icons from "./ImageImporter";

const determineIcon = (icon) => {
  return icons.find((i) => i.icon === icon.icon && i.id === icon.id);
};

const StyledWrapper = styled(Grid)(({ isSelected }) => ({
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: isSelected ? "#f9fafb" : "transparent",
  boxShadow: isSelected ? "inset 0px 2px 16px -4px #c7c7c7" : "none",
  "&:hover": {
    backgroundColor: "#3292d721",
  },
  transition: "all 0.3s !important",
  borderLeft: "1px solid #e5e5e5",
  borderRadius: "2px",
}));

function CityWeatherWidget({ city, selectedCity, handleSelect }) {
  const iconSrc = determineIcon(city.weather[0])["src"];
  const matchingIconName = iconSrc
    .split(".")[0]
    .replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });

  return (
    <Fade in={true}>
      <StyledWrapper
        container
        lg={2}
        md={4}
        sm={6}
        xs={12}
        p={2}
        onClick={() => handleSelect(city)}
        isSelected={selectedCity?.name === city.name}
      >
        <Grid
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          container
        >
          <Typography variant="subtitle1" component="h6">
            {city.name}
          </Typography>
          <Typography variant="caption">{city.sys.country}</Typography>
        </Grid>
        <Grid container alignItems="center" flexWrap="nowrap">
          <img
            src={Icons[matchingIconName]}
            style={{
              maxWidth: "120px",
              filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.2))",
            }}
          />

          <Grid container direction="column">
            <Typography variant="subtitle2">
              {Math.round(city.main.temp)}°C
            </Typography>
            <Typography variant="caption">
              {Math.round(city.main.feels_like)}°C
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Typography variant="caption" component="h6">
            {city.weather[0].description}
          </Typography>
        </Grid>
      </StyledWrapper>
    </Fade>
  );
}

export default React.memo(CityWeatherWidget);
