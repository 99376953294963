import * as React from "react";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Download } from "@mui/icons-material";
import { useGet } from "../../request";
import { useTranslation } from "react-i18next";

function ExcelExportButton({ isAdmin = false }) {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);
  const get = useGet();

  const printToXcel = async () => {
    setIsDownloading(true);
    try {
      let result = await get(isAdmin ? "quotes-xls-admin" : "quotes-xls", {}, "blob");
      if (result) {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotes.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error("Failed to generate Excel file");
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <LoadingButton
      loading={isDownloading}
      loadingPosition="start"
      startIcon={<Download />}
      variant="outlined"
      sx={{
        height: "40px",
      }}
      onClick={printToXcel}
    >
      {t("mytrips_excel")}
    </LoadingButton>
  );
}

export default ExcelExportButton;
