import React, {  } from "react";
import {
  Box,
  IconButton,
  Modal,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import WaterLevelChart from "./WaterLevelChart";

const WaterLevelPopup = ({ row, onClose }) => {
    return (
      <Modal
        open={!!row}
        onClose={onClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "80%",
            maxWidth: "90%",
            maxHeight: "90vh",
            overflow: "auto",
            position: "relative",
            borderRadius: 2,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0, margin: 1 }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <WaterLevelChart row={row} preRouteText="Water level for " />
        </Box>
      </Modal>
    );
  };
  
  export default WaterLevelPopup;