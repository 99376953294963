import { Polyline, LayersControl, LayerGroup, Marker } from "react-leaflet";
import L from "leaflet";

const terminals = [
  {
    name: "Amsterdam",
    coordinates: [52.40707416960373, 4.804522872806388],
  },
  {
    name: "Roterdam",
    coordinates: [51.88556547472945, 4.280026833061328],
  },
  {
    name: "Antwerp",
    coordinates: [51.265376492207764, 4.364985747400283],
  },
  {
    name: "Essen",
    coordinates: [51.50405825757886, 6.973955357394459],
  },
  {
    name: "Frankfurt",
    coordinates: [50.106706798000005, 8.709690281343256],
  },
  {
    name: "Mannheim",
    coordinates: [49.430066776279745, 8.507541953019995],
  },
  {
    name: "Karlsruhe",
    coordinates: [49.01711306959089, 8.2990251395413],
  },
  {
    name: "Strasbourg",
    coordinates: [48.58675682258547, 7.800504751286894],
  },
  {
    name: "Basel",
    coordinates: [47.58893358681553, 7.588228032585619],
  },
];

const Ports = () => {
  return (
    <LayersControl.Overlay checked name="Show ports">
      <LayerGroup>
        {terminals.map((ter) => (
          <Marker
            position={ter.coordinates}
            icon={L.divIcon({
              className: "custom-marker",
              html: `<div class="custom-port-icon-container">
                <div class="custom-port-icon">${ter.name}</div>
                <div class="custom-dot-port">★</div>
              </div>`,
            })}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default Ports;
