import React from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import OutlinedSection from "../atoms/OutlinedSection";
import { useTranslation } from "react-i18next";

function IndexAreaSelector({ areas, filter, handleFilterChange }) {
  const { t } = useTranslation();
  const handleArrowClick = (direction) => {
    const currentIndex = areas.indexOf(filter);
    const newIndex = currentIndex + direction;
    const selected = areas[newIndex];
    handleFilterChange(selected);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        id="area-selector"
        sx={{ fontWeight: "bold", color: "#001556", fontSize: "16px" }}
      >
        {t("index_area")}
      </InputLabel>
      <Select
        labelId="area-selector"
        value={filter}
        onChange={(e) => handleFilterChange(e.target.value)}
        label={t("index_area")}
        sx={{
          minWidth: 210,
          fontSize: "13px",
          height: "40px",
          backgroundColor: "white",
        }}
      >
        {areas.map((area) => (
          <MenuItem key={area} value={area}>
            {area}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

IndexAreaSelector.propTypes = {
  areas: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default IndexAreaSelector;
