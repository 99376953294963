import React, { useEffect, useContext } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import { UserContext } from "./AuthGuard";

const LoginSuccess = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  useEffect(() => {
    if (user) {
      if (!user.role) {
        navigate("/login");
      } else if (user.role === "none") {
        navigate("/user");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default withErrorBoundary(LoginSuccess, {
  fallback: <GeneralErrorPage />,
});
