import React from "react";
import { Grid, Box, LinearProgress } from "@mui/material";
import GpsFixed from "@mui/icons-material/GpsFixed";
import Room from "@mui/icons-material/Room";

const ProgressGrid = ({ value }) => {
  return (
    <Grid
      container
      display="grid"
      gap={1}
      sx={{
        gridTemplateColumns: "min-content 1fr min-content",
        transform: "perspective(450px) rotateX(65deg)",
      }}
    >
      <GpsFixed sx={{ color: "#212B36" }} />
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="buffer"
          value={value}
          valueBuffer={value}
          sx={{
            height: 25,
            borderRadius: 0,
            boxShadow: "0 7px 10px -5px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Box>
      <Room sx={{ color: "#212B36" }} />
    </Grid>
  );
};

export default ProgressGrid;
