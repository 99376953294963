import React from "react";
import { Tooltip, Grid, Button } from "@mui/material";
import shipIcon from "../../../assets/sh2.svg";
import { styled } from "@mui/material/styles";

const StyledSheepCount = styled(Button)({
  textAlign: "center",
  display: "grid",
  borderRadius: 0,
  overflow: "hidden",
  gap: "10px",
  whiteSpace: "nowrap",
  height: "70px",
  boxShadow: "inset 0px 0px 14px -4px rgb(72 72 72 / 50%)",
  backgroundColor: "white",
  justifyItems: "center",
});

const Counter = ({ vessels }) => {
  return (
    <Tooltip
      title={`Number of ships tracked`}
      placement="right"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            color: "common.black",
            "& .MuiTooltip-arrow": {
              color: "common.white",
            },
          },
        },
      }}
    >
      <Grid
        sx={{
          zIndex: 9999999999999,
          position: "absolute",
          top: "374px",
          left: "15.5px",
        }}
      >
        <StyledSheepCount
          disabled
          variant="text"
          size="large"
          color="primary"
          fullWidth
        >
          <img
            src={shipIcon}
            alt="ship"
            style={{
              height: 20,
              transform: "rotate(90deg)",
            }}
          />
          <Grid sx={{ marginTop: "-15px" }}>{vessels.length}</Grid>
        </StyledSheepCount>
      </Grid>
    </Tooltip>
  );
};

export default Counter;
