import i18n, { use } from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "./lang/translations.json";

// should return {[key: string]: translation}
const translationToLanguage = (language) => {
  return Object.keys(languages).reduce((acc, key) => {
    acc[key] = languages[key][language];
    return acc;
  }, {});
};

use(initReactI18next).init({
  resources: {
    en: {
      translation: translationToLanguage("en"),
    },
    nl: {
      translation: translationToLanguage("nl"),
    },
    fr: {
      translation: translationToLanguage("fr"),
    },
    de: {
      translation: translationToLanguage("de"),
    },
  },
  lng: "en",
  fallbackLng: "en",
});

export default i18n;
