import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Stack,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import {
  Info,
  Insights,
  Water,
  Sailing,
  Scale,
  PendingActions,
  Category,
} from "@mui/icons-material";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import IndexHistorySelector from "./IndexHistorySelector";
import IndexAreaSelector from "./IndexAreaSelector";
import SpotbargeTable from "../common/SpotbargeTable";
import ExplainabilityPopup from "./ExplainabilityPopup";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../common/SpotbargeLayout";
import { formatTons, formatDate } from "../../utils";
import { Areas } from "../QuoteForm";
import GeneralErrorPage from "../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useGet } from "../../request";
import TotalQuotesChart from "./charts/TotalQuotesChart";
import TotalTonsChart from "./charts/TotalTonsChart";
import TotalProductsChart from "./charts/TotalProductsChart";
import RouteHistoryChart from "./charts/RouteHistoryChart";
import WaterLevelChart from "./charts/WaterLevelChart";
import { CURRENT_INDEX, formatNumber } from "../../utils";
import DownloadIndex from "./DownloadIndex";
import { UserContext } from "../AuthGuard";
import Widget from "./Widget";
import WaterLevelPopup from "./charts/WaterLevelPopup";

function Index() {
  const { t } = useTranslation();
  const [items, setItems] = useState([CURRENT_INDEX]);
  const [currentItem, setCurrentItem] = useState(CURRENT_INDEX);
  const [currentArea, setCurrentArea] = useState(() =>
    localStorage.getItem("areaFilter"),
  );
  const [data, setData] = useState({});
  const [total, setTotal] = useState({});
  const [user] = useContext(UserContext);

  const [loading, setLoading] = useState([]);
  const [error, setError] = useState({});

  const [selectedRow, setSelectedRow] = useState(null);
  const [chartState, setChartState] = useState({});
  const [explainability, setExplainability] = useState({});
  const theme = useTheme();
  // if currentItem is a yyyy-MM-dd date, then store the date as a Date in the historyDate memo
  const historyDate = useMemo(() => {
    return currentItem.match(/^\d{4}-\d{2}-\d{2}$/)
      ? new Date(currentItem)
      : null;
  }, [currentItem]);

  const get = useGet();

  const getColumns = useCallback(
    () => [
      {
        id: "load",
        label: t("index_load"),
      },
      {
        id: "discharge",
        label: t("index_discharge"),
      },
      {
        id: "price",
        label: t("index_pricePerTon"),
      },
      {
        id: "low",
        label: t("index_low"),
      },
      {
        id: "high",
        label: t("index_high"),
      },
      {
        id: "change",
        label: t("index_changePerTon"),
      },
      {
        id: "quotesCount",
        label: t("index_quotesCount"),
      },
      {
        id: "lastDeal",
        label: t("index_last_deal"),
      },
      {
        id: "graph",
        label: t("index_historicPrice"),
      },
      {
        id: "avgLastFiveDays",
        label: t("index_avgLastFiveDays"),
      },
    ],
    [],
  );

  const tableGroups = useMemo(
    () => ({
      "CPP ARA > 1500 ton":
        historyDate && historyDate < new Date("2024-04-26")
          ? [
              {
                title: "",
                start: 0,
                end: 99,
              },
            ]
          : [
              {
                title: "ARA",
                start: 0,
                end: 3,
              },
              {
                title: "Flushing",
                start: 4,
                end: 7,
              },
              {
                title: "Ghent",
                start: 8,
                end: 12,
              },
              {
                title: "Dordrecht",
                start: 13,
                end: 17,
              },
            ],
      "CPP Rhine": [
        {
          title: "Upstream",
          start: 0,
          end: 9,
        },
        {
          title: "Downstream",
          start: 10,
          end: 11,
        },
      ],
      "DPP ARA": [
        {
          title: "ARA",
          start: 0,
          end: 3,
        },
        {
          title: "Flushing",
          start: 4,
          end: 5,
        },
      ],
    }),
    [historyDate],
  );

  const getTooltipColumns = useCallback(
    () =>
      currentArea === Areas.Canals
        ? []
        : [
            {
              id: "sailingTime",
              label: t("index_sailing_time"),
              width: 142,
            },
          ],
    [currentArea],
  );

  const [columns, setColumns] = useState(getColumns());
  const [tooltipColumns, setTooltipColumns] = useState(getColumns());

  let totalQuotes = 0;
  let totalTons = 0;
  let outliers = 0;

  if (total[currentItem] && total[currentItem][currentArea]) {
    totalQuotes = total[currentItem][currentArea]
      ? total[currentItem][currentArea]?.quotes
      : 0;
    totalTons = total[currentItem][currentArea]
      ? formatTons(total[currentItem][currentArea]?.tons)
      : 0;
    outliers = total[currentItem][currentArea]?.outliers;
  }

  useEffect(() => {
    setColumns(getColumns());
    setTooltipColumns(getTooltipColumns());
    if (
      currentArea === Areas.CPP_RHINE &&
      !columns.some((c) => c.id === "loadables")
    ) {
      setColumns([
        ...getColumns(),
        {
          id: "loadables",
          label: "Loadables",
        },
      ]);
    }
  }, [getColumns, currentArea]);

  const handleCloseModal = () => {
    setChartState({});
  };

  const handleClose = () => {
    setSelectedRow(null);
  };

  const filterRows = (rows) => rows.filter((row) => row.area === currentArea);
  const filteredData = filterRows(data[currentItem] || []);
  const sortedData = filteredData.sort((a, b) => a.order - b.order);
  const handleFilterChange = (area) => {
    setCurrentArea(area);
    localStorage.setItem("areaFilter", area);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData("index", false);
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async (endpoint, doLoad = true) => {
    if (doLoad) {
      setLoading((prevLoading) => ({ ...prevLoading, [currentItem]: true }));
    }
    const payload = { date: currentItem };

    try {
      let result = await get(endpoint, payload);
      if (!result || !result.index || !result.index.length) {
        if (!doLoad) return;
        setError((error) => ({
          ...error,
          [currentItem]: t("index_somethingWentWrong"),
        }));
        return;
      }
      if (result.error) {
        if (!doLoad) return;
        setError((error) => ({ ...error, [currentItem]: result.error }));
        return;
      }
      if (!currentArea) {
        setCurrentArea(result.index[0].area);
      }
      const index = result.index.filter((e) => !e.id.startsWith("total"));
      index.areas = index
        .map((e) => e.area)
        .filter((e, i, a) => a.indexOf(e) === i);
      if (!index.areas.includes(currentArea)) {
        setCurrentArea(index[0].area);
      }
      setData((data) => ({ ...data, [currentItem]: index }));
      const totals = result.index
        .filter((e) => e.id.startsWith("total"))
        .reduce((acc, e) => ({ ...acc, [e.area]: e }), {});
      setTotal((total) => ({ ...total, [currentItem]: totals }));
      if (result.explainability) {
        setExplainability({
          ...explainability,
          [currentItem]: result.explainability,
        });
        setColumns((prev) => {
          if (prev.some((c) => c.id === "explain")) {
            return prev;
          }
          return [
            ...prev,
            {
              id: "explain",
              label: t("index_explainability"),
            },
          ];
        });
      }
    } finally {
      if (doLoad) {
        setLoading({ ...loading, [currentItem]: false });
      }
    }
  };

  useEffect(() => {
    if (!data[currentItem] && currentItem !== CURRENT_INDEX) {
      fetchData("history");
    }
  }, [currentItem]);

  const fetchHistory = async () => {
    setLoading({ ...loading, [currentItem]: true });
    try {
      let result = await get("history-dates", {
        date: currentItem,
      });
      if (!result || !result.dates) {
        setError({ ...error, [currentItem]: t("index_somethingWentWrong") });
        return;
      }
      if (result.error) {
        setError({ ...error, [currentItem]: result.error });
        return;
      }
      setItems([...result.dates, CURRENT_INDEX]);
    } finally {
      setLoading({ ...loading, [currentItem]: false });
    }
  };

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async () => {
        await Promise.all([fetchData("index"), fetchHistory()]);
      }),
    [],
  );

  const withDashForZeroQuotes = (data, quotesCount) => {
    if (!quotesCount) {
      return "-";
    }
    return data;
  };

  const withIndicativeTooltip = (data, indicative) => {
    if (indicative) {
      return (
        <Tooltip title={t("index_indicativePrice")}>
          <Box sx={{ fontStyle: "italic" }}>{data}*</Box>
        </Tooltip>
      );
    }
    return data;
  };

  const columnRenderMap = {
    price: (row) =>
      withIndicativeTooltip(formatNumber(row.price), row.indicative),
    quotesCount: (row) =>
      withIndicativeTooltip(row.quotesCount, row.indicative),
    explain: (row) => (
      <Tooltip title={t("index_clickToSeeExplainability")}>
        <IconButton
          onClick={() => setSelectedRow(explainability[currentItem][row.id])}
        >
          <Info />
        </IconButton>
      </Tooltip>
    ),
    graph: (row) => (
      <Tooltip title={t("index_historicPriceForRoute")}>
        <IconButton
          onClick={() => setChartState({ chart: "route_history", row })}
        >
          <Insights />
        </IconButton>
      </Tooltip>
    ),
    loadables: (row) => (
      <Tooltip title={"Loadables based on water level"}>
        <IconButton
          onClick={() => setChartState({ chart: "water_level", row })}
        >
          <Water />
        </IconButton>
      </Tooltip>
    ),
    sailingTime: (row) => {
      if (!row.sailingTime) {
        return <></>;
      }
      return (
        (row.sailingTime >= 24
          ? parseFloat((row.sailingTime / 24).toFixed(1)) + " day"
          : row.sailingTime + " hour") +
        (row.sailingTime === 1 || row.sailingTime === 24 ? "" : "s")
      );
    },
    validationPercentage: (row) => {
      if (row.quotesCount === 0) {
        return "-";
      }
      return formatNumber(row.validationPercentage, 0) + "%";
    },
    low: (row) =>
      withDashForZeroQuotes(
        withIndicativeTooltip(formatNumber(row.low), row.indicative),
        row.quotesCount,
      ),
    high: (row) =>
      withDashForZeroQuotes(
        withIndicativeTooltip(formatNumber(row.high), row.indicative),
        row.quotesCount,
      ),
    lastDeal: (row) => {
      if (!row.lastDealDate || !row.lastDealPrice || row.lastDealPrice === 0) {
        return "-";
      }
      return (
        <div style={{ lineHeight: 1.1 }}>
          <div>
            <b>{t("index_last_deal_date")}:</b> {formatDate(row.lastDealDate)}
          </div>
          <div>
            <b>{t("index_last_deal_price")}:</b>{" "}
            {formatNumber(row.lastDealPrice)}
          </div>
        </div>
      );
    },
    avgLastFiveDays: (row) => {
      if (!row.avgLastFiveDays) {
        return "-";
      }
      return formatNumber(row.avgLastFiveDays);
    },
  };

  const renderSpecialities = () => {
    if (currentArea === Areas.DPP_ARA || currentArea === Areas.Canals) {
      return <></>;
    }

    let ethanol = 0;
    let jet = 0;
    let gasoline = 0;
    let ucome = 0;
    if (
      total &&
      total[currentItem] &&
      total[currentItem][currentArea] &&
      total[currentItem][currentArea].deltaEthanol !== undefined
    ) {
      ethanol = total[currentItem][currentArea].deltaEthanol;
      jet = total[currentItem][currentArea].deltaJet;
      gasoline = total[currentItem][currentArea].deltaGasoline;
      ucome = total[currentItem][currentArea].deltaUcome;
    }

    return [
      <Widget isSpeciality={true} number={ethanol} name={"Ethanol"} />,
      <Widget isSpeciality={true} number={jet} name={"Distillates"} />,
      <Widget isSpeciality={true} number={gasoline} name={"Lights"} />,
      <Widget isSpeciality={true} number={ucome} name={"Fame"} />,
    ];
  };

  const productWidget =
    currentArea === Areas.DPP_ARA || currentArea === Areas.Canals
      ? []
      : [
          <Widget
            onlyTitle={true}
            icon={<Category />}
            name={t("index_market_products")}
            handleClick={() => setChartState({ chart: "total_products" })}
          />,
        ];

  const renderMarketLiquidity = [
    <Widget
      icon={<Sailing />}
      number={totalQuotes}
      name={t("index_market_total_quotes")}
      handleClick={() => setChartState({ chart: "total_quotes" })}
    />,
    <Widget
      icon={<Scale />}
      number={totalTons}
      name={t("index_market_total_tons")}
      handleClick={() => setChartState({ chart: "total_tons" })}
    />,
    <Widget
      icon={<PendingActions />}
      number={outliers}
      name={t("index_market_outliers")}
    />,
    ...productWidget,
  ];

  return (
    <SpotbargeLayout title={t("index_header")}>
      <Box>
        {loading[currentItem] && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error[currentItem] && (
          <Alert severity="error">{error[currentItem]}</Alert>
        )}
        {!loading[currentItem] && !error[currentItem] && data[currentItem] && (
          <>
            <Box sx={{ flexGrow: 1, mb: 2 }}>
              <Grid container gap={3}>
                <Grid
                  container
                  spacing={2}
                  columns={{ xs: 4, sm: 4, md: 8, lg: 8, xl: 8 }}
                >
                  {renderSpecialities()}
                  {renderMarketLiquidity}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  columns={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                >
                  <Grid item xs={2} sm={4} md={2} lg={1}>
                    <IndexAreaSelector
                      areas={data[currentItem].areas}
                      filter={currentArea}
                      handleFilterChange={handleFilterChange}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={2} lg={1}>
                    <IndexHistorySelector
                      items={items}
                      setCurrent={setCurrentItem}
                      selectedItem={currentItem}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {tableGroups[currentArea].map((group) => (
              <Box sx={{ mb: "2rem" }}>
                {group.title && (
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.primary.main, mb: "0.5rem" }}
                  >
                    {group.title}
                  </Typography>
                )}
                <SpotbargeTable
                  data={sortedData
                    .map((e) =>
                      e.discharge === group.title
                        ? { ...e, load: e.discharge, discharge: e.load }
                        : e,
                    )
                    .slice(group.start, group.end + 1)}
                  columns={columns}
                  tooltipColumns={tooltipColumns}
                  columnRenderMap={columnRenderMap}
                  filterKey={currentArea}
                />
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "left", md: "space-between" },
                alignItems: { xs: "left", md: "flex-start" },
                mt: 2,
              }}
            >
              <Alert
                severity="info"
                elevation={1}
                size="small"
                sx={{ fontSize: 10, textAlign: "center" }}
              >
                {t("index_disclaimer")}
              </Alert>
            </Box>
          </>
        )}
        <ExplainabilityPopup
          area={currentItem}
          selectedRow={selectedRow}
          handleClose={handleClose}
        />
        {chartState.chart === "water_level" && (
          <WaterLevelPopup row={chartState.row} onClose={handleCloseModal} />
        )}
        {chartState.chart === "route_history" && (
          <RouteHistoryChart row={chartState.row} onClose={handleCloseModal} />
        )}
        {chartState.chart === "total_quotes" && (
          <TotalQuotesChart area={currentArea} onClose={handleCloseModal} />
        )}
        {chartState.chart === "total_tons" && (
          <TotalTonsChart area={currentArea} onClose={handleCloseModal} />
        )}
        {chartState.chart === "total_products" && (
          <TotalProductsChart area={currentArea} onClose={handleCloseModal} />
        )}
      </Box>
      <DownloadIndex
        index={data[currentItem]}
        totals={total[currentItem]}
        tableGroups={tableGroups}
      />
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(Index, {
  fallback: <GeneralErrorPage />,
});
