import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const ForbiddenErrorPage = () => {
  const { t: translation } = useTranslation();

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Typography variant="h4">{translation("forbidden_page")}</Typography>

      <Typography variant="body1">
        {translation("correct_permissions_to_view_page")}
      </Typography>

      <Button
        sx={{
          mt: 2,
        }}
        variant="contained"
        color="primary"
        onClick={navigateBack}
      >
        {translation("go_back")}
      </Button>
    </Container>
  );
};

export default ForbiddenErrorPage;
