import React, { useState, useEffect, useMemo } from "react";
import {
  CircularProgress,
  Alert,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useGet } from "../../../request";
import LineChart from "../../common/charts/LineChart";
import { formattedDate } from "./RouteHistoryChart";

const WaterLevelSimpleChart = ({ row, customDomain = null }) => {
  const [loading, setLoading] = useState(false);
  const [waterData, setWaterData] = useState([]);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState(30);

  const get = useGet();

  const filterCategoriesArray = useMemo(
    () => [
      { All: 10000 },
      { "1 Year": 365 },
      { "6 Months": 180 },
      { "1 Month": 30 },
      { "1 Week": 7 },
      // { Today: 1 },
    ],
    [],
  );

  const fetchData = async () => {
    if (!row) return;
    setLoading(true);
    try {
      const waterLevelsResponse = await get("water-level", {
        stationId: row.id,
      });
      if (waterLevelsResponse && waterLevelsResponse.error) {
        setError(waterLevelsResponse.error);
        return;
      }

      const data = waterLevelsResponse.map((item) => ({
        x: new Date(item.timestamp).getTime(),
        timestamp: item.timestamp,
        "Water level": item.value,
      }));
      setWaterData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [row]);

  const tickFormatter = (date) => {
    if (filter > 365) return formattedDate(new Date(date), false);
    return formattedDate(new Date(date), false, filter !== 1, filter === 1);
  };

  const filteredWaterData = useMemo(() => {
    if (!waterData || waterData.length === 0) return [];

    const filteredByDate = waterData.filter(
      (change) =>
        new Date(change.timestamp.split("T")[0]) >=
        new Date(new Date().getTime() - filter * 24 * 60 * 60 * 1000),
    );

    const totalDataPoints = filteredByDate.length;
    const desiredMaxPoints = 160;
    const samplingInterval = Math.ceil(totalDataPoints / desiredMaxPoints);

    const sampledData = filteredByDate.filter(
      (_, index) => index % samplingInterval === 0,
    );

    return sampledData;
  }, [waterData, filter]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {row.name}
        {row.altitude && (
          <Typography
            component={"span"}
            sx={{ mb: 2, textAlign: "center", fontSize: "10px !important" }}
          >
            &nbsp;(ISO {row.altitude}m)
          </Typography>
        )}
      </Typography>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && waterData && (
        <>
          <LineChart
            data={filteredWaterData}
            dataKey="Water level"
            domain={row.customDomain ?? [0, 1000]}
            formatter={(value) => `${value} cm`}
            labelFormatter={(value) => formattedDate(new Date(value), true)}
            getXAxisArgs={() => ({
              tickFormatter: tickFormatter,
            })}
            noStyling={true}
            height={250}
            customStartIndex={161}
            showBrush
          />
          <Grid container gap={0} justifyContent="center">
            {filterCategoriesArray.map((item, index) => (
              <Button
                key={index}
                sx={{
                  height: "30px",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === filterCategoriesArray.length - 1
                      ? "0 5px 5px 0"
                      : 0,
                }}
                variant={
                  filter === item[Object.keys(item)[0]]
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setFilter(item[Object.keys(item)[0]])}
              >
                {Object.keys(item)[0]}
              </Button>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(WaterLevelSimpleChart);
