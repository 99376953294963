import React, { useState, useCallback, useEffect, useMemo } from "react";
import { scaleTime } from "d3-scale";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import {
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Button,
} from "@mui/material";
import { useGet } from "../../request";
import LineChart from "../common/charts/LineChart";
import { formattedDate } from "../index/charts/RouteHistoryChart";

const GraphPriceHistory = ({ area }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(30);
  const [dashboardData, setDashboardData] = useState([]);
  const get = useGet();

  const filterCategoriesArray = useMemo(
    () => [
      { All: 10000 },
      { "6 Months": 180 },
      { "1 Month": 30 },
      { "1 Week": 7 },
    ],
    [],
  );

  const dataKeys = useMemo(() => {
    return {
      "CPP Rhine": ["ARA - Basel", "ARA - Frankfurt", "ARA - Duisburg"],
      "CPP ARA > 1500 ton": [
        "Rotterdam - Antwerp",
        "Cross harbor - Cross harbor",
        "Antwerp - Amsterdam",
      ],
    };
  });

  const propertyKeyInData = {
    "CPP ARA > 1500 ton": [
      "DWTHeavyRotterdamAntwerp",
      "DWTHeavyCrossCross",
      "DWTHeavyAntwerpAmsterdam",
    ],
    "CPP Rhine": ["RhineARABasel", "RhineARAFrankfurt", "RhineARADuisburg"],
  };

  const mapAreas = (change, key, properties) => ({
    x: new Date(change.date).getTime(),
    [dataKeys[key][0]]: parseFloat(change[properties[0]]?.toFixed(2)),
    [dataKeys[key][1]]: parseFloat(change[properties[1]]?.toFixed(2)),
    [dataKeys[key][2]]: parseFloat(change[properties[2]]?.toFixed(2)),
  });

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get("price-history");
      if (response && response.error) {
        setError(response.error);
        return;
      }
      setDashboardData(response.routeHistoryPrices);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const filteredDashboardData = useMemo(() => {
    if (!dashboardData) return [];
    if (filter === 10000) return dashboardData;

    const filteredData = dashboardData.filter((change) => {
      return (
        new Date(change.date) >=
        new Date(new Date().getTime() - filter * 24 * 60 * 60 * 1000)
      );
    });

    return filteredData;
  }, [dashboardData, filter]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(fetchDashboardData);
    return unsubscribe;
  }, [fetchDashboardData]);

  const transformedGraphData = filteredDashboardData
    .map((change) => mapAreas(change, area, propertyKeyInData[area]))
    .sort((a, b) => a.x - b.x);

  const tickFormatter = (date) => {
    date = new Date(date);
    return (
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0")
    );
  };

  const getXAxisArgsForTimeBasedGraph = (numericValues) => {
    const maxValue = Math.max(...numericValues);
    const minValue = Math.min(...numericValues);
    const timeScale = scaleTime().domain([minValue, maxValue]).nice(5);

    return {
      scale: timeScale,
      type: "number",
      domain: timeScale.domain(),
      tickFormatter: tickFormatter,
    };
  };

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {t("widget_price_history")}
      </Typography>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "250px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && error && <Alert severity="error">{error}</Alert>}
      {!error && !loading && dashboardData && (
        <>
          <LineChart
            noStyling={true}
            showBrush
            data={transformedGraphData}
            dataKey={dataKeys[area]}
            domain={[
              Math.max(
                0,
                Math.min(
                  ...transformedGraphData.map((d) => d["Rotterdam - Antwerp"]),
                ),
              ) * 0.8,
              Math.max(
                ...transformedGraphData.map((d) => d["Antwerp - Amsterdam"]),
              ),
            ]}
            formatter={(price) => (price ? "€" + price.toFixed(2) : "N/A")}
            labelFormatter={(value) => formattedDate(new Date(value), true)}
            height={250}
            getXAxisArgs={getXAxisArgsForTimeBasedGraph}
          />
          <Grid container gap={0} justifyContent="center">
            {filterCategoriesArray.map((item, index) => (
              <Button
                key={index}
                sx={{
                  height: "30px",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === filterCategoriesArray.length - 1
                      ? "0 5px 5px 0"
                      : 0,
                }}
                variant={
                  filter === item[Object.keys(item)[0]]
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setFilter(item[Object.keys(item)[0]])}
              >
                {Object.keys(item)[0]}
              </Button>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(GraphPriceHistory);
