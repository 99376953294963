import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LaptopWithPopups from "./LaptopWithPopups";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#fff",
  padding: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    padding: theme.spacing(4),
  },
}));

const Left = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(4),
}));

const Right = styled(Box)(({ theme }) => ({
  flex: 2,
  padding: theme.spacing(4),
}));

const CustomButton = styled(Button)({
  backgroundColor: "#51F2D2",
  color: "#003366",
  borderRadius: "25px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#51F2D2",
    opacity: 0.8,
  },
});

const AccentuatedHeader = styled("b")({
  color: "#51F2D2",
});

const TopSection = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <Left>
        <Typography variant="h4" sx={{ mb: 2 }} fontWeight="bold">
          {t("top_section_header_pre")}
          <AccentuatedHeader>
            {t("top_section_header_accent")}
          </AccentuatedHeader>
          {t("top_section_header_post")}
        </Typography>
        <Typography>{t("top_section_subtext")}</Typography>
        <CustomButton
          variant="contained"
          size="large"
          style={{ marginTop: "20px" }}
          component={Link}
          to="/login"
        >
          {t("top_section_become_member")}
        </CustomButton>
      </Left>
      <Right>
        <LaptopWithPopups width="90%" />
      </Right>
    </Root>
  );
};

export default TopSection;
