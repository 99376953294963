import { Polyline, LayersControl, LayerGroup } from "react-leaflet";
import rivers from "./rivers.json";

const Terminals = () => {
  return (
    <LayersControl.Overlay checked name="Show rivers">
      <LayerGroup>
        {rivers.map((ter) => (
          <Polyline
            pathOptions={{ color: "#2690ff", weight: 3 }}
            positions={ter.coordinates}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default Terminals;
